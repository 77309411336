import { Typography } from "antd";
import {
  Space,
  Input,
  Row,
  Col,
  List,
  Button,
  Avatar,
  AutoComplete,
} from "antd";
import { useNavigate } from "react-router-dom";
import { accountApproval } from "../../../../mockJson/accountApproval";
import { useDispatch, useSelector } from "react-redux";
import { searchUsers, setSearchBox } from "../../../../store/actions/users";
import { useState } from "react";
import { CloseSquareFilled } from "@ant-design/icons";

function Kyc() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchData, setSearchData] = useState({});
  const [searchError, setSearchError] = useState(false);
  const usersSearchList = useSelector((state) => state.admin.searchResult);
  let options = [];
  usersSearchList?.map((list) => {
    options.push({ value: `${list.name}  =>  ${list.mobile}`, id: list.id });
  });

  function handleSearch(data, id) {
    dispatch(searchUsers(id.id));
    navigate("/UserKyc");
    //  setSearchData(email);
    setSearchError(false);
  }

  function handleSubmit() {
    if (searchData.email) {
      dispatch(searchUsers(searchData.email));
      navigate("/UserKyc");
    } else {
      setSearchError(true);
    }
  }

  return (
    <div>
      <Row style={{ paddingLeft: "1%", paddingTop: "1%", paddingRight: "1%" }}>
        <Col span="24">
          <Typography.Title level={4}>KYC</Typography.Title>
        </Col>

        <Space.Compact style={{ width: "98%", marginTop: "1%" }}>
          <AutoComplete
            style={{ width: "100%" }}
            onSelect={handleSearch}
            placeholder="input here"
            options={options}
            onSearch={(value) => dispatch(setSearchBox(value))}
            size="large"
            filterOption={(inputValue, option) =>
              option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !==
              -1
            }
            allowClear={{ clearIcon: <CloseSquareFilled /> }}
            notFoundContent="No user found -> Please login again"
          />
          {/* <Button type="primary" size="large" onClick={handleSubmit}>
            Submit
          </Button> */}
        </Space.Compact>
        {searchError ? (
          <Typography.Text style={{ color: "red" }}>
            {" "}
            Please select option from suggested dropdown{" "}
          </Typography.Text>
        ) : (
          ""
        )}

        <Col span={24}>
          <div style={{ marginTop: "2%", marginRight: "17px" }}>
            <ListData />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export function ListData() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.admin.usersData);
  const allUserData = useSelector((state) => state.admin.getAlluserData);
  const dispatch = useDispatch();
  let listData = [];

  allUserData?.map((list) => {
    let listObj = {
      userName: list.name,
      kycStatus: list.kyc_status,
      // isAccountApprove: list.status === "ACTIVE" ? 1 : 0,
      userID: list.id,
      email: list.email,
    };
    listData.push(listObj);
  });

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={listData}
        pagination={{ defaultPageSize: 20 }}
        key="rjid"
        renderItem={(item, index) => (
          <List.Item key={index}>
            <List.Item.Meta
              className="CursorChange"
              onClick={() => {
                dispatch(searchUsers(item.userID));
                navigate("/UserKyc");
              }}
              avatar={
                <Avatar
                  src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                />
              }
              title={item.userName}
              description={
                <>
                  <b> Kyc Status: </b>
                  {item.kycStatus !== "ACCEPTED" ? (
                    <span style={{ color: "red" }}> Not Complete</span>
                  ) : (
                    <span style={{ color: "green" }}> Complete</span>
                  )}
                </>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
}

export default Kyc;
