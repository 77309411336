import "../../../css/dashboard.css";
import {
  HistoryOutlined,
  ExclamationCircleTwoTone,
  ApiTwoTone,
} from "@ant-design/icons";
import "../../../../src/css/dashboard.css";
import {
  Button,
  message,
  Popconfirm,
  notification,
  Space,
  Row,
  Col,
  Result,
  Modal,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  logout,
  reloadRedux,
  getPreRequiredData,
  sagaErrorToTrue,
  dataSaved,
  setInsuficientBalanace,
  preRequiredData,
  setPasswordError,
} from "../../../store/actions/users";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Header() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const logoutStatus = useSelector((state) => state.admin.logout);
  const [api, contextHolder] = notification.useNotification();
  const error = useSelector((state) => state.admin.sagaError);
  const getDataStatus = useSelector((state) => state.admin.dataSaved);
  const passwordCheck = useSelector((state) => state.admin.passwordError);
  const getInsufficientBalance = useSelector(
    (state) => state.admin.isInsuficientBalance
  );

  useEffect(() => {
    if (error) {
      const key = `open${Date.now()}`;
      const btn = (
        <Space>
          <Button type="primary" size="small" onClick={() => api.destroy(key)}>
            Close
          </Button>
        </Space>
      );
      api.open({
        message: (
          <Row>
            <Col span={2}>
              <ApiTwoTone
                twoToneColor="#eb2f96"
                style={{ fontSize: "larger" }}
              />{" "}
            </Col>
            <Col span={22}>
              <span>
                API was break while calling. Please check credential or try
                again.
              </span>
            </Col>
          </Row>
        ),
        duration: 0,
        // btn,
        // closeIcon: true,
        onClose: () => {
          dispatch(sagaErrorToTrue());
        },
      });
    }
  }, [error]);

  useEffect(() => {
    if (logoutStatus) {
      navigate("/");
    }
  }, [logoutStatus]);

  const confirm = () => {
    dispatch(logout());
    message.success("Logout");
  };

  const cancel = () => {
    message.success("Logout cancel");
  };

  function handleReload() {
    function handleApiResp(result) {
      response = JSON.parse(result);
      let data = {
        kycStatus: false,
        kycPending: response.kycPending,
        accountApproval: response.accountApproval,
        ticket: response.ticket,
        paymentPending: response.paymentPending,
      };
      dispatch(preRequiredData(data));
    }

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      "Cache-Control": "no-cache",
    };

    let response = {};
    fetch("https://araffiliates.com/api/static/dashboard", requestOptions)
      .then((response) => response.text())
      .then((result) => handleApiResp(result));

    dispatch(reloadRedux());
    dispatch(getPreRequiredData());
  }

  function handleOk() {
    dispatch(dataSaved(false));
  }

  function handleOkI() {
    dispatch(setInsuficientBalanace());
  }
  function handleOkP() {
    dispatch(setPasswordError(false));
  }

  return (
    <div className="Header">
      {contextHolder}
      <h2
        size={100}
        style={{ paddingTop: "20px", paddingLeft: "20px" }}
        className="PageTitle"
      >
        ARPN fintech pvt ltd (Admin)
      </h2>
      <div style={{ display: "flex" }}>
        <HistoryOutlined onClick={handleReload} />
        &nbsp; &nbsp;
        <Popconfirm
          title="Log out"
          description="Are you sure to logout?"
          onConfirm={confirm}
          onCancel={cancel}
          okText="Yes"
          cancelText="No"
        >
          <ExclamationCircleTwoTone twoToneColor="red" />
        </Popconfirm>
      </div>
      <Modal
        open={getDataStatus}
        onCancel={handleOk}
        okButtonProps={{ hidden: true }}
        //  cancelButtonProps ={{ hidden: true }}
        closeIcon={false}
      >
        <Result status="success" title="Data saved successfully!" />
      </Modal>
      <Modal
        open={getInsufficientBalance}
        onCancel={handleOkI}
        okButtonProps={{ hidden: true }}
        //  cancelButtonProps ={{ hidden: true }}
        closeIcon={false}
      >
        <Result status="error" title="INSUFFICIENT USER BALANCE" />
      </Modal>
      <Modal
        open={passwordCheck}
        onCancel={handleOkP}
        okButtonProps={{ hidden: true }}
        //  cancelButtonProps ={{ hidden: true }}
        closeIcon={false}
      >
        <Result
          status="error"
          title="PLEASE CHECK CREDENTIALS OR LOGIN AGAIN IF YOU JUST CHANGED PASSWORD"
        />
      </Modal>
    </div>
  );
}
export default Header;
