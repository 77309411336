import * as types from "./types";
export function getUser(users) {
  return {
    type: types.GET_USERS_REQUESTED,
    payload: users,
  };
}

export function getPreRequiredData() {
  return {
    type: types.PRE_REQUIRED_DATA,
  };
}

export function searchUsers(user) {
  return {
    type: types.SEARCH_USER,
    loading: true,
    payload: user,
  };
}

export function setMinimumBalance(minBal) {
  return {
    type: types.SET_MINIMUM_BALANCE,
    payload: minBal,
  };
}

export function setDescriptionEnglish(data) {
  return {
    type: types.SET_DESCRIPTION_ENGLISH,
    payload: data,
  };
}

export function setDescriptionHindi(data) {
  return {
    type: types.SET_DESCRIPTION_HINDI,
    payload: data,
  };
}

export function setlinkData(data) {
  return {
    type: types.SET_LINK_SHARE_DATA,
    payload: data,
  };
}

export function setAboutPolicy(data) {
  return {
    type: types.SET_ABOUT_POLICY,
    payload: data,
  };
}

export function setPrivacyPolicy(data) {
  return {
    type: types.SET_PRIVACY_POLICY,
    payload: data,
  };
}

export function submitAboutSection() {
  return {
    type: types.SUBMIT_ABOUT,
  };
}

export function submitPrivacyPolicy() {
  return {
    type: types.SUBMIT_PRIVACY_POLICY,
  };
}

export function submitVideo(data) {
  return {
    type: types.SUBMIT_VIDEO,
    payload: data,
  };
}

export function submitDeleteVideo(data) {
  return {
    type: types.DELETE_VIDEO,
    payload: data,
  };
}

export function resetData() {
  return {
    type: types.RESET_DATA,
  };
}

export function changeUserPermission(data) {
  return {
    type: types.CHANGE_USER_PERMISSION,
    payload: data,
  };
}

export function updateLink(data) {
  return {
    type: types.UPDATE_LINK,
    payload: data,
  };
}

export function approveIncompleteList(data) {
  return {
    type: types.APPROVE_INCOMPLETE_LIST,
    payload: data,
  };
}

export function setPaymentRequest(data) {
  return {
    type: types.SET_PAYMENT_REQUEST,
    payload: data,
  };
}

export function updateDeactivateLink(data) {
  return {
    type: types.UPDATE_DEACTIVE_LINK,
    payload: data,
  };
}

export function changeMarquee(data) {
  return {
    type: types.CHANGE_MARQUEE,
    payload: data,
  };
}

export function getLoginStatus(data) {
  return {
    type: types.GET_USERNAME_PASSWORD,
    payload: data,
  };
}

export function getUserTicket(data) {
  return {
    type: types.GET_USER_TICKET,
    payload: data,
  };
}

export function setReply(data) {
  return {
    type: types.SET_REPLY_DATA,
    payload: data,
  };
}

export function forgotSubmitOtp(data) {
  return {
    type: types.FORGOT_SUBMIT_OTP,
    payload: data,
  };
}

export function logout() {
  return {
    type: types.LOGOUT,
  };
}

export function sendForgotEmail(data) {
  return {
    type: types.SEND_FORGOT_EMAIL,
    payload: data,
  };
}

export function setChangePass(data) {
  return {
    type: types.SET_CHANGE_PASS,
    payload: data,
  };
}

export function reloadRedux() {
  return {
    type: types.RELOAD_REDUX,
  };
}

export function sendNotification(data) {
  return {
    type: types.SEND_NOTIFICATION,
    payload: data,
  };
}

export function sagaErrorToTrue() {
  return {
    type: types.SAGA_ERRO_To_TRUE,
  };
}

//New API CHANGES

export function checkLogin(data) {
  return {
    type: types.CHECK_LOGIN,
    payload: data,
  };
}

export function targetLink(data) {
  return {
    type: types.TARGET_LINK,
    payload: data,
  };
}

export function setSearchBox(data) {
  return {
    type: types.SET_SEARCH_BOX,
    payload: data,
  };
}

export function resetSelectedLink(data) {
  return {
    type: types.SELECTED_LINK,
    payload: data,
  };
}

export function updateLinkList() {
  return {
    type: types.UPDATE_LINK_LIST,
  };
}

export function approveKYC() {
  return {
    type: types.APROVE_KYC,
  };
}

export function dataSaved(data) {
  return {
    type: types.DATA_SAVED,
    payload: data,
  };
}

export function resetDescription_en(data) {
  return {
    type: types.RESER_DESC_E,
    payload: data,
  };
}

export function resetDescription_hi(data) {
  return {
    type: types.RESER_DESC_H,
    payload: data,
  };
}

export function preRequiredData(data) {
  return {
    type: types.PRE_REQUIRED_DATA_Response,
    payload: data,
  };
}

export function chatDetail(data) {
  return {
    type: types.GET_DETAIL_TICKET,
    payload: data,
  };
}

export function getTestimonials() {
  return {
    type: types.GET_TESTIMONIALS,
  };
}

export function deletePerformer(data) {
  return {
    type: types.DELETE_PERFORMER,
    payload: data,
  };
}

export function submitPerformer(data) {
  return {
    type: types.SUBMIT_PERFORMER,
    payload: data,
  };
}

export function getPendingPayment() {
  return {
    type: types.GET_PENDING_PAYMENT,
  };
}

export function getAllChatDetails(data) {
  return {
    type: types.GET_ALL_CHAT_DETAILS,
    payload: data,
  };
}

export function getPaymentCompleteList() {
  return {
    type: types.GET_PAYMENT_COMPLETE_LIST,
  };
}

export function submitSlider(data) {
  return {
    type: types.SUBMIT_SLIDER,
    payload: data,
  };
}

export function getSliderData(data) {
  return {
    type: types.GET_SLIDER_DATA,
    payload: data,
  };
}

export function deleteSlider(data) {
  return {
    type: types.DELETE_SLIDER,
    payload: data,
  };
}

export function setInsuficientBalanace() {
  return {
    type: types.INSUFICIENT_BALANCES,
  };
}

export function getRejectedList() {
  return {
    type: types.REJECTED_LIST,
  };
}

export function setLoading(data) {
  return {
    type: types.SET_LOADING,
    payload: data,
  };
}

export function setAboutData(data) {
  return {
    type: types.SET_ABOUTDATA,
    payload: data,
  };
}

export function setPrivacyDataa(data) {
  return {
    type: types.SET_PRIVACTDATAA,
    payload: data,
  };
}

export function changePassword(data) {
  return {
    type: types.CHANGE_PASS,
    payload: data,
  };
}

export function setPasswordError(data) {
  return {
    type: types.PASSWORD_EXP,
    payload: data,
  };
}
