import "../../../css/dashboard.css";
import RouterComp from "../Router/index";
import { Row, Col } from "antd";

function PageContent() {
  return (
    <div className="PageContent">
      <Row>
        <Col span={24}>
          <RouterComp />
        </Col>
      </Row>
    </div>
  );
}

export default PageContent;
