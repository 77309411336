import { Col, Row, Typography, Card, Input, Space, Button, Modal } from "antd";
import JoditEditor from "jodit-react";
import { useRef, useMemo, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendNotification } from "../../../../store/actions/users";

function Notification() {
  const [openPopup, setOpenPopup] = useState(false);
  const dataSavedSelector = useSelector((state)=> state.admin.dataSaved)
  const [notificationText, setNotificationText] = useState({
    title: "",
    body: "",
  });
  const dispatch = useDispatch();

  useEffect(()=>{
    setNotificationText({
        title: "",
        body: "",
      })
  },[dataSavedSelector])

  function handleOk() {
    dispatch(sendNotification(notificationText));
    setOpenPopup(false);
  }
  function handleCancel() {
    setOpenPopup(false);
  }
  return (
    <Row style={{ paddingLeft: "1%", paddingTop: "1%", paddingRight: "1%" }}>
      <Col span="24">
        <div style={{ display: "flex" }}>
          <Col span={12}>
            <Typography.Title level={3}>Notification</Typography.Title>
          </Col>
        </div>
      </Col>
      <Col span={24}>
        <Card style={{ backgroundColor: "rgba(237, 237, 237, 0.8)" }}>
          <Space
            direction="vertical"
            style={{ width: "-webkit-fill-available" }}
          >
            <Input
              placeholder="Notification text"
              size="large"
              value={notificationText.title}
              onChange={(e) => {
                let data = { title: e.target.value };
                setNotificationText((list) => ({
                  ...list,
                  ...data,
                }));
              }}
            />
            <Input
              placeholder="Notification Body"
              size="large"
              value={notificationText.body}
              onChange={(e) => {
                let data = { body: e.target.value };
                setNotificationText((list) => ({
                  ...list,
                  ...data,
                }));
              }}
            />
            <Button
              type="primary"
              style={{ width: "-webkit-fill-available", marginTop: "20px" }}
              onClick={() => setOpenPopup(true)}
              disabled={
                notificationText.title === "" && notificationText.body === ""
              }
            >
              Send
            </Button>
            {/* <JorditEditor1/> */}
          </Space>
        </Card>
      </Col>
      <Modal
        title="Confirmation"
        open={openPopup}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Typography> Are you sure you want to send notification ?</Typography>
      </Modal>
    </Row>
  );
}

function JorditEditor1() {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  const configE = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: "Enter details in Hindi" || "Start typings...",
    height: "20vh",
    width: "100%",
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "ul",
      "ol",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "font",
      "fontsize",
    ],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ["brush", "file"],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
  };

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={content}
        config={configE}
        onChange={(content) => {
          setContent(content);
          // dispatch(setDescriptionHindi(content));
        }}
      />
    ),
    []
  );
}

export default Notification;
