import React, { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getUser,
  getPreRequiredData,
  resetData,
  getLoginStatus,
  forgotSubmitOtp,
  sendForgotEmail,
  setChangePass,
  sagaErrorToTrue,
  checkLogin,
  preRequiredData,
} from "../store/actions/users";
import {
  Typography,
  Col,
  Spin,
  Row,
  Button,
  Card,
  notification,
  Space,
  Alert,
} from "antd";
import "../css/dashboard.css";
import OtpInput from "react-otp-input";
import { ApiTwoTone } from "@ant-design/icons";

const Login = (props) => {
  const navigate = useNavigate();
  const [newPass, setNewPass] = useState({
    newPass: "",
    confirmPass: "",
  });
  const [errorMgs, setErrorMgs] = useState(false);
  const [useNmae, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const loginStatus = useSelector((state) => state.admin.loginStatus);
  const loading = useSelector((state) => state.admin.loading);
  const [forgot, setForgot] = useState(false);
  const [forgotEmail, setForgotEmail] = useState(
    "servicearaffiliates@gmail.com"
  );
  const [shouldEnterOtp, setShouldEnterOtp] = useState(false);
  const [otp, setOtp] = useState("");
  const [userAuth, setUserAuth] = useState(false);
  const [count, setCount] = useState(180);
  const [activeResume, setActiveResume] = useState(false);
  const forgotEmailSentSelector = useSelector(
    (state) => state.admin.forgotEmailSent
  );
  const [hidePass, setHidePass] = useState("");
  const logoutStatus = useSelector((state) => state.admin.logout);
  const [api, contextHolder] = notification.useNotification();
  const error = useSelector((state) => state.admin.sagaError);
  let data = {};

  useEffect(() => {
    function handleApiResp(result) {
      response = JSON.parse(result);
      data = {
        kycStatus: false,
        kycPending: response.kycPending,
        accountApproval: response.accountApproval,
        ticket: response.ticket,
        paymentPending: response.paymentPending,
      };
      dispatch(preRequiredData(data));
    }

    var requestOptions = {
      method: "GET",
      redirect: "follow",
      "Cache-Control": "no-cache",
    };

    let response = {};
    fetch("https://araffiliates.com/api/static/dashboard", requestOptions)
      .then((response) => response.text())
      .then((result) => handleApiResp(result));

    //  console.log('response',response)
    //   response = JSON.parse(response)
  }, []);

  //forgotEmailSentSelector
  useEffect(() => {
    setForgot(false);
    setShouldEnterOtp(false);
    dispatch(resetData());
    setCount(180);
    setNewPass({
      newPass: "",
      confirmPass: "",
    });
  }, []);

  useEffect(() => {
    if (error) {
      const key = `open${Date.now()}`;
      const btn = (
        <Space>
          <Button type="primary" size="small" onClick={() => api.destroy(key)}>
            Close
          </Button>
        </Space>
      );
      api.open({
        message: (
          <Row>
            <Col span={2}>
              <ApiTwoTone
                twoToneColor="#eb2f96"
                style={{ fontSize: "larger" }}
              />{" "}
            </Col>
            <Col span={22}>
              <span>
                API was break while calling. Please check credential or try
                again.
              </span>
            </Col>
          </Row>
        ),
        duration: 0,
        // btn,
        // closeIcon: true,
        onClose: () => {
          dispatch(sagaErrorToTrue());
        },
      });
    }
  }, [error]);

  useEffect(() => {
    if (loginStatus) {
      dispatch(getUser(useNmae));
      dispatch(getPreRequiredData());
      //  dispatch(preRequiredData(data))
      setErrorMgs(false);
      navigate("/contain");
    } else {
      if (loginStatus !== "") {
        setErrorMgs(true);
      }
    }
  }, [loginStatus]);

  useEffect(() => {
    document.title = "Araffiliates";
    setCount(180);
    setActiveResume(false);
  }, []);

  const FormHeader = (props) => <h2 id="headerTitle">{props.title}</h2>;

  const Form = (props) => (
    <div>
      <UserNameInput
        description="Username"
        placeholder="Enter your username"
        type="text"
      />
      <PasswordInput
        description="Password"
        placeholder="Enter your password"
        type="password"
      />
      <FormButton title="Log in" />
    </div>
  );

  const FormButton = (props) => (
    <div
      id="button"
      className="row"
      style={{ width: "-webkit-fill-available" }}
    >
      <button onClick={handleSubmit} style={{ background: "lightseagreen" }}>
        {props.title}
      </button>
    </div>
  );

  const ForgotBtn = (props) => (
    <div
      id="forgotButton"
      className="row"
      style={{ width: "-webkit-fill-available" }}
    >
      <button
        onClick={handleForgotEmail}
        //  disabled={!forgotEmailSentSelector}
        style={{ background: "lightseagreen", marginLeft: "10px" }}
      >
        {props.title}
      </button>
    </div>
  );

  const SubmitOtpBtn = (props) => (
    <div
      id="forgotButton"
      className="row"
      style={{ width: "-webkit-fill-available" }}
    >
      <button
        onClick={handleForgotOtp}
        style={{ background: "lightseagreen", marginLeft: "10px" }}
      >
        {props.title}
      </button>
    </div>
  );

  const ChangePassBtn = (props) => (
    <div
      id="forgotButton"
      className="row"
      style={{ width: "-webkit-fill-available" }}
    >
      <button
        onClick={handleChnagePass}
        style={{ background: "lightseagreen" }}
      >
        {props.title}
      </button>
    </div>
  );

  function handleForgotEmail() {
    dispatch(sendForgotEmail(forgotEmail));
    setShouldEnterOtp(true);
  }

  function handleSubmit() {
    if (handleEmailVerification()) {
      let data = {
        email: useNmae,
        password: password,
      };
      // dispatch(getLoginStatus(data)); //changes
      dispatch(checkLogin(data));
    }
  }

  function handleEmailVerification() {
    const validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (useNmae && password && useNmae.match(validRegex)) {
      return true;
    } else {
      setErrorMgs(true);
      return false;
    }
  }

  const UserNameInput = (props) => (
    <div className="row">
      <label>{props.description}</label>
      <input
        type="text"
        placeholder={props.placeholder}
        onChange={(e) => setUserName(e.target.value)}
        value={useNmae}
      />
    </div>
  );
  const PasswordInput = (props) => (
    <div className="row">
      <label>{props.description}</label>
      <input type={props.type} placeholder={props.placeholder} />
    </div>
  );

  function handleForgotOtp() {
    dispatch(forgotSubmitOtp(otp));
    setUserAuth(true);
  }

  function handleChnagePass() {
    dispatch(setChangePass(newPass.newPass));
    setForgot(false);
    setUserAuth(false);
    setNewPass({
      newPass: "",
      confirmPass: "",
    });
    setOtp("");
    setShouldEnterOtp(false);
    setErrorMgs(false);
    setCount(180);
    setActiveResume(false);
    setNewPass({
      newPass: "",
      confirmPass: "",
    });
  }

  return (
    <>
      <div id="loginform">
        {contextHolder}
        <FormHeader title="Login" />
        {loading ? (
          <Row>
            <Col span={24}>
              <div style={{ textAlign: "center", paddingBottom: "30px" }}>
                {" "}
                <Spin size="large" style={{ alignSelf: "self-end" }} />{" "}
              </div>
            </Col>
          </Row>
        ) : (
          <div style={{ paddingLeft: "10px", paddingBottom: "20px" }}>
            <div
              className="row"
              style={
                !shouldEnterOtp ? { paddingLeft: "5px" } : { display: "none" }
              }
            >
              {errorMgs ? (
                <span style={{ textAlign: "center" }}>
                  {""}
                  <Typography.Text style={{ color: "red" }}>
                    Please check your credentials
                  </Typography.Text>
                </span>
              ) : (
                ""
              )}

              {/* <label>Email id</label>  */}
              {userAuth ? (
                ""
              ) : !forgot ? (
                <>
                  <input
                    type="text"
                    placeholder="Enter Email"
                    onChange={(e) => setUserName(e.target.value)}
                    value={useNmae}
                  />
                </>
              ) : (
                <>
                  {/* <input
                    type="text"
                    placeholder="Enter Email"
                    onChange={(e) => setForgotEmail(e.target.value)}
                    disabled
                    value={forgotEmail}
                  /> */}
                  {!shouldEnterOtp ? (
                    <div style={{ paddingLeft: "25px" }}>
                      <Alert
                        description=" Otp will be send to your service account
                          ser**********liates@gmail.com"
                        type="error"
                      />
                    </div>
                  ) : (
                    <div style={{ display: "none" }}></div>
                  )}
                </>
              )}
            </div>
            <div className="row">
              {/* <label>Password</label> */}
              {userAuth ? (
                <>
                  {forgotEmailSentSelector ? (
                    <>
                      <input
                        type="password"
                        placeholder="New Password"
                        onChange={(e) => {
                          let data = { newPass: e.target.value };
                          setNewPass((list) => ({
                            ...list,
                            ...data,
                          }));
                        }}
                        value={newPass.newPass}
                      />
                      <br />
                      <input
                        type="password"
                        placeholder="Confirm Password"
                        onChange={(e) => {
                          let data = { confirmPass: e.target.value };
                          setNewPass((list) => ({
                            ...list,
                            ...data,
                          }));
                        }}
                        value={newPass.confirmPass}
                      />
                    </>
                  ) : (
                    <>
                      <Spin />
                    </>
                  )}
                </>
              ) : !forgot ? (
                <input
                  type="password"
                  placeholder="Enter Password"
                  onChange={(e) => setPassword(e.target.value)}
                  value={password}
                />
              ) : (
                <>
                  {shouldEnterOtp ? (
                    <div style={{ display: "contents", paddingLeft: "10px" }}>
                      <Alert
                        description=" Otp is send to ser**********liates@gmail.com"
                        type="success"
                      />

                      <input
                        type="text"
                        placeholder="Enter OTP"
                        onChange={(e) => setOtp(e.target.value)}
                        value={otp}
                        style={{ textAlign: "center", paddingTop: "20px" }}
                      />
                      <Typography.Text
                        style={
                          activeResume
                            ? { color: "blue", textAlign: "right" }
                            : { textAlign: "right", color: "grey" }
                        }
                      ></Typography.Text>

                      {/* // <OtpInput
                    //   value={otp}
                    //   onChange={setOtp}
                    //   numInputs={4}
                    //   renderSeparator={<span>-</span>}
                    //   renderInput={(props) => <input {...props} />}
                    // /> */}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              )}
              {!forgot ? (
                <FormButton title="Log in" />
              ) : (
                <>
                  {!userAuth ? (
                    <>
                      {otp === "" ? (
                        <ForgotBtn title="Send OTP" />
                      ) : (
                        <SubmitOtpBtn title="Submit OTP" />
                      )}
                    </>
                  ) : (
                    <>
                      {forgotEmailSentSelector ? (
                        <ChangePassBtn title="Submit" />
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </>
              )}
              {/* {!forgot ? (
                <div style={{ textAlign: "center", paddingBottom: "20px" }}>
                  <Typography.Text
                    onClick={() => {
                      setForgot(true);
                      setErrorMgs(false);
                    }}
                    className="CursorChange"
                  >
                    Forgot password
                  </Typography.Text>
                </div>
              ) : (
                <div
                  style={{
                    textAlign: "center",
                    paddingBottom: "20px",
                    paddingTop: "10px",
                  }}
                >
                  <Typography.Text
                    onClick={() => {
                      setForgot(false);
                      setUserAuth(false);
                      setNewPass({
                        newPass: "",
                        confirmPass: "",
                      });
                      setOtp("");
                      setShouldEnterOtp(false);
                      setErrorMgs(false);
                      setCount(180);
                      setActiveResume(false);
                      setNewPass({
                        newPass: "",
                        confirmPass: "",
                      });
                      //  setForgotEmail("");
                    }}
                    className="CursorChange"
                  >
                    Go back to login
                  </Typography.Text>
                </div>
              )} */}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Login;
