import { call, put, take, takeEvery } from "redux-saga/effects";
import { usersData } from "../../mockJson/userData";
import { select } from "redux-saga/effects";
import {
  handleAboutSection,
  handlePrivacySection,
  loginAPICall,
  handleAPIcallLink,
  tagetLink,
  allIncompleteList,
  allCompleteList,
  changeUserStatus,
  getAlluser,
  completeTask,
  searchAPI,
  userSearch,
  linkShareAPI,
  deActivateLinkAPI,
  approveKYCAPI,
  updateLinkData,
  minBalanceAPI,
  updateMinBal,
  getPaymentRequest,
  updateMarqueeAPI,
  getTrainingVideo,
  addTrainingLink,
  getOpenChat,
  getDetailTicketAPI,
  getTestimonialsApi,
  getSubmitPerformer,
  deletePerformerAPI,
  allPendingPaymentAPI,
  makePaymentRequest,
  setReplyAPI,
  getCompletePaymentAPI,
  setSliderAPI,
  getSliderAPI,
  deleteSliderAPI,
  setNotificationDataAPI,
  setRejectListAPI,
  getAboutUs,
  getPrivacy,
  setNewPass,
} from "./APIcalls";

function handleAPIcallOTPEmail(action) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("email", action);
  urlencoded.append("name", "admin");
  urlencoded.append("platform", "web");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return fetch(
    "https://rgsi.araffiliates.com/api/users/sendmail",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

function handleAPIcallToken(requestData) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("email", requestData.genrateTokenRequest.email);
  urlencoded.append("password", requestData.genrateTokenRequest.password);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return fetch("https://rgsi.araffiliates.com/api/login/token", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

function handleAPIcallLogin(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", data.token);
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("token", data.token);
  urlencoded.append("platform", "web");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return fetch("https://rgsi.araffiliates.com/api/login/login", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

function handleAPIcallDeleteTraining(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("setting_value", JSON.stringify(data.setting_value));
  urlencoded.append("setting_active", data.setting_active);
  urlencoded.append("setting_key", data.setting_key);

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return fetch(
    "https://rgsi.araffiliates.com/api/settings/update",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

function handleAPIcallTicket(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("ticket_id", data);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return fetch(
    "https://rgsi.araffiliates.com/api/chat/ticket/get",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

function handleAPIcallOTP(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("userotp", data);
  urlencoded.append("email", "servicearaffiliates@gmail.com");
  urlencoded.append("platform", "web");

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
  };

  return fetch(
    "https://rgsi.araffiliates.com/api/users/verifyotp",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

function handlePassChange(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  var raw = JSON.stringify({
    platform: "web",
    password: data,
    email: "servicearaffiliates@gmail.com",
  });

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    "https://rgsi.araffiliates.com/api/login/update/password",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

function* fetchUsers(action) {
  try {
    // const users = yield call(getApiData);
    //   yield put({ type: 'GET_USERS_SUCCESS', users: users });
    const bearer = yield select((state) => state.admin.token);

    let finalUserData = [];
    let numberOfUsers = 0;

    //New Api changes
    let linkResponse = yield call(() => handleAPIcallLink(bearer));
    let inCompleteData = yield call(() => allIncompleteList(bearer));
    let completeData = yield call(() => allCompleteList(bearer));
    let minBalance = yield call(() => minBalanceAPI(bearer));
    let paymentReq = yield call(() => getPaymentRequest(bearer));
    let trainingData = yield call(() => getTrainingVideo(bearer));
    let about_Us = yield call(() => getAboutUs(bearer));
    let privacy = yield call(() => getPrivacy(bearer));
    // yield call(()=>getAllPaymentPending(bearer))
    let openChat = yield call(() => getOpenChat(bearer));
    openChat = JSON.parse(openChat);

    // openChat.chat.sort(function (a, b) {
    //   return new Date(a.created_at) - new Date(b.created_at);
    // });

    trainingData = JSON.parse(trainingData);
    let trainingresp = JSON.parse(trainingData?.settings[0]?.option_value);
    paymentReq = JSON.parse(paymentReq);
    inCompleteData = JSON.parse(inCompleteData);
    completeData = JSON.parse(completeData);
    let allUsers = yield call(() => getAlluser(bearer));
    allUsers = JSON.parse(allUsers);
    about_Us = JSON.parse(about_Us);
    privacy = JSON.parse(privacy);
    minBalance = JSON.parse(minBalance);
    // minBalance.settings[0] = JSON.parse(minBalance.settings[0])
    //  console.log('minBalance.settings[0]',minBalance.settings[0])
    // trainingLink = JSON.parse(trainingLink);
    // trainingLink.message.map((link) => {
    //   let data = JSON.parse(link.setting_value);
    //   link.setting_value = data;
    // });
    linkResponse = JSON.parse(linkResponse);
    // console.log("link 2", linkResponse.lists);
    // let responseTicket = yield call(handleAPIcallTicket);
    // completed
    // usersResponse?.users?.map((user, index) => {
    //   let finalData = JSON.parse(user.BankDetails);
    //   user.BankDetails = finalData;
    //   numberOfUsers = index + 1;
    //   finalUserData.push(user);
    //   finalUserData[index].complete = [];
    //   listResponse?.message?.linktickets?.comeplete?.map((complete, indexs) => {
    //     if (user.rjid === complete.rjid) {
    //       finalUserData[index].complete.push(complete);
    //     }
    //   });
    // });

    //incompleted
    // usersResponse?.users?.map((user, index) => {
    //   finalUserData[index].incomplete = [];
    //   listResponse?.message?.linktickets?.incomplete?.map(
    //     (incomplete, indexs) => {
    //       if (user.rjid === incomplete.rjid) {
    //         finalUserData[index].incomplete.push(incomplete);
    //       }
    //     }
    //   );
    // });

    //PaymentRequest
    // usersResponse?.users?.map((user, index) => {
    //   finalUserData[index].payment = [];
    //   listResponse?.message?.ptickets?.map((incomplete, indexs) => {
    //     if (user.rjid === incomplete.rjid) {
    //       finalUserData[index].payment.push(incomplete);
    //     }
    //   });
    // });

    // payment request number
    // finalUserData?.map((user, index) => {
    //   user?.payment?.map((list) => {
    //     if (list.status !== "completed") {
    //       requestPayemntNumber = requestPayemntNumber + 1;
    //     }
    //   });
    // });
    //  yield put({ type: "ALL_USER_DATA", data: usersResponse.users });
    const currentPage = window.location.href;
    if (currentPage.includes("SearchedUser")) {
      const userId = yield select((state) => state.admin.searchUser);
      const data = {
        id: userId.user.id,
        bearer: bearer,
      };
      let response = yield call(() => userSearch(data));
      response = JSON.parse(response);
      yield put({ type: "SEARCHED_USER_RESPONSE", data: response });
    }
    yield put({ type: "LINK_DATA", data: linkResponse?.lists });
    yield put({ type: "PAYMENT_DATA", data: paymentReq?.payments });
    yield put({ type: "OPEN_CHAT", data: openChat.chat });
    yield put({
      type: "SET_ABOUT_US",
      data: about_Us.settings[0].option_value,
    });
    yield put({
      type: "SET_PRIVACTDATA",
      data: privacy.settings[0].option_value,
    });
    //  yield put({ type: "NOTIFICATION_CALL", data: notificationData });
    yield put({ type: "GET_ALL_USER_DATA", data: allUsers?.users });
    yield put({ type: "INCOMPLETE_LIST", data: inCompleteData?.tickets });
    yield put({ type: "COMPLETE_LIST", data: completeData?.tickets });
    yield put({ type: "GET_TRAINING_DATA", data: trainingresp });
    //  const finalResponseMin = JSON.parse(responseMin);

    yield put({
      type: "SET_MINIMUM_BALANCE_DATA",
      data: minBalance.settings[0].option_value,
    });
    //COMPLETE_INCOMPMETE_LIST

    // const data = {
    //   kycStatus: false,
    //   kycPending: preRequiredData.kycPending,
    //   accountApproval: preRequiredData.accountApproval,
    //   ticket: preRequiredData.ticket,
    //   paymentPending: preRequiredData.paymentPending,
    // };
    // yield put({ type: "PRE_REQUIRED_DATA_Response", preRequiredData: data });
    yield put({ type: "UPDATE_USERDATA", usersData: usersData });
  } catch (error) {
    console.log("saga error");
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

// function* minBalance(action) {
//   try {
//     const requestData = {
//       updateSettingRequest: {
//         setting_value: action.payload,
//         setting_active: 1,
//         setting_key: "minimumbalance",
//       },
//     };

//     let response1 = yield call(() => handleMinBal(requestData));
//     response1 = JSON.parse(response1);
//     if (response1.message === "Setting updated successfully") {
//       // let response = yield call(handleAPIcallMinimumBalance);
//       yield put({
//         type: "SET_MINIMUM_BALANCE_DATA",
//         data: requestData.updateSettingRequest.setting_value,
//       });
//     } else {
//       yield put({ type: "SAGA_ERROR", data: true });
//     }
//   } catch (error) {
//     console.log("saga error");
//     yield put({ type: "GET_USERS_FAILED", message: error.message });
//     yield put({ type: "SAGA_ERROR", data: true });
//   }
// }

function* submitDeleteVideo(action) {
  try {
    let refreshData = { message: [] };
    // const trainingData = yield select((state)=> state.admin.trainingLink)
    let setValue = {};

    const trainingLinkSelector = yield select(
      (state) => state.admin.trainingLink
    );

    trainingLinkSelector.map((list) => {
      if (
        list.setting_key === action.payload.updateSettingRequest.setting_key
      ) {
        setValue = list.setting_value;
      }
    });

    let data = {
      setting_key: action.payload.updateSettingRequest.setting_key,
      setting_value: setValue,
      setting_active: action.payload.updateSettingRequest.setting_active,
    };

    let response = yield call(() => handleAPIcallDeleteTraining(data));
    response = JSON.parse(response);
    if (response.message === "Setting updated successfully") {
      refreshData.message = trainingLinkSelector?.map((list) => {
        if (
          list.setting_key === action.payload.updateSettingRequest.setting_key
        ) {
          list.setting_active =
            action.payload.updateSettingRequest.setting_active;
        }
        return list;
      });
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
    //  let response1 = yield call(handleAPIcallTrainingLink);

    yield put({ type: "TRAINING_LINK", data: refreshData });
  } catch (error) {
    console.log("saga error");
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* checkUserNamePassword(action) {
  try {
    const requestData = {
      genrateTokenRequest: action.payload,
    };

    let response = yield call(() => handleAPIcallToken(requestData));
    var data = JSON.parse(response);
    let requestData1 = {
      token: data.token,
      platform: "web",
    };

    let response2 = yield call(() => handleAPIcallLogin(requestData1));
    var data2 = JSON.parse(response2);
    if (data2.status === "success") {
      yield put({ type: "LOGIN_STATUS", data: true });
    } else {
      yield put({ type: "LOGIN_STATUS", data: false });
    }
  } catch (error) {
    console.log("saga error", error);
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* getUserTicket(action) {
  try {
    let responseTicket = yield call(() => handleAPIcallTicket(action.payload));
    let onlyTickets = [];
    let data = {};
    responseTicket = JSON.parse(responseTicket);

    responseTicket.tickets?.map((list) => {
      if (list.created_by === action.payload) {
        let arr = list.updated_date.replace("T", " ");
        let actualDate = arr.slice(0, 18);
        let arr1 = list.ticket_from === "admin_user" ? list.ticket_from : "";
        data = {
          ticket_id: list.ticket_id,
          ticket_from: list.ticket_from,
          ticket_to: list.ticket_to,
          ticket_action: list.ticket_action,
          status: list.status,
          created_by: list.created_by,
          updated_by: list.updated_by,
          created_date: list.created_date,
          label: actualDate + " " + arr1,
          children: list.comment,
        };
        onlyTickets.push(data);
      }
    });
    yield put({ type: "USER_TICKET_DATA", data: responseTicket });
    yield put({ type: "ONLY_TICKETS", data: onlyTickets });
  } catch (error) {
    console.log("saga error", error);
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* forgotSubmitOtp(action) {
  try {
    let response = yield call(() => handleAPIcallOTP(action.payload));
    response = JSON.parse(response);

    if (response.message === "OTP verified Successfully") {
      yield put({ type: "FORGOT_PASS_EMAIL", data: true });
    } else if (response.message === "OTP verification failed") {
      yield put({ type: "SAGA_ERROR", data: true });
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* sendForgotEmail(action) {
  try {
    let data = action.payload;
    let response = yield call(() => handleAPIcallOTPEmail(data));
    response = JSON.parse(response);
    if (response.message === "OTP sent successfully") {
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* setPassChange(action) {
  try {
    const response = yield call(() => handlePassChange(action.payload));
    response = JSON.parse(response);
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

//NEW API CHANGES

function* checkLoginAPI(action) {
  try {
    let response = yield call(() => loginAPICall(action.payload));
    response = JSON.parse(response);
    if (response.message === "user logged in successfully") {
      yield put({ type: "SET_TOKEN", data: response.token });
      yield put({ type: "LOGIN_STATUS", data: true });
    } else {
      yield put({ type: "LOGIN_STATUS", data: false });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* submitAboutPolicy() {
  try {
    const about = yield select((state) => state.admin.aboutPolicy);
    const bearer = yield select((state) => state.admin.token);

    const data = {
      about: about,
      bearer: bearer,
    };
    let response = yield call(() => handleAboutSection(data));
    response = JSON.parse(response);
    if (response.message === "Setting Updated") {
      yield put({ type: "DATA_SAVED", data: true });
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    console.log("saga error");
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* submitPrivacyPolicy() {
  try {
    const about = yield select((state) => state.admin.privacyPolicy);
    const bearer = yield select((state) => state.admin.token);

    const data = {
      about: about,
      bearer: bearer,
    };
    let response = yield call(() => handlePrivacySection(data));
    response = JSON.parse(response);
    if (response.message === "Setting Updated") {
      yield put({ type: "DATA_SAVED", data: true });
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    console.log("saga error");
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* targetDisplayLink(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      id: action.payload,
      bearer: bearer,
    };
    let response = yield call(() => tagetLink(data));
    response = JSON.parse(response);
    if (response.lists.length > 0) {
      yield put({ type: "SELECTED_LINK", data: response.lists[0] });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* changeUserPermissions(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const allUser = yield select((state) => state.admin.getAlluserData);
    const data = {
      bearer: bearer,
      id: action.payload.id,
      isactive: action.payload.isAccountApprove === 1 ? "DEACTIVE" : "ACTIVE",
    };
    let response = yield call(() => changeUserStatus(data));
    response = JSON.parse(response);
    if (response.message === "User Status Changed") {
      let finalAllUser = allUser.map((user) => {
        if (action.payload.email === user.email) {
          user.status = data.isactive;
        }
        return user;
      });
      yield put({ type: "GET_ALL_USER_DATA", data: finalAllUser });
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* approveIncompleteList(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      bearer: bearer,
      id: action.payload.linkId,
      status: action.payload.reject ? "REJECTED" : "COMPLETED",
      comment: action.payload.reject ? action.payload.rejectComment : "",
    };
    let response = yield call(() => completeTask(data));
    response = JSON.parse(response);
    if (response.message === "Updated Successfully") {
      let inCompleteData = yield call(() => allIncompleteList(bearer));
      let completeData = yield call(() => allCompleteList(bearer));
      if (action.payload.userPage) {
        const data = {
          bearer: bearer,
          id: action.payload.userId,
        };
        let response = yield call(() => userSearch(data));
        response = JSON.parse(response);

        yield put({ type: "SEARCHED_USER_RESPONSE", data: response });
      }
      inCompleteData = JSON.parse(inCompleteData);
      completeData = JSON.parse(completeData);
      yield put({ type: "INCOMPLETE_LIST", data: inCompleteData.tickets });
      yield put({ type: "COMPLETE_LIST", data: completeData.tickets });
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    console.log("saga error", error);
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* searchBox(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      bearer: bearer,
      search: action.payload,
    };
    let response = yield call(() => searchAPI(data));
    response = JSON.parse(response);

    yield put({ type: "SEARCH_BOX", data: response.users });
  } catch (error) {
    // yield put({ type: "GET_USERS_FAILED", message: error.message });
    // yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* searchUser(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      bearer: bearer,
      id: action.payload,
    };
    let response = yield call(() => userSearch(data));
    response = JSON.parse(response);
    if (response.user.bank_details !== "") {
      let bankData = JSON.parse(response.user.bank_details);
      response.user.bank_details = bankData;
    }
    yield put({ type: "SEARCHED_USER_RESPONSE", data: response });
  } catch (error) {
    console.log("saga error");
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* setLinkData(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      bearer: bearer,
      title_en: action.payload.title_en,
      title_hi: action.payload.title_hi,
      url: action.payload.task_url,
      amount: action.payload.task_amount,
      catagory: action.payload.catagory,
      public: action.payload.link_send_to_all ? 1 : 0,
      active: 1,
      user_ids: action.payload.link_send_to_all ? 0 : action.payload.sendToId,
      thumbnail: action.payload.image,
    };
    data.description_en = yield select((state) => state.admin.descriptionE);
    data.description_hi = yield select((state) => state.admin.descriptionH);
    //console.log('data saga',data)
    let response = yield call(() => linkShareAPI(data));
    // response = JSON.parse(response);
    // console.log("response", response);
    yield put({ type: "DATA_SAVED", data: true });
  } catch (error) {
    console.log("saga error");
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* deactivateLink(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      bearer: bearer,
      active: action.payload.active === "0" ? 1 : 0,
      id: action.payload.id,
    };
    let response = yield call(() => deActivateLinkAPI(data));
    response = JSON.parse(response);

    let linkResponse = yield call(() => handleAPIcallLink(bearer));
    linkResponse = JSON.parse(linkResponse);
    yield put({ type: "LINK_DATA", data: linkResponse.lists });
  } catch (error) {
    console.log("saga error", error);
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* updateLinkList() {
  try {
    const bearer = yield select((state) => state.admin.token);
    let linkResponse = yield call(() => handleAPIcallLink(bearer));
    linkResponse = JSON.parse(linkResponse);
    yield put({ type: "LINK_DATA", data: linkResponse.lists });
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* approveKYC() {
  try {
    const bearer = yield select((state) => state.admin.token);
    const selectedUser = yield select((state) => state.admin.searchUser);
    const data = {
      id: selectedUser.user.id,
      bearer: bearer,
    };
    let response = yield call(() => approveKYCAPI(data));
    response = JSON.parse(response);
    if (response.message === "KYC Status Updated.") {
      // let users = selectedUser
      // users.user.kyc_status = 'ACCEPTED'

      let response = yield call(() => userSearch(data));
      response = JSON.parse(response);
      yield put({ type: "SEARCHED_USER_RESPONSE", data: response });
      let allUsers = yield call(() => getAlluser(bearer));
      allUsers = JSON.parse(allUsers);
      yield put({ type: "GET_ALL_USER_DATA", data: allUsers.users });
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* updateDeactiveLink(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const allUser = yield select((state) => state.admin.getAlluserData);
    const data = {
      bearer: bearer,
      //  id: action.payload.id,
      //  isactive: action.payload.isAccountApprove === 1 ? "DEACTIVE" : "ACTIVE",

      title_en: action.payload.title_en,
      title_hi: action.payload.title_hi,
      amount: action.payload.amount,
      description_en: action.payload.description_en,
      description_hi: action.payload.description_hi,
      catagory: action.payload.catagory,
      public: action.payload.link_send_to_all ? 1 : 0,
      active: action.payload.isactive,
      id: action.payload.link_id,
      thumbnail: action.payload.thumbnail,
    };
    let response = yield call(() => updateLinkData(data));
    response = JSON.parse(response);
    if (response.message === "Data Updated Successfully.") {
      // let finalAllUser = allUser.map((user) => {
      //   console.log("action.payload", action.payload);
      //   if (action.payload.email === user.email) {
      //     user.status = data.isactive;
      //   }
      //   return user;
      // });
      // yield put({ type: "GET_ALL_USER_DATA", data: finalAllUser });
      let linkResponse = yield call(() => handleAPIcallLink(bearer));
      linkResponse = JSON.parse(linkResponse);
      yield put({ type: "LINK_DATA", data: linkResponse.lists });
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* minBalance(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      setting_value: action.payload,
      bearer: bearer,
    };

    let response1 = yield call(() => updateMinBal(data));
    response1 = JSON.parse(response1);
    if (response1.message === "Setting Updated") {
      // let response = yield call(handleAPIcallMinimumBalance);
      yield put({
        type: "SET_MINIMUM_BALANCE_DATA",
        data: data.setting_value,
      });
      yield put({ type: "DATA_SAVED", data: true });
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    console.log("saga error");
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* changeMarquees(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      bearer: bearer,
      setting_value: action.payload,
    };

    let response = yield call(() => updateMarqueeAPI(data));
    response = JSON.parse(response);
    if (response.message === "Setting Updated") {
      yield put({ type: "DATA_SAVED", data: true });
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    console.log("saga error", error);
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* addTraining(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      bearer: bearer,
      setting_value: action.payload,
    };

    let response = yield call(() => addTrainingLink(data));
    response = JSON.parse(response);
    if (response.message === "Setting Updated") {
      let trainingData = yield call(() => getTrainingVideo(bearer));
      trainingData = JSON.parse(trainingData);
      let trainingresp = JSON.parse(trainingData.settings[0].option_value);
      yield put({ type: "GET_TRAINING_DATA", data: trainingresp });
      yield put({ type: "DATA_SAVED", data: true });
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* getDetailTicket(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      bearer: bearer,
      id: action.payload,
    };

    let response = yield call(() => getDetailTicketAPI(data));
    response = JSON.parse(response);

    yield put({ type: "SET_CHAT_DETAILS", data: response });
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* getTestimonials() {
  try {
    const bearer = yield select((state) => state.admin.token);

    let response = yield call(() => getTestimonialsApi(bearer));
    response = JSON.parse(response);
    yield put({ type: "SET_TESTIMONIALS_DATA", data: response });
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* deletePerformer(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    let data = {
      bearer: bearer,
      id: action.payload.id,
    };
    let response = yield call(() => deletePerformerAPI(data));
    response = JSON.parse(response);
    if (response.message === "Testimonial deleted successfully") {
      let response1 = yield call(() => getTestimonialsApi(bearer));
      response1 = JSON.parse(response1);
      yield put({ type: "SET_TESTIMONIALS_DATA", data: response1 });
      yield put({ type: "DATA_SAVED", data: true });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

export function* submitPerformer(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    let data = {
      bearer: bearer,
      value: action.payload,
    };
    let response = yield call(() => getSubmitPerformer(data));
    response = JSON.parse(response);
    if (response.message === "Testimonial added successfully") {
      const bearer = yield select((state) => state.admin.token);

      let response = yield call(() => getTestimonialsApi(bearer));
      response = JSON.parse(response);
      yield put({ type: "SET_TESTIMONIALS_DATA", data: response });
      yield put({ type: "DATA_SAVED", data: true });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

export function* getAllPaymentPending() {
  try {
    const bearer = yield select((state) => state.admin.token);
    let data = {
      bearer: bearer,
      // value: action.payload,
    };
    let response = yield call(() => allPendingPaymentAPI(data));
    response = JSON.parse(response);
    yield put({ type: "SET_PENDING_PAYMENT", data: response?.payments });
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* setPaymentRequest(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    let data = {
      bearer: bearer,
      id: action.payload.pid,
      status: action.payload.reject ? "REJECTED" : "APPROVED",
      tds: action.payload.deductible_amount,
      comment: {
        transaction_id: action.payload.tid,
        amount: action.payload.amount,
        date: action.payload.date,
        comment: action.payload.other,
      },
    };
    console.log("data saga", data);
    let response = yield call(() => makePaymentRequest(data));
    response = JSON.parse(response);
    console.log("response", response.message);
    if (response.message === "status updated for the payment") {
      let data = {
        bearer: bearer,
        // value: action.payload,
      };
      let response = yield call(() => allPendingPaymentAPI(data));
      response = JSON.parse(response);

      yield put({ type: "SET_PENDING_PAYMENT", data: response.payments });
      const currentPage = window.location.href;
      if (currentPage.includes("SearchedUser")) {
        const userId = yield select((state) => state.admin.searchUser);
        const data = {
          id: userId.user.id,
          bearer: bearer,
        };
        let response = yield call(() => userSearch(data));
        response = JSON.parse(response);
        yield put({ type: "SEARCHED_USER_RESPONSE", data: response });
      }
      yield put({ type: "DATA_SAVED", data: true });
    } else if (response.message === "User don't have enough balance.") {
      yield put({ type: "INSUFICIENT_BALANCE", data: true });
      let data = {
        bearer: bearer,
        // value: action.payload,
      };
      let response = yield call(() => allPendingPaymentAPI(data));
      response = JSON.parse(response);

      yield put({ type: "SET_PENDING_PAYMENT", data: response.payments });
      const currentPage = window.location.href;
      if (currentPage.includes("SearchedUser")) {
        const userId = yield select((state) => state.admin.searchUser);
        const data = {
          id: userId.user.id,
          bearer: bearer,
        };
        let response = yield call(() => userSearch(data));
        response = JSON.parse(response);
        yield put({ type: "SEARCHED_USER_RESPONSE", data: response });
      }
    } else {
      yield put({ type: "SAGA_ERROR", data: true });
    }
  } catch (error) {
    console.log("saga error", error);
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

export function* getAllChatDetails(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      bearer: bearer,
      id: action.payload.id,
    };

    let response = yield call(() => getDetailTicketAPI(data));
    response = JSON.parse(response);

    yield put({ type: "SET_CHAT_DETAILS", data: response });

    const data1 = {
      bearer: bearer,
      id: action.payload.userId,
    };
    let response1 = yield call(() => userSearch(data1));
    response1 = JSON.parse(response1);

    yield put({ type: "SEARCHED_USER_RESPONSE", data: response1 });
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* setReplyData(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    const data = {
      bearer: bearer,
      ticket_id: action.payload.ticket_id,
      status: action.payload.status,
      comment: action.payload.comment,
    };

    let response = yield call(() => setReplyAPI(data));
    response = JSON.parse(response);

    if (response.message === "Comment added") {
      const data = {
        bearer: bearer,
        id: action.payload.ticket_id,
      };
      let response = yield call(() => getDetailTicketAPI(data));
      response = JSON.parse(response);

      yield put({ type: "SET_CHAT_DETAILS", data: response });
      yield put({ type: "DATA_SAVED", data: true });
    }
  } catch (error) {
    console.log("saga error", error);
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

export function* getPaymentCompleteList() {
  try {
    const bearer = yield select((state) => state.admin.token);

    let response = yield call(() => getCompletePaymentAPI(bearer));
    response = JSON.parse(response);
    yield put({ type: "SET_COMPLETE_PAYMENT", data: response.payments });
    let response1 = yield call(() => setRejectListAPI(bearer));
    response1 = JSON.parse(response1);
    yield put({ type: "SET_REJECTED_LIST", data: response1?.tickets });
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

export function* submitSlider(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    let data = {
      bearer: bearer,
      title: action.payload.title,
      url: action.payload.url,
      photo: action.payload.photo,
    };
    let response = yield call(() => setSliderAPI(data));
    response = JSON.parse(response);
    if (response.message === "Banner added successfully") {
      let response1 = yield call(() => getSliderAPI(data));
      response1 = JSON.parse(response1);
      yield put({ type: "GET_SLIDER_DATA2", data: response1 });
      yield put({ type: "DATA_SAVED", data: true });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

export function* deleteSlider(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    let data = {
      bearer: bearer,
      id: action.payload.id,
    };
    let response = yield call(() => deleteSliderAPI(data));
    response = JSON.parse(response);
    if (response.message === "Banner deleted successfully") {
      let response1 = yield call(() => getSliderAPI(data));
      response1 = JSON.parse(response1);
      yield put({ type: "GET_SLIDER_DATA2", data: response1 });
      yield put({ type: "DATA_SAVED", data: true });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

function* sendNotification(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    let data = {
      bearer: bearer,
      title: action.payload.title,
      body: action.payload.body,
    };
    let response = yield call(() => setNotificationDataAPI(data));
    response = JSON.parse(response);
    if (response.message === "Notification sent to users.") {
      yield put({ type: "DATA_SAVED", data: true });
    }
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

export function* getRejectedLists() {
  try {
    const bearer = yield select((state) => state.admin.token);

    let response = yield call(() => setRejectListAPI(bearer));
    response = JSON.parse(response);
    yield put({ type: "SET_REJECTED_LIST", data: response?.tickets });
  } catch (error) {
    yield put({ type: "GET_USERS_FAILED", message: error.message });
    yield put({ type: "SAGA_ERROR", data: true });
  }
}

export function* changePassword(action) {
  try {
    const bearer = yield select((state) => state.admin.token);
    let data = {
      bearer: bearer,
      oldPass: action.payload.oldPass,
      newPass: action.payload.newPass,
    };

    let response = yield call(() => setNewPass(data));
    response = JSON.parse(response);
    console.log("response", response);
    if (response.message === "Password updated.") {
      yield put({ type: "DATA_SAVED", data: true });
    } else {
      yield put({ type: "PASSWORD_ERROR", data: true });
    }
  } catch (error) {
    yield put({ type: "PASSWORD_ERROR", data: true });
  }
}

function* userSaga() {
  //  yield takeEvery("GET_USERS_REQUESTED", getApiData);
  yield takeEvery("PRE_REQUIRED_DATA", fetchUsers);
  yield takeEvery("SEARCH_USER", searchUser);
  yield takeEvery("SET_MINIMUM_BALANCE", minBalance);
  yield takeEvery("SET_LINK_SHARE_DATA", setLinkData);
  yield takeEvery("SUBMIT_VIDEO", addTraining);
  yield takeEvery("DELETE_VIDEO", submitDeleteVideo);
  //yield takeEvery("CHANGE_USER_PERMISSION", changeUserPermission);
  yield takeEvery("UPDATE_LINK", deactivateLink);
  yield takeEvery("APPROVE_INCOMPLETE_LIST", approveIncompleteList);
  yield takeEvery("CHANGE_MARQUEE", changeMarquees);
  yield takeEvery("GET_USERNAME_PASSWORD", checkUserNamePassword);
  yield takeEvery("GET_USER_TICKET", getUserTicket);
  yield takeEvery("FORGOT_SUBMIT_OTP", forgotSubmitOtp);
  yield takeEvery("SEND_FORGOT_EMAIL", sendForgotEmail);
  yield takeEvery("SET_CHANGE_PASS", setPassChange);

  //New API changes
  yield takeEvery("CHECK_LOGIN", checkLoginAPI);
  yield takeEvery("SUBMIT_PRIVACY_POLICY", submitPrivacyPolicy);
  yield takeEvery("SUBMIT_ABOUT", submitAboutPolicy);
  yield takeEvery("TARGET_LINK", targetDisplayLink);
  yield takeEvery("CHANGE_USER_PERMISSION", changeUserPermissions);
  yield takeEvery("SET_SEARCH_BOX", searchBox);
  yield takeEvery("UPDATE_LINK_LIST", updateLinkList);
  yield takeEvery("APROVE_KYC", approveKYC);
  yield takeEvery("GET_DETAIL_TICKET", getDetailTicket);
  yield takeEvery("UPDATE_DEACTIVE_LINK", updateDeactiveLink);
  yield takeEvery("GET_TESTIMONIALS", getTestimonials);
  yield takeEvery("DELETE_PERFORMER", deletePerformer);
  yield takeEvery("SUBMIT_PERFORMER", submitPerformer);
  yield takeEvery("GET_PENDING_PAYMENT", getAllPaymentPending);
  yield takeEvery("SET_PAYMENT_REQUEST", setPaymentRequest);
  yield takeEvery("GET_ALL_CHAT_DETAILS", getAllChatDetails);
  yield takeEvery("SET_REPLY_DATA", setReplyData);
  yield takeEvery("GET_PAYMENT_COMPLETE_LIST", getPaymentCompleteList);
  yield takeEvery("SUBMIT_SLIDER", submitSlider);
  yield takeEvery("DELETE_SLIDER", deleteSlider);
  yield takeEvery("SEND_NOTIFICATION", sendNotification);
  yield takeEvery("REJECTED_LIST", getRejectedLists);
  yield takeEvery("CHANGE_PASS", changePassword);
}

export default userSaga;
