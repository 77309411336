//login API
export function loginAPICall(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

  var urlencoded = new URLSearchParams();
  urlencoded.append("email", data.email);
  urlencoded.append("password", data.password);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: urlencoded,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/login/", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//About section
export function handleAboutSection(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = { id: 2, option_value: data.about };

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/setting/update", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Privacy section
export function handlePrivacySection(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = { id: 9, option_value: data.about };

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/setting/update", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//ALL links

export function handleAPIcallLink(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/task/list", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Target Link

export function tagetLink(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    `https://araffiliates.com/api/task/get/${data.id}`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//All incomplete list

export function allIncompleteList(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    "https://araffiliates.com/api/task/tickets/submited",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//All complete list

export function allCompleteList(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    "https://araffiliates.com/api/task/tickets/completed",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//changes User status

export function changeUserStatus(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "text/plain");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = { status: data.isactive };

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    `https://araffiliates.com/api/users/user/${data.id}/status`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//get All Users

export function getAlluser(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/users", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Complete task

export function completeTask(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = JSON.stringify({
    ticket_id: data.id,
    status: data.status,
    comment: data.comment,
  });

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    "https://araffiliates.com/api/task/ticket/update",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Search API

export function searchAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    `https://araffiliates.com/api/users/search?q=${data.search}`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//User search

export function userSearch(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    `https://araffiliates.com/api/users/user/${data.id}`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Pre Required data

export function preRequiredCall() {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    mode: "cors",
    "Cache-Control": "no-cache",
  };

  return fetch("/api/static/dashboar.json", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Link share

export function linkShareAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var formdata = new FormData();
  formdata.append("title_en", data.title_en);
  formdata.append("title_hi", data.title_hi);
  formdata.append("url", data.url);
  formdata.append("amount", data.amount);
  formdata.append("description_en", data.description_en);
  formdata.append("description_hi", data.description_hi);
  formdata.append("catagory", data.catagory);
  formdata.append("public", data.public);
  formdata.append("active", data.active);
  formdata.append("user_ids", data.user_ids);
  formdata.append("thumbnail", data.thumbnail, data.thumbnail.name);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/task/create/new", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Deactivate Link

export function deActivateLinkAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var formdata = new FormData();
  // formdata.append("title_en", data.title_en);
  // formdata.append("title_hi", data.title_hi);
  // formdata.append("url", data.url);
  // formdata.append("amount", data.amount);
  // formdata.append("description_en", data.description_en);
  // formdata.append("description_hi", data.description_hi);
  // formdata.append("catagory", data.catagory);
  // formdata.append("public", data.public);
  formdata.append("active", data.active);
  formdata.append("id", data.id);
  // formdata.append('thumbnail', data.thumbnail, data.thumbnail.name)

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/task/update", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Approve kyc status

export function approveKYCAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = JSON.stringify({
    user_id: data.id,
    status: "ACCEPTED",
  });

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/users/kyc/status", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// update Link data

export function updateLinkData(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var formdata = new FormData();
  formdata.append("title_en", data.title_en);
  formdata.append("title_hi", data.title_hi);
  formdata.append("url", data.url);
  formdata.append("amount", data.amount);
  formdata.append("description_en", data.description_en);
  formdata.append("description_hi", data.description_hi);
  formdata.append("catagory", data.catagory);
  formdata.append("public", data.public);
  formdata.append("active", data.active);
  formdata.append("id", data.id);
  formdata.append("thumbnail", data.thumbnail, data.thumbnail.name);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/task/update", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Min balanace

export function minBalanceAPI(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    "https://araffiliates.com/api/setting/MIN_WITHDRAWAL_LIMIT",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Update Min balance

export function updateMinBal(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = { id: 1, option_value: data.setting_value };

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/setting/update", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//GET PAYMENT REQUEST

export function getPaymentRequest(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    "https://araffiliates.com/api/payment/tickets/all",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Marquee

export function updateMarqueeAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = { id: 7, option_value: data.setting_value };

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/setting/update", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// Training

export function getTrainingVideo(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/setting/training", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Add Training link

export function addTrainingLink(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = { id: 4, option_value: JSON.stringify(data.setting_value) };

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: JSON.stringify(raw),
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/setting/update", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//get Open chat

export function getOpenChat(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/chat/list/OPEN", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//get Detail Ticket

export function getDetailTicketAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    `https://araffiliates.com/api/chat/${data.id}/details`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//get Testimonials

export function getTestimonialsApi() {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    "https://araffiliates.com/api/static/testimonials",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// Delete Performer

export function deletePerformerAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    `https://araffiliates.com/api/testimonials/${data.id}`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// Get Performer

export function getSubmitPerformer(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var formdata = new FormData();
  formdata.append("title", data.value.title);
  formdata.append("description", data.value.desc);
  formdata.append("link", data.value.url);
  formdata.append("subtitle", data.value.subTitle);
  formdata.append("photo", data.value.photo, data.value.photo.name);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/testimonials", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// Get all pending Payment

export function allPendingPaymentAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    "https://araffiliates.com/api/payment/tickets/PENDING",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Make Payment Request

export function makePaymentRequest(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "text/plain");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = JSON.stringify({
    status: data.status,
    //  comment: data.comment,
    comment: data.comment.other,
    TDS: data.tds,
  });

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    `https://araffiliates.com/api/payment/tickets/${data.id}/update`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// SET REPLY

export function setReplyAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "text/plain");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = JSON.stringify({
    comment: data.comment,
    status: data.status,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    `https://araffiliates.com/api/chat/${data.ticket_id}/comment`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// GET COMPLETE PAYMENT REQUEST

export function getCompletePaymentAPI(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    "https://araffiliates.com/api/payment/tickets/APPROVED",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// SET SLIDER

export function setSliderAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var formdata = new FormData();
  formdata.append("title", data.title);
  formdata.append("link", data.url);
  formdata.append("photo", data.photo);

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: formdata,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/banners", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//GET SLIDER

export function getSliderAPI() {
  var requestOptions = {
    method: "GET",
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/static/banners", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//Delete Slider

export function deleteSliderAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var requestOptions = {
    method: "DELETE",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    `https://araffiliates.com/api/banners/${data.id}`,
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

//NOTIFICATION

export function setNotificationDataAPI(data) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "text/plain");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = JSON.stringify({
    title: data.title,
    body: data.body,
  });

  var requestOptions = {
    method: "POST",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(`https://araffiliates.com/api/notification`, requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// REJECTED LIST

export function setRejectListAPI(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    "https://araffiliates.com/api/task/tickets/rejected",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// About_us

export function getAboutUs(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch("https://araffiliates.com/api/setting/about_us", requestOptions)
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// privacy

export function getPrivacy(bearer) {
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + bearer);

  var requestOptions = {
    method: "GET",
    headers: myHeaders,
    redirect: "follow",
    "Cache-Control": "no-cache",
  };

  return fetch(
    "https://araffiliates.com/api/setting/privacy_policy",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}

// NEW PASSWORD

export function setNewPass(data) {
  console.log("data", data);
  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  myHeaders.append("Authorization", "Bearer " + data.bearer);

  var raw = JSON.stringify({
    old_password: data.oldPass,
    new_password: data.newPass,
  });

  var requestOptions = {
    method: "PATCH",
    headers: myHeaders,
    body: raw,
    redirect: "follow",
  };

  return fetch(
    "https://araffiliates.com/api/users/update/password",
    requestOptions
  )
    .then((response) => response.text())
    .catch((error) => console.log("error", error));
}
