import { Typography } from "antd";
import { Input, Row, Col, Button, Modal, List, Spin, Radio } from "antd";
import { FireTwoTone, LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../css/dashboard.css";
import {
  submitDeleteVideo,
  submitVideo,
} from "../../../../store/actions/users";

const Training = () => {
  const [openModel, setOpenModel] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);
  const loading = useSelector((state) => state.admin.loading);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const trainingLink = useSelector((state) => state.admin.trainingData);
  const [itemData, setItemData] = useState({});
  const [deleteItem, setDeleteItem] = useState();
  const dispatch = useDispatch();
  const optionsWithDisabled = [
    { label: "Inactive", value: "Inactive" },
    { label: "Active", value: "Active" },
  ];
  const [addVideoData, setAddVideoData] = useState({
    key: Date.now(),
    title: "",
    url: "",
  });

  const onChange4 = (item) => {
    setItemData(item);
    setOpenModel(true);
  };

  function handleAddOk() {
    let pastObj = [addVideoData];
    let finalArr = trainingLink.concat(pastObj);
    dispatch(submitVideo(finalArr));
    setAddVideoData({
      key: "",
      title: "",
      url: "",
    });
    setOpenAddModel(false);
  }

  function handleAddCancel() {
    setAddVideoData({
      title: "",
      url: "",
    });
    setOpenAddModel(false);
  }

  function handleOkDelete() {
    let arr = [];
    trainingLink.map((list) => {
      if (list.key !== deleteItem.key) {
        arr.push(list);
      }
    });
    //  deleteItem
    dispatch(submitVideo(arr));
    setOpenModel(false);
  }

  function handleCancelDelete() {
    setOpenModel(false);
  }
  function handleOpenPopup(item) {
    item.key = Date.now();
    setDeleteItem(item);
    setOpenModel(true);
  }

  return (
    <div>
      <Row style={{ paddingLeft: "1%", paddingTop: "1%", paddingRight: "1%" }}>
        <Col span="24" style={{ display: "flex" }}>
          <Typography.Title level={4}>Training</Typography.Title>
          <Button
            onClick={() => setOpenAddModel(true)}
            danger
            style={{ marginLeft: "auto", marginRight: "20px", width: "150px" }}
          >
            {" "}
            + Add
          </Button>
        </Col>
        <Col span={24} style={{ marginTop: "30px" }}>
          <List
            itemLayout="horizontal"
            dataSource={trainingLink}
            pagination={{ defaultPageSize: 8 }}
            loading={loading}
            key={"link_id"}
            renderItem={(item, index) => (
              <List.Item className="CursorChange">
                <List.Item.Meta
                  avatar={<FireTwoTone twoToneColor="red" />}
                  title={
                    <div style={{ display: "flex" }}>
                      {" "}
                      <Typography.Text>{item.title} </Typography.Text>
                    </div>
                  }
                  description={
                    <>
                      <Typography.Text>{item.url}</Typography.Text>
                    </>
                  }
                />
                {loading ? (
                  <Spin
                    size="large"
                    style={{ alignSelf: "self-end" }}
                    indicator={antIcon}
                  />
                ) : (
                  <Button
                    type="primary"
                    danger
                    onClick={() => handleOpenPopup(item)}
                  >
                    Delete
                  </Button>
                )}
              </List.Item>
            )}
          />
          <Modal
            title="Confirmation"
            open={openModel}
            onOk={handleOkDelete}
            onCancel={handleCancelDelete}
          >
            <Typography>
              {" "}
              Are you sure you want to delete this video?{" "}
            </Typography>
          </Modal>
          <Modal
            title="Add Video"
            open={openAddModel}
            onOk={handleAddOk}
            okButtonProps={{
              disabled: addVideoData.title === "" || addVideoData.url === "",
            }}
            onCancel={handleAddCancel}
          >
            <div>
              <Input
                size="large"
                placeholder="Enter Title"
                value={addVideoData.title}
                onChange={(e) => {
                  let data = { title: e.target.value };
                  setAddVideoData((list) => ({
                    ...list,
                    ...data,
                  }));
                }}
              ></Input>{" "}
              <br></br>
              <br></br>
              <Input
                size="large"
                value={addVideoData.url}
                placeholder="Enter URL"
                onChange={(e) => {
                  let data = { url: e.target.value };
                  setAddVideoData((list) => ({
                    ...list,
                    ...data,
                  }));
                }}
              ></Input>
            </div>
          </Modal>
        </Col>
      </Row>
    </div>
  );
};

export default Training;

// dispatch(submitVideo(sendVideo))
// dispatch(submitDeleteVideo(deleteVideo))
