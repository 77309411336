import { Typography } from "antd";
import { Space, AutoComplete, Row, Col, List, Button, Avatar } from "antd";
import { CloseSquareFilled } from "@ant-design/icons";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  searchUsers,
  getUserTicket,
  getAllChatDetails,
  chatDetail,
} from "../../../../store/actions/users";
import { useDispatch } from "react-redux";

function Ticket() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [searchError, setSearchError] = useState(false);
  const [searchData, setSearchData] = useState({});
  const usersSearchList = useSelector((state) => state.admin.searchResult);
  let options = [];
  usersSearchList?.map((list) => {
    options.push({ value: `${list.name}  =>  ${list.mobile}`, id: list.id });
  });

  function handleSearch(data, id) {
    dispatch(chatDetail(id.id));
    dispatch(searchUsers(id.id));
    navigate("/userTicket");
    // setSearchData(email);
    setSearchError(false);
  }

  function handleSubmit() {
    if (searchData.email) {
      dispatch(searchUsers(searchData.email));
      navigate("/userTicket");
    } else {
      setSearchError(true);
    }
  }

  return (
    <div>
      <Row style={{ paddingLeft: "1%", paddingTop: "1%", paddingRight: "1%" }}>
        <Col span="24">
          <Typography.Title level={4}>Ticket</Typography.Title>
        </Col>

        <Space.Compact style={{ width: "98%", marginTop: "1%" }}>
          {/* <AutoComplete
                style={{ width: "100%" }}
                onSelect={handleSearch}
                placeholder="input here"
                options={options}
                onSearch={(value)=>dispatch(setSearchBox(value))}
                size="large"
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                allowClear={{ clearIcon: <CloseSquareFilled /> }}
                notFoundContent="No user found -> Please login again"
              /> */}
          {/* <Button type="primary" size="large" onClick={handleSubmit}>
            Submit
          </Button> */}
        </Space.Compact>
        {searchError ? (
          <Typography.Text style={{ color: "red" }}>
            {" "}
            Please select option from suggested dropdown{" "}
          </Typography.Text>
        ) : (
          ""
        )}
        <Col span={24}>
          <div style={{ marginTop: "2%", marginRight: "17px" }}>
            <ListData />
          </div>
        </Col>
      </Row>
    </div>
  );
}

export function ListData() {
  const navigate = useNavigate();
  const userData = useSelector((state) => state.admin.usersData);
  // const usersSearchList = useSelector((state) => state.admin.allUserData);
  const openChatSelector = useSelector((state) => state.admin.openChat);
  const dispatch = useDispatch();
  let listData = [];

  openChatSelector?.map((list) => {
    if (list.status === "OPEN") {
      let listObj = {
        userName: list.name, //list.userName,
        kycStatus: list.kyc_status, // list.kycStatus,
        //  isAccountApprove: list.isAccountApprove,
        userID: list.user_id,
        id: list.id,
        title: list.title,
        created_at: list.created_at,
      };
      listData.push(listObj);
    }
  });

  return (
    <>
      <List
        itemLayout="horizontal"
        dataSource={listData}
        pagination={{ defaultPageSize: 20 }}
        key="rjid"
        renderItem={(item, index) => (
          <List.Item key={index}>
            <List.Item.Meta
              className="CursorChange"
              onClick={() => {
                // dispatch(chatDetail(item.id));
                // dispatch(searchUsers(item.userID));
                let data = {
                  id: item.id,
                  userId: item.userID,
                };
                dispatch(getAllChatDetails(data));

                navigate("/userTicket");
              }}
              avatar={
                <Avatar
                  src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                />
              }
              title={item.userName}
              description={
                <>
                  <Typography.Text>Title: {item.title}</Typography.Text>
                  <br />
                  <b>Date: {item.created_at}</b>
                  <br />
                  <b> Kyc Status: </b>
                  {item.kycStatus !== "ACCEPTED" ? (
                    <span style={{ color: "red" }}> Not Complete</span>
                  ) : (
                    <span style={{ color: "green" }}> Complete</span>
                  )}
                </>
              }
            />
          </List.Item>
        )}
      />
    </>
  );
}

export default Ticket;
