import { Typography, Row, Col, Button, Card, DatePicker, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import * as XLSX from "xlsx";
import { useEffect, useState } from "react";
import moment from "moment";
import { getPaymentCompleteList } from "../../../../store/actions/users";

function Report() {
  const { RangePicker } = DatePicker;
  const [completeDateStart, setCompletedDateStart] = useState("");
  const [completeDateEnd, setCompletedDateEnd] = useState("");
  const [paymentDateStart, setPaymentDateStart] = useState("");
  const [paymentDateEnd, setPaymentDateEnd] = useState("");
  const dispatch = useDispatch();
  const completedList = useSelector((state) => state.admin.completeList);
  const paymentComplete = useSelector((state) => state.admin.paymentComplete);
  const inCompleteList = useSelector((state) => state.admin.incompleteList);
  const rejectList = useSelector((state) => state.admin.rejectedList);
  const [finalCompletedList, setFinalCompletedList] = useState([]);
  const [finalPaymentList, setFinalPaymentList] = useState([]);
  const [dropDownValue, setDropDownValue] = useState("Completed");

  useEffect(() => {
    dispatch(getPaymentCompleteList());
  }, []);

  useEffect(() => {
    let key;
    setFinalCompletedList([]);
    if (dropDownValue === "Completed") {
      key = completedList;
    } else if (dropDownValue === "Incompleted") {
      key = inCompleteList;
    } else {
      key = rejectList;
    }
    key.map((list) => {
      let strDate = list.modified_at.substring(0, 10);
      let date1 = new Date(completeDateStart);
      let dateE = new Date(completeDateEnd);
      let date2 = new Date(strDate);
      if (date1 <= date2 && date2 <= dateE) {
        let data = {
          User_Id: list.user_id,
          User_Name: list.user_name,
          Customer_Name: list.customer_name,
          Customer_Number: list.customer_mobile,
          Title: list.title_en,
          Url: list.url,
          //   status: list.status,
          //   kyc_status: list.kyc_status,
          Amount: list.amount,
          Date: list.modified_at,
          Status: list.status,
        };
        if (dropDownValue === "Rejected") {
          data.Reject_Reason = list.admin_data;
        } else if (dropDownValue !== "Rejected") {
          data.Comment = list.data;
        }
        setFinalCompletedList((old) => [...old, data]);
      }
    });
  }, [completeDateStart, completeDateEnd, dropDownValue]);

  useEffect(() => {
    paymentComplete.map((list) => {
      let strDate = list.modified_at.substring(0, 10);
      let date1 = new Date(paymentDateStart);
      let dateE = new Date(paymentDateEnd);
      let date2 = new Date(strDate);
      if (date1 <= date2 && date2 <= dateE) {
        let data = {
          User_Name: list.name,
          User_Id: list.user_id,
          // url: list.url,
          //  title: list.title_en,
          Status: list.status,
          Kyc_Status: list.kyc_status,
          Amount: list.amount,
          Date: list.modified_at,
          TDS: list.TDS,
          Paid_Amount: list.amount - list.TDS,
        };
        setFinalPaymentList((old) => [...old, data]);
      }
    });
  }, [paymentDateStart, paymentDateEnd]);

  const handleCompleteChange = (date, dateString) => {
    setCompletedDateStart(dateString[0]);
    setCompletedDateEnd(dateString[1]);
  };

  const handlePaymentChange = (date, dateString) => {
    setPaymentDateStart(dateString[0]);
    setPaymentDateEnd(dateString[1]);
  };

  function handleCompleteClick() {
    const worksheet = XLSX.utils.json_to_sheet(finalCompletedList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, `${dropDownValue}.xlsx`);
  }

  function handlePaymentClick() {
    const worksheet = XLSX.utils.json_to_sheet(finalPaymentList);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "PaymentList.xlsx");
  }

  return (
    <div style={{ all: "initial" }}>
      <Card
        style={{
          marginTop: "10px",
          marginRight: "10px",
          paddingBottom: "20px",
          height: "100%",
          backgroundColor: "rgba(237, 237, 237, 0.8)",
        }}
        type="inner"
      >
        <div style={{ height: "100%", width: "100%" }}>
          <Row
            style={{ paddingTop: "1%", paddingLeft: "1%", paddingRight: "1%" }}
          >
            <Col span="24">
              <Typography.Title level={4}>Report</Typography.Title>
            </Col>
          </Row>
          <Row>
            <Select
              defaultValue="Completed"
              style={{ width: 120, marginBottom: "10px" }}
              options={[
                { value: "Completed", label: "Completed" },
                { value: "Incompleted", label: "Incompleted" },
                { value: "Rejected", label: "Rejected" },
              ]}
              onChange={(value) => setDropDownValue(value)}
            />
            <div style={{ display: "flex" }}>
              <Col span={11}>
                <Card
                  title={`${dropDownValue} Request`}
                  style={{ width: "100%" }}
                >
                  <RangePicker onChange={handleCompleteChange} />
                  <br />
                  <br />
                  <Typography.Text>
                    Click on below button to download excel sheet for completed
                    Request Data.
                  </Typography.Text>
                  <br /> <br />
                  <Button
                    onClick={handleCompleteClick}
                    disabled={
                      completeDateStart === "" || completeDateEnd === ""
                    }
                    style={{ width: "100%" }}
                    danger
                  >
                    Download
                  </Button>
                </Card>
              </Col>
              <Col span={2}></Col>
              <Col span={11}>
                <Card title="Payment Request" style={{ width: "100%" }}>
                  <RangePicker onChange={handlePaymentChange} />
                  <br />
                  <br />
                  <Typography.Text>
                    Click on below button to download excel sheet for completed
                    Request Data.
                  </Typography.Text>
                  <br />
                  <br />
                  <Button
                    onClick={handlePaymentClick}
                    disabled={paymentDateStart === "" || paymentDateEnd === ""}
                    style={{ width: "100%" }}
                    danger
                  >
                    Download
                  </Button>
                </Card>
              </Col>
            </div>
          </Row>
        </div>
      </Card>
    </div>
  );
}

export default Report;
