import { Space, Typography } from "antd";
import "../../css/dashboard.css";
import Header from "./header";
import PageContent from "./pageContent";
import Footer from "./footer";
import SideMenu from "./sideMenu";
import { Row, Col, message } from "antd";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";

function Dashboard() {
  const userAvailable = useSelector((state) => state.admin.users);
  const logoutStatus = useSelector((state) => state.admin.logout);
  const logout = useSelector((state) => state.admin.logout);
  const navigate = useNavigate();
  let count = 0;

  useEffect(() => {
    if (count === 0) {
      // message.success("Log In");
      count = count + 1;
    }
  });

  return (
    <>
      {userAvailable === "" ? (
        <>
          {logoutStatus ? (
            <>{navigate("/")}</>
          ) : (
            <>
              {logout ? (
                ""
              ) : (
                <>
                  {alert("Wrong Url. Please go to login page")}
                  {(window.location = "/")}
                </>
              )}
            </>
          )}
        </>
      ) : (
        <div id="dashboard">
          <Row type="flex">
            <Col span={24}>
              <Header />
            </Col>
            <Col span={4} style={{ marginBottom: "17%" }}>
              <SideMenu></SideMenu>
            </Col>
            <Col span={20}>
              <PageContent></PageContent>
            </Col>

            {/* <Col span={24}>
            <Footer />
          </Col> */}
          </Row>
        </div>
      )}
    </>
  );
}

export default Dashboard;
