export const usersData = [
  {
    Userid: "ybhomm1",
    userName: "yash bhomle",
    Phone_Number: "8390782181",
    Link: "hhtps://google.co",
    Address: "New yeshwant Nagar",
    title:'ICICI Link',
    cName:'Amit',
    cMobile:'9898768374',
    email:'yash@gmail.com',
    kycStatus: "false",
    isAccountApprove: false,
    inCompleteRequest: [
      {
        comment: "I have worked on icici bank",
        date: "20/03/2023",
        Amount: "900",
        link: "https://iciciBank.com",
      },
      {
        comment: "Worked on Tata app",
        date: "08/03/2023",
        Amount: "700",
        link: "https://angleBroking.com",
      },
    ],
    CompletedRequest: [
    ],
    paymentRequest: [
      {
        paymentComment: "hey, i need money for my task",
        Amount: "200",
        date: "20/03/2023",
      },
    ],
  },
  {
    Userid: "sanketkhat",
    userName: "Sanket Khatdeo",
    Phone_Number: "8888996801",
    Address: "New yeshwant Nagar",
    kycStatus: "true",
    title:'Angel Link',
    cName:'Sumedh',
    email:'sumedh@gmail.com',
    cMobile:'6798768374',
    isAccountApprove: false,
    amount: "400",
    inCompleteRequest: [
      {
        comment: "I worked on Angel broker",
        date: "25/03/2023",
        Amount: "300",
        link: "hhtps://google.co",
      },
    ],
    CompletedRequest: [
      {
        comment: "I have worked on Angel broker",
        date: "26/03/2023",
      },
    ],
    paymentRequest: [
      {
        paymentComment: "I have completed my task",
        Amount: "200",
        date: "20/03/2023",
      },
    ],
  },
  {
    Userid: "MahirKir",
    userName: "Mahir",
    Phone_Number: "999988675",
    Amount: "350",
    Address: "New yeshwant Nagar",
    kycStatus: "true",
    title:'Tata Link',
    cName:'Sumit',
    cMobile:'7698766674',
    email:'sumit@gmail.com',
    isAccountApprove: true,
    inCompleteRequest: [
      {
        comment: "I worked on tata",
        date: "23/05/2020",
        Amount: "350",
        link: "https://tataPlay.com",
      },
    ],
    CompletedRequest: [
      {
        comment: "I have worked on tata",
        date: "21/03/2023",
      },
    ],
    paymentRequest: [
      {
        paymentComment: "Please send me money",
        Amount: "350",
        date: "23/05/2020",
      },
    ],
  },
  {
    Userid: "Nitink",
    userName: "Nitin Kamat",
    Phone_Number: "909098989",
    Amount: "980",
    Address: "New yeshwant Nagar",
    kycStatus: "false",
    title:'Jio fiber Link',
    cName:'Yash',
    cMobile:'8898868874',
    email:'Riya@gmail.com',
    isAccountApprove: false,
    inCompleteRequest: [
      {
        comment: "Worked on jio fiber",
        date: "25/03/2023",
        Amount: "900",
        link: "https://jioFiber.com",
      },
    ],
    CompletedRequest: [
      {
        comment: "Worked on jio fiber",
        date: "26/03/2023",
      },
    ],
    paymentRequest: [
      {
        paymentComment: "Send me 2000rs",
        Amount: "980",
        date: "25/03/2023",
      },
    ],
  },
  {
    Userid: "shubhamBn",
    userName: "Shubham Dumb",
    Phone_Number: "786958945",
    Amount: "100",
    Address: "New yeshwant Nagar",
    kycStatus: "ture",
    title:'Tata Play Link',
    cName:'Ashok',
    cMobile:'7798768320',
    email:'ashok@gmail.com',
    isAccountApprove: true,
    inCompleteRequest: [
      {
        comment: "Tata play link",
        date: "19/10/2023",
        Amount: "100",
        link: "https://TataPlay.com",
      },
    ],
    CompletedRequest: [
      {
        comment: "Tata play link",
        date: "20/10/2023",
      },
    ],
    paymentRequest: [
      {
        paymentComment: "Task is completed on time",
        Amount: "980",
        date: "19/10/2023",
      },
    ],
  },
  {
    Userid: "Kishorsdf",
    userName: "Kishor Kamat",
    Phone_Number: "7878593829",
    Amount: "700",
    Address: "New yeshwant Nagar",
    kycStatus: "false",
    isAccountApprove: true,
    title:'ICICI Link',
    email:'suresh@gmail.com',
    cName:'Angel',
    cMobile:'9898766523',
    inCompleteRequest: [
      {
        comment: "Angle broker account",
        date: "08/03/2021",
        Amount: "650",
        link: "https://angleBroker.com",
      },
    ],
    CompletedRequest: [
      {
        comment: "Angle broker account",
        date: "08/03/2021",
      },
    ],
    paymentRequest: [
      {
        paymentComment: "Please send money on my account",
        Amount: "650",
        date: "08/05/2023",
      },
    ],
  },
  {
    Userid: "kumarSa",
    userName: "Kumar Sanu",
    Phone_Number: "678233829",
    Amount: "700",
    Address: "New yeshwant Nagar",
    kycStatus: "false",
    title:'Tata play Link',
    cName:'Kiran',
    email:'kishor@gmail.com',
    cMobile:'8894728400',
    isAccountApprove: true,
    inCompleteRequest: [
      {
        comment: "I worked on tata",
        date: "20/03/2023",
        Amount: "870",
        link: "https://iciciBank.com",
      },
    ],
    CompletedRequest: [
      {
        comment: "I worked on tata",
        date: "20/03/2023",
      },
    ],
    paymentRequest: [
      {
        paymentComment: "Send full money",
        Amount: "870",
        date: "20/05/2023",
      },
    ],
  },
];
