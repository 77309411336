import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Kyc from "../pages/Kyc";
import CreateAdmin from "../pages/CreateAdmin";
import LinkShare from "../pages/Linkshare";
import MainDashboard from "../pageContent/mainDashboard";
import SearchedUser from "../pages/People/SearchedUser";
import UserKyc from "../pages/Kyc/userKyc";
import People from "../pages/People";
import Ticket from "../cardPages/Ticket";
import AccountApproval from "../cardPages/AccountApproval";
import About from "../pages/About";
import UserTicket from "../../dashboard/cardPages/Ticket/userTicket";
import Training from "../pages/Training";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import Report from "../pages/Report";
import Notification from "../pages/Notification";
import Slider from "../pages/Slider";
import RoundTag from "../pages/RoundTag";
import RejectedList from "../pages/RejectedList";

function RouterComp() {
  return (
    <div>
      <Routes>
        <Route path="/contain" element={<MainDashboard />}></Route>
        <Route path="/contact" element={<CreateAdmin />}></Route>
        <Route path="/kyc" element={<Kyc />}></Route>
        <Route path="/adminOptions" element={<CreateAdmin />}></Route>
        <Route path="/linkShare" element={<LinkShare />}></Route>
        <Route path="/SearchedUser" element={<SearchedUser />}></Route>
        <Route path="/UserKyc" element={<UserKyc />}></Route>
        <Route path="/people" element={<People />}></Route>
        <Route path="/paymentRequest" element={<AccountApproval />}></Route>
        <Route path="/ticket" element={<Ticket />}></Route>
        <Route path="/accountApproval" element={<AccountApproval />}></Route>
        <Route path="/about" element={<About />}></Route>
        <Route path="/userTicket" element={<UserTicket />}></Route>
        <Route path="/slider" element={<Slider />}></Route>
        <Route path="/report" element={<Report />}></Route>
        <Route path="/training" element={<Training />}></Route>
        <Route path="/privacyPolicy" element={<PrivacyPolicy />}></Route>
        <Route path="/notification" element={<Notification />}></Route>
        <Route path="/roundTag" element={<RoundTag />}></Route>
        {/* <Route path="/rejectedList" element={<RejectedList />}></Route> */}
        <Route
          path="*"
          element={<div> Not Found or You do not have permission.</div>}
        />
      </Routes>
    </div>
  );
}

export default RouterComp;
