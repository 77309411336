import {
  Typography,
  Card,
  Row,
  Col,
  Button,
  Table,
  Space,
  Modal,
  Tabs,
  Input,
  DatePicker,
  Popover,
  Spin,
  Image,
  Popconfirm,
} from "antd";
import "../../../../css/dashboard.css";
import { useEffect, useState } from "react";
import {
  CheckCircleTwoTone,
  MessageTwoTone,
  DollarTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import {
  approveIncompleteList,
  setPaymentRequest,
  searchUsers,
} from "../../../../store/actions/users";
import { useNavigate } from "react-router-dom";

function SearchedUser() {
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const usersSearchList = useSelector((state) => state.admin.searchUser);
  const loading = useSelector((state) => state.admin.loading);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const profileImg =  usersSearchList.user.photo

  const handleOk = () => {
    setConfirmationPopup(false);
  };
  const handleCancel = () => {
    setConfirmationPopup(false);
  };

  return (
    <>
      {loading ? (
        <Row>
          <Col span={24}>
            <div style={{ display: "grid", height: "30vh" }}>
              {" "}
              <Spin size="large" style={{ alignSelf: "self-end" }} />{" "}
            </div>
          </Col>
        </Row>
      ) : (
        <Row
          style={{ paddingLeft: "1%", paddingTop: "1%", paddingRight: "1%" }}
        >
          <Col span={24}>
            <Typography.Title level={4}>Agent Info :</Typography.Title>
          </Col>
          <Col
            span={24}
            style={{
              border: "1px solid rgba(0,0,0,0.15)",
              borderWidth: "thick",
              marginBottom: "10px",
              borderRadius: "20px",
            }}
          >
            {/* <Card className="CardFullLength" style={{ marginBottom: "10px" }}> */}
            <Card
              style={{
                backgroundColor: "rgba(237, 237, 237, 0.8)",
                marginTop: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <Col span={4}>
                  <div
                    style={{
                      border: "1px solid rgba(0,0,0,0.15)",
                      borderWidth: "thick",
                      display: "inline-block",
                      justifyContent: "space-between",
                    }}
                  >
                    {usersSearchList.user.photo === "" ? (
                      <img
                        src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=1`}
                        width="100px"
                      />
                    ) : (
                      <Image width={100} src={usersSearchList.user.photo} />
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <UserDetails />
                </Col>
                <Col span={4}>
                  <div style={{ float: "right" }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      {/* <Badge
                        dot={show}
                        color="green"
                        style={{ paddingLeft: "2px", right: "auto" }}
                      > */}
                      <Button
                        type="primary"
                        size="large"
                        danger
                        style={{ width: "200%" }}
                        onClick={() => setConfirmationPopup(true)}
                      >
                        PAY
                      </Button>
                      {/* </Badge> */}
                      <Button
                        type="primary"
                        size="large"
                        style={{ width: "200%" }}
                        onClick={() => {
                          dispatch(searchUsers(usersSearchList.user.id));
                          navigate("/UserKyc");
                        }}
                      >
                        KYC
                      </Button>
                    </Space>
                  </div>
                </Col>
              </div>
              <Modal
                title="Confirmation"
                open={confirmationPopup}
                onOk={handleOk}
                onCancel={handleCancel}
              >
                <div>
                  <Col>
                    <PaymentRequestForUser />
                  </Col>
                </div>
              </Modal>
            </Card>
            <div style={{ display: "flex" }}>
              <Col span={12} style={{ paddingTop: "2%" }}>
                <PendingList />
              </Col>
              <Col span={12} style={{ paddingTop: "2%" }}>
                <CompleteList />
              </Col>
            </div>
            {/* </Card> */}
          </Col>
        </Row>
      )}
    </>
  );
}

function PendingList() {
  const [pendingData, setPendingData] = useState([]);
  const [modalDataPending, setModalDataPending] = useState({});
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
  const loadings = useSelector((state) => state.admin.loading);
  const completeIncomplete = useSelector(
    (state) => state.admin.completeIncompleteList
  );
  const usersSearchList = useSelector((state) => state.admin.searchUser);
  const userDetails = usersSearchList.user;
  const userId = usersSearchList?.user.id;

  const [pendinglistSubmit, setPendinglistSubmit] = useState();
  const dispatch = useDispatch();
  let pendingdata = [];

  usersSearchList?.task_tickets.map((list) => {
    if (list.tt_status === "SUBMITED") {
      const pendingDataobj = {
        id: list.task_id,
        linkId: list.id,
        userName: userDetails.name,
        comment: list.user_comment,
        amount: list.amount,
        date: list.modified_at,
        //cMobile: list.mdn,
        //cName: list.name,
        title: list.title_en,
        //phoneNumber: lists.mdn,
        //  Address: lists.Address,
        link: list.url,
        kycStatus: userDetails.kyc_status,
        userPage: true,
        userId: userId,
        customer_name: list.customer_name,
        customer_mobile: list.customer_mobile,
        url: list.url,
      };
      pendingdata.push(pendingDataobj);
    }
  });

  const handleCancel = () => {
    pendinglistSubmit.reject = false;
    pendinglistSubmit.rejectComment = "";
    setIsModalOpen(false);
  };
  const handleOk = () => {
    pendinglistSubmit.reject = false;
    pendinglistSubmit.rejectComment = "";
    dispatch(approveIncompleteList(pendinglistSubmit));
    setIsModalOpen(false);
  };
  const showModal = (record) => {
    setPendinglistSubmit(record);
    setIsModalOpen(true);
  };

  const showDetailModel = (record) => {
    setIsModalDetailOpen(true);
    setModalDataPending(record);
  };
  const handleMoreCancel = () => {
    setIsModalDetailOpen(false);
  };
  const handleMoreOk = () => {
    setIsModalDetailOpen(false);
  };
  const handleReject = () => {
    pendinglistSubmit.reject = true;
    dispatch(approveIncompleteList(pendinglistSubmit));
    setIsModalOpen(false);
  };

  return (
    <div style={{ alignItems: "normal" }}>
      <Table
        // style={{ width: "600px", height: "4px" }}
        style={{ paddingLeft: "20px" }}
        columns={[
          {
            title: "Incomplete Request",
            dataIndex: "title",
            render: (text, record) => (
              <span onClick={() => showDetailModel(record)}>
                User: {record.userName} <br />
                Title: {record.title}
                <br />
                Date: {record.date}
                <br />
                {/* Work: {record.comment} <br /> */}
                Kyc Status:{" "}
                {record.kycStatus !== "ACCEPTED" ? (
                  <span style={{ color: "red" }}> Not Complete</span>
                ) : (
                  <span style={{ color: "green" }}>Complete</span>
                )}
              </span>
            ),
          },
          {
            title: <center> Approve </center>,
            render: (text, record) => (
              <center>
                <Button onClick={() => showModal(record)} type="primary">
                  Approve
                </Button>
              </center>
            ),
          },
        ]}
        loading={loadings}
        dataSource={pendingdata}
        pagination={{ defaultPageSize: 20 }}
      ></Table>
      <Modal
        title="Confirm Approval"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Popconfirm
            title="Reason for Reject"
            okButtonProps={{ disabled: false }}
            description={
              <>
                <Input
                  placeholder="Eg: Wrog link shared."
                  style={{ width: "300px" }}
                  // value={pendinglistSubmit.rejectComment}
                  onChange={(e) => {
                    pendinglistSubmit.rejectComment = e.target.value;
                  }}
                ></Input>
              </>
            }
            onConfirm={handleReject}
            onCancel={handleCancel}
            okText="Yes"
            cancelText="No"
            icon={
              <WarningTwoTone
                twoToneColor="red"
                style={{ display: "inline-flex" }}
              />
            }
            style={{ width: "100px" }}
            //  onOpenChange={(e)=>console.log('value',e.target.value)}
          >
            <Button
              type="primary"
              danger
              //  onClick={handleReject}
              style={{ float: "left" }}
            >
              Reject
            </Button>
          </Popconfirm>,
          <Button type="primary" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <Space direction="vertical" style={{ paddingBottom: "7px" }}>
          <hey>Are you sure you want to approve this request ?</hey>
        </Space>
      </Modal>
      <Modal
        title="More details"
        open={isModalDetailOpen}
        onOk={handleMoreOk}
        onCancel={handleMoreCancel}
      >
        <Space direction="vertical">
          <Typography.Text>
            <b> Customer Name:</b> {modalDataPending.customer_name}
          </Typography.Text>
          <Typography.Text>
            <b>Customer Number:</b> {modalDataPending.customer_mobile}
          </Typography.Text>
          <Typography.Text>
            <b>Amount:</b> {modalDataPending.amount}
          </Typography.Text>
          <Typography.Text>
            <b>URL:</b> {modalDataPending.url}
          </Typography.Text>
          <Typography.Text>
            <b>Comment:</b> {modalDataPending.comment}
          </Typography.Text>
        </Space>
      </Modal>
    </div>
  );
}

function CompleteList() {
  const loadings = useSelector((state) => state.admin.loading);
  const completeIncomplete = useSelector(
    (state) => state.admin.completeIncompleteList
  );
  const usersSearchList = useSelector((state) => state.admin.searchUser);
  const userDetails = usersSearchList.user;
  let completedData = [];
  let data = [];

  usersSearchList?.task_tickets.map((list) => {
    if (list.tt_status === "COMPLETED") {
      const completedDataObj = {
        id: list.id,
        linkId: list.task_id,
        userName: userDetails.name,
        comment: list.user_comment,
        amount: list.amount,
        date: list.modified_at,
        //cMobile: list.mdn,
        //cName: list.name,
        title: list.title_en,
        //phoneNumber: lists.mdn,
        //  Address: lists.Address,
        link: list.url,
        kycStatus: userDetails.kyc_status,
      };
      completedData.push(completedDataObj);
    }
  });

  return (
    <Table
      // style={{ paddingLeft: "20px", width: "600px", height: "40px" }}
      style={{
        paddingLeft: "20px",
        paddingRight: "20px",
        paddingBottom: "10px",
      }}
      columns={[
        {
          title: "Completed Request",
          dataIndex: "title",
          render: (text, record) => (
            <span>
              User: {record.userName} <br />
              Title: {record.title} <br />
              Date: {record.date}
              <br />
              {/* Work: {record.comment} <br /> */}
              Kyc Status:{" "}
              {record.kycStatus === 0 ? (
                <span style={{ color: "red" }}> Not Complete</span>
              ) : (
                <span style={{ color: "green" }}>Complete</span>
              )}
            </span>
          ),
        },
        {
          title: <center> Approved</center>,
          render: (text) => (
            <center>
              {" "}
              <Button type="dashed">Completed</Button>
            </center>
          ),
        },
      ]}
      loading={loadings}
      dataSource={completedData}
      pagination={{ defaultPageSize: 20 }}
    ></Table>
  );
}

function PaymentRequestForUser() {
  const dispatch = useDispatch();
  const loading = useSelector((state) => state.admin.loading);
  const usersSearchList = useSelector((state) => state.admin.searchUser);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalData, setModalData] = useState({
    amount: "",
    tid: "",
    date: "",
    other: "",
    pid: "",
    rjid: "",
    deductible_amount: "",
  });
  const [amount, setAmount] = useState(0);
  const completeIncomplete = useSelector(
    (state) => state.admin.completeIncompleteList
  );
  let pendingdata = [];

  const showModal = (record) => {
    let data = { pid: record.pid };
    setModalData((list) => ({
      ...list,
      ...data,
    }));
    setAmount(record.amount);
    setIsModalOpen(true);
  };

  const handleOk = () => {
    dispatch(setPaymentRequest(modalData));
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onOk = (value) => {};

  const onChange = (value, dateString) => {
    let data = { date: dateString };
    setModalData((list) => ({
      ...list,
      ...data,
    }));
  };

  usersSearchList?.payment_tickets?.map((list) => {
    if (list.status === "PENDING") {
      let pendingDataobj = {
        pid: list.id,
        amount: list.amount,
        comment: list.comment,
        kyc_status: list.kyc_status,
        date: list.created_at,
      };
      pendingdata.push(pendingDataobj);
    }
  });

  return (
    <div style={{ alignItems: "normal", paddingRight: "4%" }}>
      <Table
        columns={[
          {
            title: "Payment Request",
            dataIndex: "title",
            render: (text, record) => (
              <span>
                <Popover content={record.comment} title="Comment">
                  Comment: Hover to see
                </Popover>{" "}
                <br />
                Amount: {record.amount} <br />
                Date: {record.date}
              </span>
            ),
          },
          {
            title: <center>PAY</center>,
            render: (text, record) => (
              <center>
                {" "}
                <Button type="primary" onClick={() => showModal(record)}>
                  PAY
                </Button>
              </center>
            ),
          },
        ]}
        loading={loading}
        dataSource={pendingdata}
        pagination={{ defaultPageSize: 3 }}
      ></Table>
      <Modal
        title="Payment Details"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okButtonProps={{
          disabled:
            modalData.date === "" ||
            modalData.other === "" ||
            modalData.tid === "",
        }}
      >
        <Space direction="vertical">
          <Typography.Text>
            User requested for <span style={{ color: "red" }}>{amount}rs</span>
          </Typography.Text>
          <Input
            size="large"
            placeholder="Transaction Id"
            prefix={<CheckCircleTwoTone />}
            onChange={(e) => {
              let data = { tid: e.target.value };
              setModalData((list) => ({
                ...list,
                ...data,
              }));
            }}
          />
          <Input
            type="number"
            size="large"
            placeholder="TDS"
            value={modalData.deductible_amount}
            prefix={<DollarTwoTone />}
            onChange={(e) => {
              let data = { deductible_amount: e.target.value };
              setModalData((list) => ({
                ...list,
                ...data,
              }));
            }}
          />
          <DatePicker size="large" showTime onChange={onChange} onOk={onOk} />
          <Input
            size="large"
            placeholder="Other Details"
            prefix={<MessageTwoTone />}
            onChange={(e) => {
              let data = { other: e.target.value };
              setModalData((list) => ({
                ...list,
                ...data,
              }));
            }}
          />
        </Space>
      </Modal>
    </div>
  );
}

export function UserDetails() {
  const [tabChange, setTabChange] = useState(1);
  const usersSearchList = useSelector((state) => state.admin.searchUser);
  const loading = useSelector((state) => state.admin.loading);
  function changeTab(activeKey) {
    setTabChange(activeKey);
  }

  return (
    <>
      <div>
        {loading ? (
          <Row>
            <Col span={24}>
              <div style={{ display: "grid", height: "30vh" }}>
                {" "}
                <Spin size="large" style={{ alignSelf: "self-end" }} />{" "}
              </div>
            </Col>
          </Row>
        ) : (
          <Tabs
            defaultActiveKey="1"
            activeKey={tabChange}
            onChange={changeTab}
            tabPosition="left"
          >
            <div tab="Personal Details" key="1">
              <span>
                <table
                  style={{
                    width: "max-content",
                    borderCollapse: "separate",
                    borderSpacing: "0.5em",
                  }}
                >
                  <tr>
                    <td>
                      <Typography.Text>
                        <u> UserName</u> : {usersSearchList.user.name}{" "}
                      </Typography.Text>
                    </td>
                    <td>
                      <Typography.Text>
                        <u>Email id</u> : {usersSearchList.user.email}
                      </Typography.Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography.Text>
                        <u>Mobile no.</u> : {usersSearchList.user.mobile}
                      </Typography.Text>
                    </td>
                    <td>
                      <Typography.Text>
                        <u>Address</u> : <br />
                        {usersSearchList.user.address}
                      </Typography.Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography.Text>
                        <u>KYC status</u> :
                        {usersSearchList.user.kyc_status === "PENDING" ? (
                          <span style={{ color: "red" }}> Not Completed</span>
                        ) : (
                          <span style={{ color: "green" }}> Complete</span>
                        )}
                      </Typography.Text>
                    </td>
                    <td>
                      <Typography.Text>
                        <u>Cunstomer ID</u> : {usersSearchList.user.id}
                      </Typography.Text>
                    </td>
                  </tr>
                </table>
              </span>
            </div>
            <div
              tab={
                <span>
                  Account & <br /> Other Details
                </span>
              }
              key="2"
            >
              <span>
                <table
                  style={{
                    width: "max-content",
                    borderCollapse: "separate",
                    borderSpacing: "0.5em",
                  }}
                >
                  <tr>
                    <td>
                      <Typography.Text>
                        <u> Bank Name</u> :{" "}
                        {usersSearchList.user.bank_details.bank_name
                          ? usersSearchList.user.bank_details.bank_name
                          : ""}
                      </Typography.Text>
                    </td>

                    <td>
                      <Typography.Text>
                        <u>IFC code</u> :{" "}
                        {usersSearchList.user.bank_details.ifsc_code
                          ? usersSearchList.user.bank_details.ifsc_code
                          : ""}
                      </Typography.Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography.Text>
                        Account Number :<br />
                        {usersSearchList.user.bank_details.account_number
                          ? usersSearchList.user.bank_details.account_number
                          : ""}
                      </Typography.Text>
                    </td>
                    <td>
                      <Typography.Text>
                        Balance Amount : {usersSearchList.user.wallet_balance}
                      </Typography.Text>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Typography.Text>
                        <u>Registration IP</u> : {usersSearchList.user.IP}
                      </Typography.Text>
                    </td>
                    <td>
                      <Typography.Text>
                        <u>State</u> :{" "}
                        {`${usersSearchList.user.city},${usersSearchList.user.state},${usersSearchList.user.country}`}
                      </Typography.Text>
                    </td>
                  </tr>
                </table>
              </span>
            </div>
          </Tabs>
        )}
      </div>
    </>
  );
}

export default SearchedUser;
