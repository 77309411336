import { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  Button,
  Timeline,
  Modal,
  Typography,
  Input,
  Checkbox,
  Spin,
} from "antd";
import { UserDetails } from "../../pages/People/SearchedUser";
import { useDispatch, useSelector } from "react-redux";
import { setReply } from "../../../../store/actions/users";

function UserTicket() {
  const [openModel, setOpenModel] = useState(false);
  const { TextArea } = Input;
  const [replyData, setReplyData] = useState({
    reply: "",
    type: false,
  });
  const onlyTicket = useSelector((state) => state.admin.chatDetail.chat_ticket);
  const onlyChat = useSelector((state) => state.admin.chatDetail.chat_messages);
  const loading = useSelector((state) => state.admin.loading);
  const [chatData, setChatData] = useState({});
  const [chatMessage, setChatMessage] = useState([]);
  const [responseBy, setResponseBy] = useState('USER')
  const dispatch = useDispatch();

  useEffect(() => {
    if (!loading) {
      setChatData({
        ticket_id: onlyTicket?.id,
        title: onlyTicket?.title,
        description: onlyTicket?.description,
        status: onlyTicket?.status,
        created_at: onlyTicket?.created_at,
      });
      //  console.log("length", onlyChat.length);
      setChatMessage([]);
      setChatMessage((oldData) => [
        ...oldData,
        {
          id: onlyTicket.id,
          label: `(USER) ${onlyTicket.created_at}`,
          children: onlyTicket.description,
        },
      ]);
      if (onlyChat.length > 0) {
        onlyChat.map((list, index) => {
          setChatMessage((oldData) => [
            ...oldData,
            {
              id: list.id,
              label: `(${list.posted_by}) ${list.created_at}`,
              children: list.comment,
            },
          ]);
          setResponseBy(list.posted_by === 'ADMIN' ? 'USER' :'ADMIN')
        });
      }
    }
  }, [onlyTicket, onlyChat, loading]);

  function handleOk() {
    let data = {
      ticket_id: chatData.ticket_id,
      status: replyData.type ? "CLOSED" : "OPEN",
      comment: replyData.reply,
    };
    dispatch(setReply(data));
    setReplyData({
      reply: "",
      type: false,
    });
    setOpenModel(false);
  }

  function handleCancel() {
    setReplyData({
      reply: "",
      type: false,
    });
    setOpenModel(false);
  }

  function handleModelPopup() {
    setOpenModel(true);
  }

  const onChange = (e) => {
    let data = { type: e.target.checked };
    setReplyData((list) => ({
      ...list,
      ...data,
    }));
  };

  return (
    <>
      {loading ? (
        <Row>
          <Col span={24}>
            <div style={{ display: "grid", height: "30vh" }}>
              {" "}
              <Spin size="large" style={{ alignSelf: "self-end" }} />{" "}
            </div>
          </Col>
        </Row>
      ) : (
        <Row
          style={{
            paddingRight: "15px",
            paddingTop: "10px",
            paddingBottom: "10px",
          }}
        >
          <Col span={24}>
            <Card
              style={{
                backgroundColor: "rgba(237, 237, 237, 0.8)",
                marginTop: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <Col span={4}>
                  <div
                    style={{
                      border: "1px solid rgba(0,0,0,0.15)",
                      borderWidth: "thick",
                      display: "inline-block",
                      justifyContent: "space-between",
                    }}
                  >
                    <img
                      src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=1`}
                      width="100px"
                    />
                  </div>
                </Col>
                <Col span={12}>
                  <UserDetails />
                </Col>
              </div>
            </Card>
            <Card
              style={{
                backgroundColor: "rgba(237, 237, 237, 0.8)",
                marginTop: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <span
                style={{ display: "block", overflow: "auto", height: "60vh" }}
              >
                <Typography.Text>
                  <b>{`${chatData.title} (TICKET ID: ${chatData.ticket_id})`}</b>
                  <b><span style={{float:'right'}}>Reply pending by: <span style={{color:'green'}}> {responseBy}</span></span> </b><br/>
                </Typography.Text>
                <Timeline
                  style={{ paddingTop: "2px", marginTop: "20px" }}
                  mode="left"
                  items={chatMessage}
                />
              </span>
              <Button
                type="primary"
                onClick={handleModelPopup}
                style={{ width: "-webkit-fill-available" }}
              >
                Reply
              </Button>
            </Card>
          </Col>
          <Modal
            title="Reply"
            open={openModel}
            onOk={handleOk}
            okButtonProps={{ disabled: replyData.reply === "" }}
            onCancel={handleCancel}
          >
            <Typography.Text></Typography.Text>

            <TextArea
              rows={4}
              value={replyData.reply}
              onChange={(e) => {
                let data = { reply: e.target.value };
                setReplyData((list) => ({
                  ...list,
                  ...data,
                }));
              }}
            ></TextArea>
            <br></br>
            <Checkbox onChange={onChange} value={replyData.type}>
              Complete{" "}
              <Typography.Text style={{ color: "red" }}>
                {" "}
                {
                  "(Once ticket is marked complete it can not be open again)"
                }{" "}
              </Typography.Text>
            </Checkbox>
          </Modal>
        </Row>
      )}
    </>
  );
}

export default UserTicket;
