import { Button, Typography } from "antd";
import {
  Space,
  Input,
  Radio,
  Row,
  Col,
  Card,
  Modal,
  List,
  AutoComplete,
  Spin,
  Image,
  Tag,
} from "antd";
import { useState, useRef, useMemo, useEffect } from "react";
import {
  FireTwoTone,
  EditTwoTone,
  CloseSquareFilled,
  LoadingOutlined,
} from "@ant-design/icons";
import JoditEditor from "jodit-react";
import "../../../../css/dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import {
  setDescriptionEnglish,
  setDescriptionHindi,
  setlinkData,
  updateDeactivateLink,
  updateLink,
  targetLink,
  setSearchBox,
  resetSelectedLink,
  updateLinkList,
  resetDescription_en,
  resetDescription_hi,
} from "../../../../store/actions/users";

function LinkShare() {
  const [value4, setValue4] = useState("Send All");
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [changeOption, setChangeOption] = useState("Send Link");
  const [content, setContent] = useState("");
  const listData = useSelector((state) => state.admin.linkData);
  const loading = useSelector((state) => state.admin.loading);
  const [freshLinkData, setFreshLinkData] = useState([]);
  const [data, setData] = useState("All");
  const [searchData, setSearchData] = useState({});
  const descE = useSelector((state) => state.admin.descriptionE);
  const descH = useSelector((state) => state.admin.descriptionH);
  const usersSearchList = useSelector((state) => state.admin.searchResult);
  const getDataStatus = useSelector((state) => state.admin.dataSaved);
  const [confirmSearch, setConfirmSearch] = useState();
  const [tagArr, setTagArr] = useState([]);
  let [arr, setArr] = useState([]);
  let options1 = [];
  let sendToId = "";
  usersSearchList?.map((list) => {
    options1.push({ value: `${list.name}  =>  ${list.mobile}`, id: list.id });
  });

  const [linkData, setLinkData] = useState({
    title_en: "",
    title_hi: "",
    task_url: "",
    task_amount: 0,
    description_en: "",
    description_hi: "",
    send_to: "",
    catagory: "Bank Account",
    link_send_to_all: true,
    image: {},
    isactive: 1,
    sendToId: "",
  });
  let optionss = [];
  listData?.map((list) => {
    optionss.push({ value: `${list.link_title_en}`, link: list.link_id });
  });

  useEffect(() => {
    if (getDataStatus) {
      setLinkData({
        title_en: "",
        title_hi: "",
        task_url: "",
        task_amount: 0,
        description_en: "",
        description_hi: "",
        send_to: "",
        catagory: "Bank Account",
        link_send_to_all: true,
        image: {},
        isactive: 1,
        sendToId: "",
      });
      dispatch(resetDescription_en(""));
      dispatch(resetDescription_hi(""));
      handleFileReset("");
      setTagArr([]);
      setValue4("Send All");
      setArr([]);
    }
  }, [getDataStatus]);

  useEffect(() => {
    if (value4 === "Send All") {
      let data = { link_send_to_all: true };
      setLinkData((list) => ({
        ...list,
        ...data,
      }));
      setTagArr([]);
    } else {
      let data = { link_send_to_all: false };
      setLinkData((list) => ({
        ...list,
        ...data,
      }));
    }
  }, [value4]);

  useEffect(() => {
    dispatch(setDescriptionEnglish(content));
    dispatch(setDescriptionHindi(content));
  }, []);

  useEffect(() => {
    if (selectedImage !== null || selectedImage !== "") {
      let data = { image: selectedImage };
      setLinkData((list) => ({
        ...list,
        ...data,
      }));
    } else {
    }
  }, [selectedImage]);

  useEffect(() => {
    setFreshLinkData([]);
    let activeCat = 0;
    if (data === "Onboard User") {
      activeCat = 1;
    } else if (data === "Bank Account") {
      activeCat = 2;
    } else if (data === "Instant Loan") {
      activeCat = 3;
    } else if (data === "Credit Card") {
      activeCat = 4;
    } else if (data === "Demat Account") {
      activeCat = 5;
    } else if (data === "Onboard Merchant") {
      activeCat = 6;
    } else {
      activeCat = 7;
    }
    let a = [];
    listData?.map((link) => {
      if (link.catalog_id === activeCat) {
        //  console.log('activeCat',link)
        a.push(link);
      }
    });
    setFreshLinkData(a);
  }, [data]);

  useEffect(() => {
    if (searchData === "") {
    }
  }, [searchData]);

  const optionsWithDisabled = [
    { label: "Send All", value: "Send All" },
    { label: "Specific", value: "Specific" },
  ];

  const links = {};

  const onChange4 = ({ target: { value } }) => {
    setValue4(value);
  };
  const options = [
    { label: "Send Link", value: "Send Link" },
    { label: "Link List", value: "Link List" },
  ];

  const onChangeOptions = ({ target: { value } }) => {
    setChangeOption(value);
    dispatch(updateLinkList());
  };

  const items = [
    "All",
    // "Onboard User",
    "Bank Account",
    "Instant Loan",
    "Credit Card",
    "Demat Account",
    "Onboard Merchant",
    "Other",
  ];

  const items1 = [
    // "Onboard User",
    "Bank Account",
    "Instant Loan",
    "Credit Card",
    "Demat Account",
    "Onboard Merchant",
    "Other",
  ];

  function handleModelPopup() {
    setOpenModel(true);
  }

  function handleOk() {
    // if (linkData.catagory === "Onboard User") {
    //   linkData.catagory = 1;
    // } else if (linkData.catagory === "Bank Account") {
    //   linkData.catagory = 2;
    // } else if (linkData.catagory === "Instant Loan") {
    //   linkData.catagory = 3;
    // } else if (linkData.catagory === "Credit Card") {
    //   linkData.catagory = 4;
    // } else if (linkData.catagory === "Demat Account") {
    //   linkData.catagory = 5;
    // } else if (linkData.catagory === "Onboard Merchant") {
    //   linkData.catagory = 6;
    // } else {
    //   linkData.catagory = 7;
    // }

    let str = "";
    tagArr.map((list, index) => {
      if (arr.length > 0) {
        arr.map((item) => {
          if (index !== item) {
            str = str + list.key;
            str = str + ",";
          }
        });
      } else {
        str = str + list.key;
        str = str + ",";
      }
    });
    sendToId = str.slice(0, -1);
    linkData.sendToId = sendToId;
    dispatch(setlinkData(linkData));
    setOpenModel(false);
  }

  function handleCancel() {
    setOpenModel(false);
  }

  const filterBySize = (file) => {
    //filter out images larger than 5MB
    return file.size <= 5242880;
  };
  function handleFileReset(e) {
    setSelectedImage(null);
    document.getElementById("upload").value = "";
  }

  function handleFile(e) {
    setSelectedImage(e.target.files[0]);
    //e.taget.files[0] = null
  }

  function handleSearch(data, link) {
    setSearchData(link);
  }

  function handleClose(index) {
    // arr.push(index)
    setArr((oldArray) => [...oldArray, index]);

    // tagArr.map((list) => {
    //   arr.push(list);
    // });
    // arr.splice(index, 1);
    //  console.log('arr',arr)
    //  setTagArr(arr);
  }
  //  console.log('arr 1 ',arr)
  // console.log("tagArr 1 ", tagArr);

  function handleTages() {
    return (
      <>
        <Space size={[0, "small"]} wrap>
          {tagArr.map((list, index) => {
            return (
              <Tag
                // bordered={false}
                closable
                color="geekblue"
                style={{ marginBottom: "5px" }}
                onClose={() => handleClose(index)}
              >
                {list.value}
              </Tag>
            );
          })}
        </Space>
      </>
    );
  }

  function handleSearchEmail(data1, id) {
    // let data = { send_to: id.id };
    // setLinkData((list) => ({
    //   ...list,
    //   ...data,
    // }));
    // let data = "";
    // data = data + "," + id.id;
    // console.log("id", id.value);
    // console.log("id", id.id);
    if (id.value) {
      // arr.push({key:id.id, value:id.value})
      setTagArr((oldArray) => [...oldArray, { key: id.id, value: id.value }]);
    }
    setConfirmSearch(data);
  }

  function handleClear() {
    setSearchData({});
    setFreshLinkData([]);
  }

  function handleSearchbox() {
    setFreshLinkData([]);
    listData?.map((link) => {
      if (link.link_id === searchData.link) {
        setFreshLinkData((oldArray) => [...oldArray, link]);
      }
    });
  }

  return (
    <Row
      style={{
        //  paddingLeft: "1%",
        paddingTop: "1%",
        paddingRight: "1%",
        paddingBottom: "20px",
      }}
    >
      <Col span="24">
        <Card style={{ backgroundColor: "rgba(237, 237, 237, 0.8)" }}>
          <Space size="large" direction="vertical" style={{ width: "100%" }}>
            <div style={{ display: "flex" }}>
              <Col span={12}>
                <Typography.Title level={4}>
                  {changeOption === "Send Link"
                    ? "Link Share"
                    : "Deactivate Link"}
                </Typography.Title>
              </Col>
              <Col span={12}>
                <Radio.Group
                  options={options}
                  onChange={onChangeOptions}
                  value={changeOption}
                  optionType="button"
                  buttonStyle="outline"
                  style={{ float: "right" }}
                />
              </Col>
            </div>
            {changeOption === "Send Link" ? (
              <>
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    size="large"
                    value={linkData.title_en}
                    onChange={(e) => {
                      let data = { title_en: e.target.value };
                      setLinkData((list) => ({
                        ...list,
                        ...data,
                      }));
                    }}
                    placeholder="Link Title English"
                  />
                </Space.Compact>
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    size="large"
                    value={linkData.title_hi}
                    onChange={(e) => {
                      let data = { title_hi: e.target.value };
                      setLinkData((list) => ({
                        ...list,
                        ...data,
                      }));
                    }}
                    placeholder="Link Title Hindi"
                  />
                </Space.Compact>
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    size="large"
                    value={linkData.task_url}
                    onChange={(e) => {
                      let data = { task_url: e.target.value };
                      setLinkData((list) => ({
                        ...list,
                        ...data,
                      }));
                    }}
                    placeholder="Link URL"
                  />
                </Space.Compact>
                <Space.Compact style={{ width: "100%" }}>
                  <Input
                    type="number"
                    size="large"
                    value={linkData.task_amount}
                    onChange={(e) => {
                      if (e.target.value >= 0) {
                        let data = { task_amount: e.target.value };
                        setLinkData((list) => ({
                          ...list,
                          ...data,
                        }));
                      }
                    }}
                    placeholder="Amount"
                  />
                </Space.Compact>
                <Space.Compact style={{ width: "100%", display: "block" }}>
                  <JorditEditor1
                    style={{
                      width: "-webkit-fill-available",
                      display: "block",
                    }}
                  />
                </Space.Compact>
                <Space.Compact style={{ width: "100%", display: "block" }}>
                  <JorditEditor2 />
                </Space.Compact>

                {value4 === "Specific" ? (
                  <Space.Compact direction="vertical" style={{ width: "100%" }}>
                    <AutoComplete
                      style={{ width: "100%" }}
                      onSelect={handleSearchEmail}
                      placeholder="input here"
                      options={options1}
                      onSearch={(value) => dispatch(setSearchBox(value))}
                      size="large"
                      filterOption={(inputValue, option) =>
                        option.value
                          .toUpperCase()
                          .indexOf(inputValue.toUpperCase()) !== -1
                      }
                      allowClear={{ clearIcon: <CloseSquareFilled /> }}
                      notFoundContent="No user found -> Please login again"
                    />
                    <br />
                    {handleTages()}
                    {/* <Input
                    size="large"
                    value={confirmSearch}
                    onChange={(e) => {
                     
                    }}
                    placeholder="Conform data"
                  />
                  */}
                    {/* <Input
                      size="large"
                      placeholder="Add user id"
                      onChange={(e) => {
                        let data = { send_to: e.target.value };
                        setLinkData((list) => ({
                          ...list,
                          ...data,
                        }));
                      }}
                    /> */}
                  </Space.Compact>
                ) : (
                  ""
                )}

                <div>
                  <Radio.Group
                    options={optionsWithDisabled}
                    onChange={onChange4}
                    value={value4}
                    optionType="button"
                    buttonStyle="outline"
                  />

                  <select
                    style={{
                      borderRadius: "5px",
                      height: "35px",
                      width: "100px",
                      marginLeft: "30px",
                      border: "0.1",
                    }}
                    onChange={(e) => {
                      let data = { catagory: e.target.value };
                      setLinkData((list) => ({
                        ...list,
                        ...data,
                      }));
                    }}
                  >
                    {items1?.map((item) => (
                      <option id={item} value={item}>
                        {item}
                      </option>
                    ))}
                  </select>
                </div>
                <input
                  id="upload"
                  type="file"
                  name="myImage"
                  accept=".jpeg,.jpg"
                  onChange={(e) => {
                    handleFile(e);
                  }}
                />
                {selectedImage && (
                  <div>
                    <Image
                      style={{ marginTop: "10px" }}
                      alt="not found"
                      width={"150px"}
                      height={"70px"}
                      src={URL.createObjectURL(selectedImage)}
                    />
                    <br />
                    <br />
                    <Button onClick={(e) => handleFileReset(e)} danger>
                      Remove
                    </Button>
                  </div>
                )}
                <Button
                  size="large"
                  onClick={handleModelPopup}
                  type="default"
                  danger
                  disabled={
                    linkData.titleE === "" ||
                    linkData.titleH === "" ||
                    linkData.link == "" ||
                    linkData.url === "" ||
                    descE === "" ||
                    descH === "" ||
                    (changeOption === "Send Link"
                      ? false
                      : linkData.sendTo === "")
                  }
                  style={{ width: "-webkit-fill-available" }}
                >
                  Submit
                </Button>
              </>
            ) : (
              <>
                {/* <Space.Compact style={{ width: "98%", marginTop: "1%" }}>
                  <AutoComplete
                    style={{ width: "100%" }}
                    onSelect={handleSearch}
                    placeholder="input here"
                    size="large"
                    options={optionss}
                    onClear={handleClear}
                    filterOption={(inputValue, option) =>
                      option.value
                        .toUpperCase()
                        .indexOf(inputValue.toUpperCase()) !== -1
                    }
                    allowClear={{ clearIcon: <CloseSquareFilled /> }}
                    notFoundContent="No user found -> Please login again"
                  />
                  <Button type="primary" size="large" onClick={handleSearchbox}>
                    Submit
                  </Button>
                </Space.Compact> */}

                <select
                  style={{
                    borderRadius: "5px",
                    height: "35px",
                    width: "100px",

                    border: "0.1",
                  }}
                  onChange={(e) => {
                    setData(e.target.value);
                  }}
                >
                  {items?.map((item) => (
                    <option>{item}</option>
                  ))}
                </select>

                <ListData
                  listLinkData={
                    data === "All" && !searchData.link
                      ? listData
                      : freshLinkData
                  }
                />
              </>
            )}
          </Space>
        </Card>
        {/* <TextArea rows={4} placeholder="maxLength is 6" maxLength={6} /> */}
        <Modal
          title="Confirmation"
          open={openModel}
          onOk={handleOk}
          onCancel={handleCancel}
        >
          <Typography.Text>
            Are you sure you want to send link ?{" "}
          </Typography.Text>
        </Modal>
      </Col>
    </Row>
  );
}

export function ListData({ listLinkData }) {
  const [value4, setValue4] = useState("Send All");
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const [editPopup, setEditPopup] = useState(false);
  const listData = useSelector((state) => state.admin.linkData);
  const [link, setLink] = useState();
  const [updateDataLink, setUpdateDataLink] = useState("");
  const loading = useSelector((state) => state.admin.loading);
  const [selectedImage, setSelectedImage] = useState(null);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const selectedLink = useSelector((state) => state.admin.selectedLink);
  const [image, setImage] = useState();
  const { TextArea } = Input;
  const [category, setCategory] = useState("");
  const [linkData, setLinkData] = useState({
    title_en: "",
    title_hi: "",
    task_url: "",
    task_amount: 0,
    description_en: "",
    description_hi: "",
    //  send_to: "",
    catagory: "Onboard User",
    link_send_to_all: true,
    // image: {},
    isactive: 1,
    updated_date: "",
    link_id: "",
    thumbnail: "",
  });
  const items = [
    "Onboard User",
    "bank account",
    "Instant Loan",
    "Credit Card",
    "Demat Account",
    "Onboard Merchant",
    "Other",
  ];
  useEffect(() => {
    if (selectedImage !== null || selectedImage !== "") {
      let data = { thumbnail: selectedImage };
      setLinkData((list) => ({
        ...list,
        ...data,
      }));
    } else {
    }
  }, [selectedImage]);
  const dispatch = useDispatch();

  const optionsWithDisabled = [
    { label: "Inactive", value: "Inactive" },
    { label: "Active", value: "Active" },
  ];

  const onChange4 = (item) => {
    //  setValue4(value);
    if (item.catalog_id === 1) {
      item.category = "Onboard User";
    } else if (item.catalog_id === 2) {
      item.category = "Bank Account";
    } else if (item.catalog_id === 3) {
      item.category = "Instant Loan";
    } else if (item.catalog_id === 4) {
      item.category = "Credit Card";
    } else if (item.catalog_id === 5) {
      item.category = "Demat Account";
    } else if (item.catalog_id === 6) {
      item.category = "Onboard Merchant";
    } else {
      item.category = "Other";
    }
    item.thumbnail = setLink(item);
    setConfirmationPopup(true);
  };

  useEffect(() => {
    let data = {
      link_id: selectedLink.id,
      title_en: selectedLink.title_en,
      title_hi: selectedLink.title_hi,
      description_en: selectedLink.description_en,
      description_hi: selectedLink.description_hi,
      catagory: selectedLink.catalog_id,
      url: selectedLink.url,
      amount: selectedLink.amount,
      thumbnail: selectedLink.thumbnail,
    };
    setLinkData((list) => ({
      ...list,
      ...data,
    }));
  }, [loading]);

  const onChangeEdit = (item) => {
    //  setValue4(value);
    // setLink(item);
    dispatch(targetLink(item.id));
    setUpdateDataLink(item);

    setEditPopup(true);
  };

  const handleOk = () => {
    dispatch(updateLink(link));
    setConfirmationPopup(false);
  };

  const handleCancel = () => {
    setConfirmationPopup(false);
  };

  const handleEditOk = () => {
    listData?.map((list) => {
      if (list.id === linkData.link_id) {
        if (list.catagory === "1") {
          linkData.catagory = "Onboard User";
        } else if (list.catagory === "2") {
          linkData.catagory = "Bank Account";
        } else if (list.catagory === "3") {
          linkData.catagory = "Instant Loan";
        } else if (list.catagory === "4") {
          linkData.catagory = "Credit Card";
        } else if (list.catagory === "5") {
          linkData.catagory = "Demat Account";
        } else if (list.catagory === "6") {
          linkData.catagory = "Onboard Merchant";
        } else {
          linkData.catagory = "Other";
        }
        linkData.task_url = list.url;
        linkData.task_amount = list.amount;
        linkData.catagory = category;
      }
    });
    dispatch(updateDeactivateLink(linkData));
    setLinkData({
      title_en: "",
      title_hi: "",
      task_url: "",
      task_amount: 0,
      description_en: "",
      description_hi: "",
      //   send_to: "",
      catagory: "Onboard User",
      link_send_to_all: true,
      // image: {},
      isactive: 1,
      updated_date: "",
      link_id: "",
      thumbnail: "",
    });
    setSelectedImage(null);
    setEditPopup(false);
  };
  const handleEditCancel = () => {
    setLinkData({
      title_en: "",
      title_hi: "",
      task_url: "",
      task_amount: 0,
      description_en: "",
      description_hi: "",
      //  send_to: "",
      catagory: "Onboard User",
      link_send_to_all: true,
      //  image: {},
      isactive: 1,
      updated_date: "",
      link_id: "",
      thumbnail: "",
    });
    setSelectedImage(null);
    setEditPopup(false);
  };

  function handleFileReset(e) {
    setSelectedImage(null);
    document.getElementById("upload").value = "";
  }

  useEffect(() => {
    if (selectedLink.catalog_id === "1") {
      setCategory("Onboard User");
    } else if (selectedLink.catalog_id === "2") {
      setCategory("Bank Account");
    } else if (selectedLink.catalog_id === "3") {
      setCategory("Instant Loan");
    } else if (selectedLink.catalog_id === "4") {
      setCategory("Credit Card");
    } else if (selectedLink.catalog_id === "5") {
      setCategory("Demat Account");
    } else if (selectedLink.catalog_id === "6") {
      setCategory("Onboard Merchant");
    } else {
      setCategory("Other");
    }
  }, [selectedLink]);

  function handleFile(e) {
    setSelectedImage(e.target.files[0]);
    //e.taget.files[0] = null
  }

  return (
    <>
      {loading ? (
        <Row>
          <Col span={24}>
            <div style={{ display: "grid", height: "30vh" }}>
              {" "}
              <Spin size="large" style={{ alignSelf: "self-end" }} />{" "}
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <List
            itemLayout="horizontal"
            dataSource={listLinkData}
            loading={loading}
            pagination={{ defaultPageSize: 20 }}
            key={"link_id"}
            renderItem={(item, index) => (
              <List.Item>
                <List.Item.Meta
                  avatar={<FireTwoTone twoToneColor="red" />}
                  title={
                    <div style={{ display: "flex" }}>
                      {" "}
                      <Typography.Text>{item.title_en} </Typography.Text>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <EditTwoTone
                        size="large"
                        onClick={() => onChangeEdit(item)}
                        style={{ marginTop: "4px" }}
                      />{" "}
                    </div>
                  }
                  description={
                    <>
                      <Typography.Text>{item.url}</Typography.Text>
                    </>
                  }
                />
                {loading ? (
                  <Spin
                    size="large"
                    style={{ alignSelf: "self-end" }}
                    indicator={antIcon}
                  />
                ) : (
                  <Radio.Group
                    options={optionsWithDisabled}
                    onChange={() => onChange4(item)}
                    value={item.active === "1" ? "Active" : "Inactive"}
                    optionType="button"
                    buttonStyle="outline"
                  />
                )}
              </List.Item>
            )}
          />
          <Modal
            title="Confirmation"
            open={confirmationPopup}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Typography>
              {" "}
              Are you sure you want to change link's permission?{" "}
            </Typography>
          </Modal>
          <Modal
            title="Edit Link"
            open={editPopup}
            onOk={handleEditOk}
            onCancel={handleEditCancel}
          >
            <>
              {loading ? (
                <Row>
                  <Col span={24}>
                    <div style={{ display: "grid", height: "30vh" }}>
                      {" "}
                      <Spin
                        size="large"
                        style={{ alignSelf: "self-end" }}
                      />{" "}
                    </div>
                  </Col>
                </Row>
              ) : (
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Space.Compact style={{ width: "100%" }}>
                    <Input
                      size="large"
                      value={linkData.title_en}
                      onChange={(e) => {
                        let data = { title_en: e.target.value };
                        setLinkData((list) => ({
                          ...list,
                          ...data,
                        }));
                      }}
                      placeholder="Link Title English"
                    />
                  </Space.Compact>
                  <Space.Compact style={{ width: "100%" }}>
                    <Input
                      size="large"
                      value={linkData.title_hi}
                      onChange={(e) => {
                        let data = { title_hi: e.target.value };
                        setLinkData((list) => ({
                          ...list,
                          ...data,
                        }));
                      }}
                      placeholder="Link Title Hindi"
                    />
                  </Space.Compact>
                  <Space.Compact style={{ width: "100%" }}>
                    <Input
                      size="large"
                      value={linkData.url}
                      disabled
                      // onChange={(e) => {
                      //   let data = { task_url: e.target.value };
                      //   setLinkData((list) => ({
                      //     ...list,
                      //     ...data,
                      //   }));
                      // }}
                      placeholder="Link URL"
                    />
                  </Space.Compact>
                  <Space.Compact style={{ width: "100%" }}>
                    <Input
                      type="number"
                      size="large"
                      value={linkData.amount}
                      disabled
                      // onChange={(e) => {
                      //   let data = { task_amount: e.target.value };
                      //   setLinkData((list) => ({
                      //     ...list,
                      //     ...data,
                      //   }));
                      // }}
                      placeholder="Amount"
                    />
                  </Space.Compact>
                  <Space.Compact style={{ width: "100%", display: "block" }}>
                    {/* <JorditEditor3
                      value={linkData.description_en}
                      style={{
                        width: "-webkit-fill-available",
                        display: "block",
                      }}
                    /> */}
                    <TextArea
                      rows={4}
                      placeholder="Description in English"
                      value={linkData.description_en}
                      onChange={(e) => {
                        let data = { description_en: e.target.value };
                        setLinkData((list) => ({
                          ...list,
                          ...data,
                        }));
                        dispatch(setDescriptionEnglish(data.description_en));
                      }}
                      style={{
                        width: "-webkit-fill-available",
                        display: "block",
                      }}
                    />
                  </Space.Compact>
                  <Space.Compact style={{ width: "100%", display: "block" }}>
                    {/* <JorditEditor4 value={linkData.description_hi} /> */}
                    <TextArea
                      rows={4}
                      placeholder="Description in Hindi"
                      value={linkData.description_hi}
                      onChange={(e) => {
                        let data = { description_hi: e.target.value };
                        setLinkData((list) => ({
                          ...list,
                          ...data,
                        }));
                        dispatch(setDescriptionHindi(data.description_hi));
                      }}
                      style={{
                        width: "-webkit-fill-available",
                        display: "block",
                      }}
                    />
                  </Space.Compact>
                  <Space.Compact style={{ width: "100%", display: "block" }}>
                    <select
                      value={category}
                      style={{
                        borderRadius: "5px",
                        height: "35px",
                        width: "100px",
                        border: "0.1",
                      }}
                      onChange={(e) => {
                        let data = { catagory: e.target.value };
                        setLinkData((list) => ({
                          ...list,
                          ...data,
                        }));
                        setCategory(data.catagory);
                      }}
                    >
                      {items?.map((item) => (
                        <option id={item} value={item}>
                          {item}
                        </option>
                      ))}
                    </select>
                    &nbsp;&nbsp;
                    <input
                      id="upload"
                      type="file"
                      name="myImage"
                      accept=".jpeg,.jpg"
                      onChange={(e) => {
                        handleFile(e);
                      }}
                    />
                    {linkData.thumbnail !== "" && (
                      <div>
                        <Image
                          style={{ marginTop: "10px" }}
                          alt="not found"
                          width={"150px"}
                          height={"70px"}
                          src={
                            selectedImage
                              ? URL.createObjectURL(selectedImage)
                              : linkData.thumbnail
                          }
                        />
                        <br />
                        <br />
                        {/* <Button onClick={(e) => linkData.thumbnail=''} danger>
                          Remove
                        </Button> */}
                      </div>
                    )}
                  </Space.Compact>
                </Space>
              )}
            </>
          </Modal>
        </>
      )}
    </>
  );
}

function JorditEditor1() {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  const getDataStatus = useSelector((state) => state.admin.dataSaved);

  const configE = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: "Enter details in English" || "Start typings...",
    height: "20vh",
    width: "100%",
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "ul",
      "ol",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "font",
      "fontsize",
    ],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ["brush", "file"],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
  };

  useEffect(() => {
    if (getDataStatus) {
      setContent("");
    }
  }, [getDataStatus]);

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={content}
        config={configE}
        onChange={(content) => {
          setContent(content);
          dispatch(setDescriptionEnglish(content));
        }}
      />
    ),
    [getDataStatus]
  );
}

function JorditEditor2() {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();
  const getDataStatus = useSelector((state) => state.admin.dataSaved);

  useEffect(() => {
    if (getDataStatus) {
      setContent("");
    }
  }, [getDataStatus]);

  const configE = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: "Enter details in Hindi" || "Start typings...",
    height: "20vh",
    width: "100%",
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "ul",
      "ol",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "font",
      "fontsize",
    ],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ["brush", "file"],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
  };

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={content}
        config={configE}
        onChange={(content) => {
          setContent(content);
          dispatch(setDescriptionHindi(content));
        }}
      />
    ),
    [getDataStatus]
  );
}

function JorditEditor3(value) {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setContent(value.value);
  });

  const configE = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: "Enter details in English" || "Start typings...",
    height: "20vh",
    width: "100%",
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "ul",
      "ol",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "font",
      "fontsize",
    ],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ["brush", "file"],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
  };

  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={content}
        config={configE}
        onChange={(content) => {
          setContent(content);
          dispatch(setDescriptionEnglish(content));
        }}
      />
    ),
    [content]
  );
}

function JorditEditor4(value) {
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    setContent(value.value);
  });

  const configE = {
    readonly: false, // all options from https://xdsoft.net/jodit/docs/,
    placeholder: "Enter details in Hindi" || "Start typings...",
    height: "20vh",
    width: "100%",
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "ul",
      "ol",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "font",
      "fontsize",
    ],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ["brush", "file"],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
  };
  return useMemo(
    () => (
      <JoditEditor
        ref={editor}
        value={content}
        config={configE}
        onChange={(content) => {
          setContent(content);
          dispatch(setDescriptionHindi(content));
        }}
      />
    ),
    [content]
  );
}

export default LinkShare;
