import * as type from "../actions/types";
const initalState = {
  users: "",
  token: "",
  loginStatus: "",
  loading: false,
  preRequiredData: {
    kycStatus: false,
    kycPending: 0,
    accountApproval: 0,
    ticket: 0,
    paymentPending: 0,
  },
  usersData: {},
  allUserData: {},
  searchedUser: [],
  searchUser: {},
  completeIncompleteList: {},
  linkData: {},
  trainingLink: {},
  userTicketData: {},
  onlyTicket: [],
  pushNotification: [],
  minBal: 0,
  descriptionE: "",
  descriptionH: "",
  aboutPolicy: "",
  privacyPolicy: "",
  logout: false,
  forgotEmailSent: false,
  error: null,
  sagaError: false,
  selectedLink: {},
  getAlluserData: {},
  incompleteList: {},
  completeList: {},
  dataSaved: false,
  allPaymentData: [],
  trainingData: [],
  openChat: [],
  testimonials: [],
  pendingPayment: [],
  chatDetail: {},
  paymentComplete: [],
  sliderData: [],
  isInsuficientBalance: false,
  privactData: "",
  about_usData: "",
  passwordError: false,
};
export default function users(state = initalState, action) {
  switch (action.type) {
    case type.GET_USERS_REQUESTED:
      return {
        ...state,
        loading: true,
        users: action.payload,
      };
    case type.GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case type.GET_USERS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.message,
      };
    case type.PRE_REQUIRED_DATA:
      return {
        ...state,
        loading: true,
      };
    case type.PRE_REQUIRED_DATA_Response:
      return {
        ...state,
        preRequiredData: action.payload,
        loading: false,
      };
    case type.UPDATE_USERDATA:
      return {
        ...state,
        usersData: action.usersData,
      };
    case type.SEARCH_USER:
      return {
        ...state,
        loading: true,
      };
    case type.SEARCH_USER_Response:
      return {
        ...state,
        searchedUser: action.data,
        loading: false,
      };
    case type.SEARCHED_USER_RESPONSE:
      return {
        ...state,
        loading: false,
        searchUser: action.data,
      };
    case type.SET_MINIMUM_BALANCE:
      return {
        ...state,
        loading: true,
      };
    case type.SET_MINIMUM_BALANCE_DATA:
      return {
        ...state,
        minBal: action.data,
      };
    case type.SET_DESCRIPTION_ENGLISH:
      return {
        ...state,
        descriptionE: action.payload,
      };
    case type.SET_DESCRIPTION_HINDI:
      return {
        ...state,
        descriptionH: action.payload,
      };
    case type.SET_LINK_SHARE_DATA:
      return {
        ...state,
        loading: false,
      };
    case type.SET_ABOUT_POLICY:
      return {
        ...state,
        aboutPolicy: action.payload,
        loading: true,
      };
    case type.SET_PRIVACY_POLICY:
      return {
        ...state,
        privacyPolicy: action.payload,
        loading: true,
      };
    case type.SUBMIT_ABOUT:
      return {
        ...state,
        loading: true,
      };
    case type.SUBMIT_PRIVACY_POLICY:
      return {
        ...state,
        loading: true,
      };
    case type.SUBMIT_VIDEO:
      return {
        ...state,
        loading: true,
      };
    case type.DELETE_VIDEO:
      return {
        ...state,
        loading: true,
      };
    case type.CHANGE_USER_PERMISSION:
      return {
        ...state,
        loading: true,
      };
    case type.ALL_USER_DATA:
      return {
        ...state,
        allUserData: action.data,
        loading: false,
      };
    case type.LINK_DATA:
      return {
        ...state,
        linkData: action.data,
        loading: false,
      };
    case type.UPDATE_LINK:
      return {
        ...state,
        loading: true,
      };
    case type.COMPLETE_INCOMPMETE_LIST:
      return {
        ...state,
        completeIncompleteList: action.data,
        loading: false,
      };
    case type.APPROVE_INCOMPLETE_LIST:
      return {
        ...state,
        loading: true,
      };
    case type.SET_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case type.UPDATE_DEACTIVE_LINK:
      return {
        ...state,
        loading: true,
      };
    case type.CHANGE_PASSWORD:
      return {
        ...state,
        loading: false,
      };
    case type.CHANGE_MARQUEE:
      return {
        ...state,
        loading: false,
      };
    case type.GET_USERNAME_PASSWORD:
      return {
        ...state,
        loading: true,
      };
    case type.GET_USER_TICKET:
      return {
        ...state,
        loading: true,
      };
    case type.USER_TICKET_DATA:
      return {
        ...state,
        userTicketData: action.data,
        loading: false,
      };
    case type.ONLY_TICKETS:
      return {
        ...state,
        onlyTicket: action.data,
        loading: false,
      };
    case type.SET_REPLY_DATA:
      return {
        ...state,
        loading: true,
      };
    case type.TRAINING_LINK:
      return {
        ...state,
        trainingLink: action.data.message,
        loading: false,
      };
    case type.LOGIN_STATUS:
      return {
        ...state,
        loginStatus: action.data,
        loading: false,
      };
    case type.FORGOT_SUBMIT_OTP:
      return {
        ...state,
        loading: false,
      };
    case type.SEND_FORGOT_EMAIL:
      return {
        ...state,
        loading: false,
      };
    case type.LOGOUT:
      return {
        ...state,
        logout: true,
      };
    case type.SAGA_ERROR:
      return {
        ...state,
        sagaError: action.data,
        loading: false,
      };
    case type.SET_CHANGE_PASS:
      return {
        ...state,
        loading: true,
      };
    case type.SEND_NOTIFICATION:
      return {
        ...state,
        loading: false,
      };
    case type.SAGA_ERRO_To_TRUE:
      return {
        ...state,
        loading: false,
        sagaError: false,
      };
    case type.FORGOT_PASS_EMAIL:
      return {
        ...state,
        forgotEmailSent: action.data,
        loading: false,
      };
    case type.NOTIFICATION_CALL:
      return {
        ...state,
        pushNotification: action.data,
      };
    case type.RELOAD_REDUX:
      return {
        ...state,
        loading: true,
      };
    case type.RESET_DATA:
      return {
        ...state,
        users: "",
        loginStatus: "",
        loading: false,
        preRequiredData: {
          kycStatus: false,
          kycPending: 0,
          accountApproval: 0,
          ticket: 0,
          paymentPending: 0,
        },
        usersData: {},
        allUserData: {},
        searchedUser: [],
        searchUser: {},
        minBal: 0,
        linkData: {},
        userTicketData: {},
        completeIncompleteList: {},
        trainingLink: {},
        onlyTicket: [],
        descriptionE: "",
        descriptionH: "",
        aboutPolicy: "",
        userData: [],
        pushNotification: [],
        privacyPolicy: "",
        logout: false,
        forgotEmailSent: false,
        error: null,
        sagaError: false,
        token: "",
        selectedLink: {},
        incompleteList: {},
        completeList: {},
        getAlluserData: {},
        dataSaved: false,
        allPaymentData: [],
        trainingData: [],
        openChat: [],
        testimonials: [],
        pendingPayment: [],
        chatDetail: {},
        paymentComplete: [],
        sliderData: [],
        isInsuficientBalance: false,
        privactData: "",
        about_usData: "",
        passwordError: false,
      };

    //NEW API CHANGES
    case type.CHECK_LOGIN:
      return {
        ...state,
        loading: true,
      };
    case type.SET_TOKEN:
      return {
        ...state,
        token: action.data,
      };
    case type.TARGET_LINK:
      return {
        ...state,
        loading: true,
      };
    case type.SELECTED_LINK:
      return {
        ...state,
        selectedLink: action.data,
        loading: false,
      };
    case type.GET_ALL_USER_DATA:
      return {
        ...state,
        loading: false,
        getAlluserData: action.data,
      };
    case type.COMPLETE_LIST:
      return {
        ...state,
        loading: false,
        completeList: action.data,
      };
    case type.INCOMPLETE_LIST:
      return {
        ...state,
        loading: false,
        incompleteList: action.data,
      };
    case type.SEARCH_BOX:
      return {
        ...state,
        loading: false,
        searchResult: action.data,
      };
    case type.SET_SEARCH_BOX:
      return {
        ...state,
      };
    case type.UPDATE_LINK_LIST:
      return {
        ...state,
      };
    case type.APROVE_KYC:
      return {
        ...state,
      };
    case type.DATA_SAVED:
      return {
        ...state,
        dataSaved: action.data,
        loading: false,
      };
    case type.RESER_DESC_E:
      return {
        ...state,
        descriptionE: "",
      };
    case type.RESER_DESC_H:
      return {
        ...state,
        descriptionH: "",
      };
    case type.PAYMENT_DATA:
      return {
        ...state,
        allPaymentData: action.data,
      };
    case type.GET_TRAINING_DATA:
      return {
        ...state,
        trainingData: action.data,
        loading: false,
      };
    case type.OPEN_CHAT:
      return {
        ...state,
        openChat: action.data,
      };
    case type.GET_DETAIL_TICKET:
      return {
        ...state,
        loading: true,
      };
    case type.GET_TESTIMONIALS:
      return {
        ...state,
        loading: true,
      };
    case type.SET_TESTIMONIALS_DATA:
      return {
        ...state,
        testimonials: action.data,
        loading: false,
      };
    case type.DELETE_PERFORMER:
      return {
        ...state,
        loading: true,
      };
    case type.SUBMIT_PERFORMER:
      return {
        ...state,
        loading: true,
      };
    case type.SET_PENDING_PAYMENT:
      return {
        ...state,
        pendingPayment: action.data,
        loading: false,
      };
    case type.GET_PENDING_PAYMENT:
      return {
        ...state,
        loading: true,
      };
    case type.SET_CHAT_DETAILS:
      return {
        ...state,
        chatDetail: action.data,
        // loading: false
      };
    case type.GET_ALL_CHAT_DETAILS:
      return {
        ...state,
        loading: true,
      };
    case type.GET_PAYMENT_COMPLETE_LIST:
      return {
        ...state,
        loading: true,
      };
    case type.SET_COMPLETE_PAYMENT:
      return {
        ...state,
        paymentComplete: action.data,
        loading: false,
      };
    case type.SUBMIT_SLIDER:
      return {
        ...state,
      };
    case type.GET_SLIDER_DATA:
      return {
        ...state,
        sliderData: action.payload,
      };
    case type.GET_SLIDER_DATA2:
      return {
        ...state,
        sliderData: action.data,
      };
    case type.DELETE_SLIDER:
      return {
        ...state,
      };
    case type.REJECTED_LIST:
      return {
        ...state,
        loading: true,
      };
    case type.SET_REJECTED_LIST:
      return {
        ...state,
        rejectedList: action.data,
        loading: false,
      };
    case type.INSUFICIENT_BALANCE:
      return {
        ...state,
        isInsuficientBalance: action.data,
      };
    case type.INSUFICIENT_BALANCES:
      return {
        ...state,
        isInsuficientBalance: false,
      };
    case type.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };
    case type.SET_ABOUT_US:
      return {
        ...state,
        about_usData: action.data,
      };
    case type.SET_ABOUTDATA:
      return {
        ...state,
        about_usData: action.payload,
      };
    case type.SET_PRIVACTDATA:
      return {
        ...state,
        privactData: action.data,
      };
    case type.SET_PRIVACTDATAA:
      return {
        ...state,
        privactData: action.payload,
      };
    case type.CHANGE_PASS:
      return {
        ...state,
      };
    case type.PASSWORD_ERROR:
      return {
        passwordError: action.data,
      };
    case type.PASSWORD_EXP:
      return {
        passwordError: action.payload,
      };
    default:
      return state;
  }
}
