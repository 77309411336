import "../../../css/dashboard.css";
import { Menu } from "antd";
import {
  AppstoreTwoTone,
  ReconciliationTwoTone,
  FilePdfTwoTone,
  ShopOutlined,
  ContainerTwoTone,
  IdcardTwoTone,
  TagsTwoTone,
  UnlockTwoTone,
  TabletTwoTone,
  NotificationTwoTone,
  AlertTwoTone,
  FundTwoTone,
  RightSquareTwoTone,
  ExclamationCircleTwoTone,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

function SideMenu() {
  const navigate = useNavigate();
  return (
    <div className="SideMenu">
      <Menu
        className="SideMenuVertical"
        mode="vertical"
        onClick={(item) => {
          navigate(item.key);
        }}
        items={[
          {
            label: "Dashboard",
            key: "/contain",
            icon: <AppstoreTwoTone twoToneColor="purple" />,
          },
          {
            label: "People",
            key: "/people",
            icon: <IdcardTwoTone />,
          },
          {
            label: "KYC",
            key: "/kyc",
            icon: <ReconciliationTwoTone twoToneColor="#eb2f96" />,
          },
          {
            label: "Admin Options",
            key: "/adminOptions",
            icon: <ShopOutlined />,
          },
          {
            label: "Link Share",
            key: "/linkShare",
            icon: <TagsTwoTone twoToneColor="#52c41a" />,
          },
          {
            label: "Ticket",
            key: "/ticket",
            icon: <ContainerTwoTone />,
          },
          {
            label: "Training",
            key: "/training",
            icon: <TabletTwoTone twoToneColor="orange" />,
          },
          {
            label: "Top Performer",
            key: "/slider",
            icon: <FundTwoTone twoToneColor="red" />,
          },
          {
            label: "Notification",
            key: "/notification",
            icon: <AlertTwoTone twoToneColor="maroon" />,
          },
          {
            label: "Slider",
            key: "/roundTag",
            icon: <RightSquareTwoTone twoToneColor="red" />,   
          },
          // {
          //   label: "Rejected List",
          //   key: "/rejectedList",
          //   icon: <ExclamationCircleTwoTone twoToneColor="Grey"/>,
          // },
          {
            label: "Report",
            key: "/report",
            icon: <FilePdfTwoTone twoToneColor="Green" />,
          },
          {
            label: "About",
            key: "/about",
            icon: <NotificationTwoTone twoToneColor="brown" />,
          },
          {
            label: "Privacy Policy",
            key: "/privacyPolicy",
            icon: <UnlockTwoTone twoToneColor="red" />,
          },
        ]}
      ></Menu>
    </div>
  );
}

export default SideMenu;
