import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./component/login";
import Dashboard from "./component/dashboard/dashboard";
import { Provider } from "react-redux";
import { store, persistor } from "./store/store";
import { PersistGate } from "redux-persist/integration/react";

const App = () => {
  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Router basename="/">
            <Routes>
              <Route path="/" element={<Login />}></Route>
              <Route path="/*" element={<Dashboard />}></Route>
            </Routes>
          </Router>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
