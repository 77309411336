import {
  Typography,
  Space,
  Card,
  Statistic,
  Table,
  Button,
  Modal,
  Spin,
  message,
  Select,
  Input,
  Popconfirm,
} from "antd";
import {
  FormOutlined,
  DollarCircleOutlined,
  UserOutlined,
  MailOutlined,
  WarningTwoTone,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import "../../../../src/css/dashboard.css";
import { useNavigate } from "react-router-dom";
import { Row, Col } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { approveIncompleteList } from "../../../store/actions/users";

function MainDashboard() {
  const navigate = useNavigate();
  const preData = useSelector((state) => state.admin.preRequiredData);
  const loading = useSelector((state) => state.admin.loading);
  const userData = useSelector((state) => state.admin.userData);

  function handlePaymentRequest(data) {
    {
      navigate(data);
    }
  }

  return (
    <>
      {loading && userData !== {} ? (
        <Row>
          <Col span={24}>
            <div style={{ display: "grid", height: "30vh" }}>
              {" "}
              <Spin size="large" style={{ alignSelf: "self-end" }} />{" "}
            </div>
          </Col>
        </Row>
      ) : (
        <Row
          style={{ paddingLeft: "1%", paddingTop: "1%", paddingRight: "1%" }}
        >
          <Col span={24}>
            <Typography.Title level={4}>Dashboard</Typography.Title>
          </Col>
          <Col span={6}>
            <div style={{ marginRight: "7px" }}>
              <DaseboardCard
                className="Card"
                icon={
                  <FormOutlined
                    style={{
                      color: "green",
                      backgroundColor: "rgba(0,255,0,0.25)",
                      borderRadius: 20,
                      fontSize: 24,
                      padding: 8,
                    }}
                  />
                }
                title={<b>Kyc Pending</b>}
                value={preData.kycPending}
              />
            </div>
          </Col>
          <Col span={6}>
            <div
              className="CursorChange"
              onClick={() => {
                let data = "/accountApproval";
                handlePaymentRequest(data);
              }}
              style={{ marginRight: "7px" }}
            >
              <DaseboardCard
                className="Card"
                icon={
                  <UserOutlined
                    style={{
                      color: "purple",
                      backgroundColor: "rgba(0,255,255,0.25)",
                      borderRadius: 20,
                      fontSize: 24,
                      padding: 8,
                    }}
                  />
                }
                title={<b>Account Approval</b>}
                value={preData.accountApproval}
              />
            </div>
          </Col>
          <Col span={6}>
            <div
              className="CursorChange"
              onClick={() => {
                let data = "/ticket";
                handlePaymentRequest(data);
              }}
              style={{ marginRight: "7px" }}
            >
              <DaseboardCard
                className="Card"
                icon={
                  <MailOutlined
                    style={{
                      color: "red",
                      backgroundColor: "rgba(255,0,0,0.25)",
                      borderRadius: 20,
                      fontSize: 24,
                      padding: 8,
                    }}
                  />
                }
                title={<b>Ticket</b>}
                value={preData.ticket}
              />
            </div>
          </Col>
          <Col span={6}>
            <div
              className="CursorChange"
              onClick={() => {
                let data = "/paymentRequest";
                handlePaymentRequest(data);
              }}
            >
              <DaseboardCard
                className="Card"
                icon={
                  <DollarCircleOutlined
                    style={{
                      color: "green",
                      backgroundColor: "rgba(207,219,0)",
                      borderRadius: 20,
                      fontSize: 24,
                      padding: 8,
                    }}
                  />
                }
                title={<b>Payment Request</b>}
                value={preData.paymentPending}
              />
            </div>
            {/* <div style={{ marginTop: "20px" }}>
              <Select
                defaultValue={5}
                style={{ width: 120 }}
                onChange={handleDisplay}
                options={[
                  { value: 5, label: "5" },
                  { value: 10, label: "10" },
                  { value: 15, label: "15" },
                  { value: 20, label: "20" },
                ]}
              />
            </div> */}
          </Col>
          <Col span={12} style={{ paddingTop: "2%" }}>
            <PendingList />
          </Col>

          <Col span={11} style={{ paddingTop: "2%", marginLeft: "10px" }}>
            <CompleteList />
          </Col>
        </Row>
      )}
    </>
  );
}

function DaseboardCard({ title, value, icon }) {
  return (
    <Card>
      <Space direction="horizontal">
        {icon}
        <Statistic title={title} value={value} />
      </Space>
    </Card>
  );
}

function PendingList() {
  const [pendingData, setPendingData] = useState([]);
  const [modalDataPending, setModalDataPending] = useState({});
  const loading = useSelector((state) => state.admin.loading);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalDetailOpen, setIsModalDetailOpen] = useState(false);
  const userData = useSelector((state) => state.admin.usersData);
  const completeIncomplete = useSelector(
    (state) => state.admin.completeIncompleteList
  );
  const incomplete = useSelector((state) => state.admin.incompleteList);
  const [pendinglistSubmit, setPendinglistSubmit] = useState();
  const dispatch = useDispatch();
  let pendingdata = [];
  const [staticMoreData, setStaticMoreData] = useState({
    subscriberId: "ybhomm1",
    phoneNumber: "8390782182",
    amount: "200",
    comment: "hey, i need money for my task",
    link: "hhtps://google.co",
    address: "",
  });
  incomplete?.map((list) => {
    if (list.status === "SUBMITED") {
      const pendingDataobj = {
        id: list.user_id,
        linkId: list.id,
        userName: list.user_name,
        comment: list.data,
        amount: list.amount,
        date: list.modified_at,
        kycStatus: list.kyc_status,
        title_en: list.title_en,
        customer_name: list.customer_name,
        customer_mobile: list.customer_mobile,
        url: list.url,
        // cMobile: list.mdn,
        // cName: list.name,
        // title: list.link_title_en,
        // phoneNumber: lists.mdn,
      };
      pendingdata.push(pendingDataobj);
    }
  });

  // incomplete?.map((list) => {
  //  // list.data = JSON.parse(list.data )
  //   console.log('list.data',list.data)
  //   console.log('type', typeof list.data)
  // })

  let count = 0;

  const handleCancel = () => {
    pendinglistSubmit.reject = false;
    pendinglistSubmit.rejectComment = "";
    setIsModalOpen(false);
  };
  const handleOk = () => {
    pendinglistSubmit.reject = false;
    pendinglistSubmit.rejectComment = "";
    dispatch(approveIncompleteList(pendinglistSubmit));

    setIsModalOpen(false);
  };
  const showModal = (record) => {
    //  pendinglistSubmit.rejectComment = ""
    setPendinglistSubmit(record);
    setIsModalOpen(true);
  };

  const showDetailModel = (record) => {
    setIsModalDetailOpen(true);
    setModalDataPending(record);
  };
  const handleMoreCancel = () => {
    setIsModalDetailOpen(false);
  };
  const handleMoreOk = () => {
    setIsModalDetailOpen(false);
  };
  const handleReject = () => {
    pendinglistSubmit.reject = true;
    dispatch(approveIncompleteList(pendinglistSubmit));
    setIsModalOpen(false);
  };

  let data = [];

  return (
    <div style={{ alignItems: "normal" }}>
      <Table
        style={{ width: "600px", height: "4px", display: "contents" }}
        columns={[
          {
            title: "Incomplete Request",
            dataIndex: "title",
            key: "id",
            render: (text, record) => (
              <span
                onClick={() => showDetailModel(record)}
                className="CursorChange"
              >
                User: {record.userName} <br />
                Date: {record.date} <br />
                Title: {record.title_en}
                <br />
                {/* Work: {record.comment} <br /> */}
                Kyc Status:{" "}
                {record.kycStatus === "ACCEPTED" ? (
                  <span style={{ color: "green" }}>Complete</span>
                ) : (
                  <span style={{ color: "red" }}> Not Complete</span>
                )}
                <br />
              </span>
            ),
          },
          {
            title: <center> Approve </center>,
            key: "inCompleteBtn" + Math.random(),
            render: (text, record) => (
              <center>
                <Button onClick={() => showModal(record)} type="primary">
                  Approve
                </Button>
              </center>
            ),
          },
        ]}
        loading={loading}
        dataSource={pendingdata}
        pagination={{ defaultPageSize: 20 }}
      ></Table>
      <Modal
        title="Confirm Approval"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Popconfirm
            title="Reason for Reject"
            okButtonProps={{ disabled: false }}
            description={
              <>
                <Input
                  placeholder="Eg: Wrong link shared."
                  style={{ width: "300px" }}
                  // value={pendinglistSubmit.rejectComment}
                  onChange={(e) => {
                    pendinglistSubmit.rejectComment = e.target.value;
                  }}
                ></Input>
              </>
            }
            onConfirm={handleReject}
            onCancel={handleCancel}
            okText="Yes"
            cancelText="No"
            icon={
              <WarningTwoTone
                twoToneColor="red"
                style={{ display: "inline-flex" }}
              />
            }
            style={{ width: "100px" }}
            //  onOpenChange={(e)=>console.log('value',e.target.value)}
          >
            <Button
              type="primary"
              danger
              // onClick={handleReject}
              style={{ float: "left" }}
            >
              Reject
            </Button>
          </Popconfirm>,
          <Button type="primary" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button type="primary" onClick={handleOk}>
            Ok
          </Button>,
        ]}
      >
        <Space direction="vertical" style={{ paddingBottom: "7px" }}>
          <hey>Are you sure you want to approve this request ?</hey>
          {/* {} <Input placeholder="Reason for reject"></Input> */}
        </Space>
      </Modal>
      <Modal
        title="More details "
        open={isModalDetailOpen}
        onOk={handleMoreOk}
        onCancel={handleMoreCancel}
      >
        <Space direction="vertical">
          <Typography.Text>
            <b> Customer Name:</b> {modalDataPending.customer_name}
          </Typography.Text>
          <Typography.Text>
            <b>Customer Number:</b> {modalDataPending.customer_mobile}
          </Typography.Text>
          <Typography.Text>
            <b>Amount:</b> {modalDataPending.amount}
          </Typography.Text>
          <Typography.Text>
            <b>URL:</b> {modalDataPending.url}
          </Typography.Text>
          <Typography.Text>
            <b>Comment:</b> {modalDataPending.comment}
          </Typography.Text>
        </Space>
      </Modal>
    </div>
  );
}

function CompleteList() {
  const loading = useSelector((state) => state.admin.loading);
  const completeIncomplete = useSelector(
    (state) => state.admin.completeIncompleteList
  );
  const completedList = useSelector((state) => state.admin.completeList);
  let completedData = [];

  completedList?.map((list) => {
    const completeListData = {
      id: list.user_id,
      name: list.user_name,
      //  comment: list.data,
      amount: list.amount,
      date: list.created_at,
      kycStatus: list.kyc_status,
      title_en: list.title_en,
      dateM: list.modified_at,
    };
    completedData.push(completeListData);
  });

  return (
    <Table
      style={{ width: "600px", height: "40px", display: "contents" }}
      columns={[
        {
          title: "Completed Request",
          dataIndex: "title",
          key: "id",
          render: (text, record) => (
            <span>
              User: {record.name} <br />
              {/* Work: {record.comment} <br /> */}
              Title: {record.title_en}
              <br />
              Date: {record.dateM}
              <br />
              Kyc Status:{" "}
              {record.kycStatus === 0 ? (
                <span style={{ color: "red" }}> Not Complete</span>
              ) : (
                <span style={{ color: "green" }}>Complete</span>
              )}
            </span>
          ),
        },
        {
          title: <center> Approved</center>,
          key: "id",
          render: (text) => (
            <center>
              {" "}
              <Button type="dashed">Completed</Button>
            </center>
          ),
        },
      ]}
      loading={loading}
      dataSource={completedData}
      pagination={{ defaultPageSize: 20 }}
    ></Table>
  );
}

export default MainDashboard;
