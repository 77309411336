import { Typography } from "antd";
import { Input, Row, Col, Button, Modal, List, Spin, Image } from "antd";
import { FireTwoTone, LoadingOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "../../../../css/dashboard.css";
import {
  submitDeleteVideo,
  submitPerformer,
  getTestimonials,
  deletePerformer,
} from "../../../../store/actions/users";
const { TextArea } = Input;

const Slider = () => {
  const [openModel, setOpenModel] = useState(false);
  const [openAddModel, setOpenAddModel] = useState(false);
  const loading = useSelector((state) => state.admin.loading);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const testimonials = useSelector((state) => state.admin.testimonials);
  const [finalTestimonials, setFinalTestimonials] = useState([]);
  const [itemData, setItemData] = useState({});
  const [deleteItem, setDeleteItem] = useState();
  const [openDescPopup, setOpenDescPopup] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [descFields, setDescFields] = useState({desc:'', photo:null});
  const dispatch = useDispatch();
  const optionsWithDisabled = [
    { label: "Inactive", value: "Inactive" },
    { label: "Active", value: "Active" },
  ];
  const [addPerformer, setAddPerformer] = useState({
    key: "",
    title: "",
    url: "",
    subTitle: "",
    desc: "",
    photo: "",
  });

  useEffect(() => {
    dispatch(getTestimonials());
    setAddPerformer({
      key: "",
      title: "",
      url: "",
      subTitle: "",
      desc: "",
      photo: "",
    });
    setSelectedImage(null);
  }, []);

    useEffect(() => {
      if (loading) {
        testimonials.map((list) => {
          let data = {
            title: list.title,
            description: list.description,
            link: list.link,
            subtitle: list.subtitle,
            id: list.id,
            photo: list.photo,
          };
          setFinalTestimonials((oldData) => [...oldData, data]);
        });
      }
    }, [loading]);

  const onChange4 = (item) => {
    setItemData(item);
    setOpenModel(true);
  };

  function handleAddOk() {
    let data = {
      //  key: '' ,
      title: addPerformer.title,
      url: addPerformer.url,
      subTitle: addPerformer.subTitle,
      desc: addPerformer.desc,
      photo: selectedImage,
    };
    dispatch(submitPerformer(data));
    setAddPerformer({
      key: "",
      title: "",
      url: "",
      subTitle: "",
      desc: "",
      photo: "",
    });
    setSelectedImage(null);
    setOpenAddModel(false);
  }

  function handleAddCancel() {
    // setAddVideoData({
    //   title: "",
    //   url: "",
    // });
    setAddPerformer({
      key: "",
      title: "",
      url: "",
      subTitle: "",
      desc: "",
      photo: "",
    });
    setSelectedImage(null);
    setOpenAddModel(false);
  }

  function handleOkDelete() {
    dispatch(deletePerformer(deleteItem));
    setOpenModel(false);
  }

  function handleCancelDelete() {
    setOpenModel(false);
  }
  function handleOpenPopup(item) {
    setDeleteItem(item);
    setOpenModel(true);
  }

  function handleFileReset(e) {
    setSelectedImage(null);
    document.getElementById("upload").value = "";
  }

  function handleFile(e) {
    setSelectedImage(e.target.files[0]);
    //e.taget.files[0] = null
  }

  function handleModel(item) {
    let data = { desc: item.description, photo: item.photo };
    setDescFields((list) => ({
      ...list,
      ...data,
    }));
    setOpenDescPopup(true);
  }

  function handleOkDesc() {
    setOpenDescPopup(false);
  }

  function handleCancelDesc() {
    setOpenDescPopup(false);
  }

  return (
    <div>
         {loading ? (
        <Row>
          <Col span={24}>
            <div style={{ display: "grid", height: "30vh" }}>
              {" "}
              <Spin size="large" style={{ alignSelf: "self-end" }} />{" "}
            </div>
          </Col>
        </Row>
      ) : (
        <>
      <Row style={{ paddingLeft: "1%", paddingTop: "1%", paddingRight: "1%" }}>
        <Col span="24" style={{ display: "flex" }}>
          <Typography.Title level={4}>Top Performer</Typography.Title>
          <Button
            onClick={() => setOpenAddModel(true)}
            danger
            style={{ marginLeft: "auto", marginRight: "20px", width: "150px" }}
          >
            {" "}
            + Add
          </Button>
        </Col>
        <Col span={24} style={{ marginTop: "30px" }}>
          <List
            itemLayout="horizontal"
            dataSource={testimonials}
            pagination={{ defaultPageSize: 8 }}
            loading={loading}
            key={"link_id"}
            renderItem={(item, index) => (
              <List.Item className="CursorChange" >
                <List.Item.Meta
                   onClick={()=>handleModel(item)}
                  avatar={<FireTwoTone twoToneColor="red" />}
                  title={
                    <>
                      <div style={{ display: "flex" }}>
                        {" "}
                        <Typography.Text strong={true} level={2}>
                          {item.title}{" "}
                        </Typography.Text>
                      </div>
                      <div>
                        <Typography.Text level={6}>
                          {item.subtitle}
                        </Typography.Text>
                      </div>
                    </>
                  }
                  description={
                    <>
                      <Typography.Text>{item.link}</Typography.Text>
                    </>
                  }
                  
                />
                {loading ? (
                  <Spin
                    size="large"
                    style={{ alignSelf: "self-end" }}
                    indicator={antIcon}
                  />
                ) : (
                  <Button
                    type="primary"
                    danger
                    onClick={() => handleOpenPopup(item)}
                  >
                    Delete
                  </Button>
                )}
              </List.Item>
            )}
          />
          <Modal
            title="Confirmation"
            open={openModel}
            onOk={handleOkDelete}
            onCancel={handleCancelDelete}
           
          >
            <Typography>
              {" "}
              Are you sure you want to delete this Performer?{" "}
            </Typography>
          </Modal>
          <Modal
            title="Details"
            open={openDescPopup}
            onOk={handleOkDesc}
            onCancel={handleCancelDesc}
            okButtonProps={{hidden:true}}
          >
            <Typography>
            <Image width={100} src={descFields.photo} /> <br/>
             <Typography.Text style={{marginTop:'20px'}}>Description: {descFields.desc}</Typography.Text>
            
            </Typography>
          </Modal>
          <Modal
            title="Add Performer"
            open={openAddModel}
            onOk={handleAddOk}
            okButtonProps={{
              disabled:
                addPerformer.title === "" ||
              //  addPerformer.url === "" ||
                addPerformer.subTitle === "" ||
                addPerformer.desc === "",
            }}
            onCancel={handleAddCancel}
          >
            <div>
              <Input
                size="large"
                placeholder="Enter Title"
                value={addPerformer.title}
                onChange={(e) => {
                  let data = { title: e.target.value };
                  setAddPerformer((list) => ({
                    ...list,
                    ...data,
                  }));
                }}
              ></Input>{" "}
              <br></br>
              <br></br>
              <Input
                size="large"
                placeholder="Enter Sub Title"
                value={addPerformer.subTitle}
                onChange={(e) => {
                  let data = { subTitle: e.target.value };
                  setAddPerformer((list) => ({
                    ...list,
                    ...data,
                  }));
                }}
              ></Input>
              <br />
              <br />
              {/* <Input
            //  style={{visibility:'hidden'}}
                size="large"
                value={addPerformer.url}
                placeholder="Enter URL"
                onChange={(e) => {
                  let data = { url: e.target.value };
                  setAddPerformer((list) => ({
                    ...list,
                    ...data,
                  }));
                }}
              ></Input>
              <br></br>
              <br /> */}
              <TextArea
                rows={4}
                value={addPerformer.desc}
                placeholder="Description"
                onChange={(e) => {
                  let data = { desc: e.target.value };
                  setAddPerformer((list) => ({
                    ...list,
                    ...data,
                  }));
                }}
              />
              <br />
              <br />
              <input
                id="upload"
                type="file"
                name="myImage"
                accept=".jpeg,.jpg"
                onChange={(e) => {
                  handleFile(e);
                }}
              />
              {selectedImage && (
                <div>
                  <Image
                    style={{ marginTop: "10px" }}
                    alt="not found"
                    width={"150px"}
                    height={"70px"}
                    src={URL.createObjectURL(selectedImage)}
                  />
                  <br />
                  <br />
                  <Button onClick={(e) => handleFileReset(e)} danger>
                    Remove
                  </Button>
                </div>
              )}
            </div>
          </Modal>
        </Col>
      </Row>
      </>
      )}
    </div>
    
  );
};

export default Slider;

// dispatch(submitVideo(sendVideo))
// dispatch(submitDeleteVideo(deleteVideo))
