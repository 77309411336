import {
  Typography,
  Input,
  Radio,
  List,
  Avatar,
  Modal,
  Space,
  Button,
  Row,
  Col,
  DatePicker,
  Spin,
  AutoComplete,
} from "antd";
import { useEffect, useState } from "react";
import {
  CheckCircleTwoTone,
  MessageTwoTone,
  DollarTwoTone,
  LoadingOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  searchUsers,
  changeUserPermission,
  setPaymentRequest,
  setSearchBox,
  getPendingPayment,
} from "../../../../store/actions/users";
import { CloseSquareFilled } from "@ant-design/icons";
import "../../../../css/dashboard.css";

function AccountApproval() {
  const currentPage = window.location.href;
  const usersSearchList = useSelector((state) => state.admin.searchResult);
  const [searchData, setSearchData] = useState({});
  const [searchError, setSearchError] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let options = [];
  usersSearchList?.map((list) => {
    options.push({ value: `${list.name}  =>  ${list.mobile}`, id: list.id });
  });

  function handleSearch(data, id) {
    dispatch(searchUsers(id.id));
    navigate("/SearchedUser");
    // setSearchData(email);
    setSearchError(false);
  }

  function handleSubmit() {
    // if (searchData.email) {
    //   dispatch(searchUsers(searchData.email));
    //   navigate("/SearchedUser");
    // } else {
    //   setSearchError(true);
    // }
  }

  return (
    <div style={{ paddingBottom: "10px" }}>
      {usersSearchList === {} ? (
        <Row>
          <Col span={24}>
            <div style={{ display: "grid", height: "30vh" }}>
              {" "}
              <Spin size="large" style={{ alignSelf: "self-end" }} />{" "}
            </div>
          </Col>
        </Row>
      ) : (
        <>
          <Row
            style={{ paddingTop: "1%", paddingLeft: "1%", paddingRight: "1%" }}
          >
            <Col span="24">
              {currentPage.includes("accountApproval") ? (
                <Typography.Title level={4}>Account Approval</Typography.Title>
              ) : (
                <Typography.Title level={4}>Payment Request</Typography.Title>
              )}
            </Col>

            <Space.Compact style={{ width: "98%", marginTop: "1%" }}>
              <AutoComplete
                style={{ width: "100%" }}
                onSelect={handleSearch}
                placeholder="input here"
                options={options}
                onSearch={(value) => dispatch(setSearchBox(value))}
                size="large"
                filterOption={(inputValue, option) =>
                  option.value
                    .toUpperCase()
                    .indexOf(inputValue.toUpperCase()) !== -1
                }
                allowClear={{ clearIcon: <CloseSquareFilled /> }}
                notFoundContent="No user found -> Please login again"
              />
              {/* <Button type="primary" size="large" onClick={handleSubmit}>
                Submit
              </Button> */}
            </Space.Compact>
            {searchError ? (
              <Typography.Text style={{ color: "red" }}>
                {" "}
                Please select option from suggested dropdown{" "}
              </Typography.Text>
            ) : (
              ""
            )}

            <Col span={24}>
              <div style={{ marginTop: "2%", marginRight: "17px" }}>
                <ListData />
              </div>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export function ListData() {
  const { TextArea } = Input;
  const currentPage = window.location.href;
  const userData = useSelector((state) => state.admin.usersData);
  const usersSearchList = useSelector((state) => state.admin.allUserData);
  const isSearchUserAvailable = useSelector((state) => state.admin.searchUser);
  const pendingPayment = useSelector((state) => state.admin.pendingPayment);
  const [changeData, setChangeData] = useState(usersSearchList);
  const [permissionChnageUser, setPermissionChnageUser] = useState({});
  const allUserData = useSelector((state) => state.admin.getAlluserData);
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
  const completeIncomplete = useSelector(
    (state) => state.admin.completeIncompleteList
  );
  const listData = [];
  const users = [];
  const [modalData, setModalData] = useState({
    amount: "",
    deductible_amount: "",
    tid: "",
    date: "",
    other: "",
    pid: "",
    rjid: "",
    reject: false,
  });
  const [dataSet, setDataSet] = useState(listData);
  let userId = "";
  const loading = useSelector((state) => state.admin.loading);
  const dispatch = useDispatch();
  const optionsWithDisabled = [
    { label: "Inactive", value: "Inactive" },
    { label: "Active", value: "Active" },
  ];
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const onChange4 = (item) => {
    setPermissionChnageUser(item);
    setConfirmationPopup(true);
  };
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    dispatch(getPendingPayment());
  }, []);

  currentPage.includes("accountApproval")
    ? allUserData?.map((list) => {
        let listObj = {
          userName: list.name,
          kycStatus: list.kyc_status,
          isAccountApprove: list.status === "ACTIVE" ? 1 : 0,
          id: list.id,
          email: list.email,
        };
        users.push(listObj);
      })
    : pendingPayment?.map((lists) => {
        const pendingDataobj = {
          id: lists.user_id,
          linkId: lists.id,
          userName: lists.name,
          comment: lists.comment,
          amount: lists.amount,
          date: lists.created_at,
          kycStatus: lists.kyc_status,
        };
        listData.push(pendingDataobj);
      });
  const [amount, setAmount] = useState(0);
  const showModal = (record) => {
    setAmount(record.amount);
    let data = { pid: record.linkId };
    setModalData((list) => ({
      ...list,
      ...data,
    }));
    setIsModalOpen(true);
  };

  const handleOkPay = () => {
    modalData.reject = false;
    dispatch(setPaymentRequest(modalData));
    setModalData({
      amount: "",
      deductible_amount: "",
      tid: "",
      date: "",
      other: "",
      pid: "",
      rjid: "",
      reject: false,
    });
    setIsModalOpen(false);
  };

  const handleCancelPay = () => {
    modalData.reject = false;
    setModalData({
      amount: "",
      deductible_amount: "",
      tid: "",
      date: "",
      other: "",
      pid: "",
      rjid: "",
      reject: false,
    });
    setIsModalOpen(false);
  };

  const handleOk = () => {
    dispatch(changeUserPermission(permissionChnageUser));
    setChangeData(() => usersSearchList);
    setConfirmationPopup(false);
  };
  const handleCancel = () => {
    setConfirmationPopup(false);
  };
  const onOk = (value) => {};

  const onChange = (value, dateString) => {
    let data = { date: dateString };
    setModalData((list) => ({
      ...list,
      ...data,
    }));
  };

  const handleReject = () => {
    modalData.reject = true;
    dispatch(setPaymentRequest(modalData));
    setIsModalOpen(false);
  };

  return (
    <>
      {" "}
      <List
        itemLayout="horizontal"
        dataSource={currentPage.includes("accountApproval") ? users : listData}
        pagination={{ defaultPageSize: 20 }}
        // key={'id'}
        renderItem={(item, index) => (
          <List.Item key={index}>
            <List.Item.Meta
              className="CursorChange"
              onClick={() => {
                //  userId = item.userId
                dispatch(searchUsers(item.id));
                {
                  navigate("/SearchedUser");
                }
              }}
              avatar={
                <Avatar
                  src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=${index}`}
                />
              }
              title={item.userName}
              description={
                <div>
                  <b> Kyc Status: </b>
                  {item.kycStatus !== "ACCEPTED" ? (
                    <span style={{ color: "red" }}> Not Complete</span>
                  ) : (
                    <span style={{ color: "green" }}> Complete</span>
                  )}{" "}
                  {currentPage.includes("accountApproval") ? (
                    ""
                  ) : (
                    <>
                      {" "}
                      <b style={{ color: "black" }}> | </b>{" "}
                      <b> Date: {item.date} </b>
                      <span style={{ color: "black" }}>
                        {" "}
                        {/* {item.date.slice(0, 10)}{" "} */}
                      </span>
                      <b style={{ color: "black" }}> | </b> <b> Amount: </b>
                      <span style={{ color: "black" }}> {item.amount} </span>
                      <b style={{ color: "black" }}></b>
                      {/* | </b>
                       <Popover content={item.link}>
                        <span style={{ color: "blue" }}>link...</span>
                      </Popover> */}
                      <br />
                      <b>Comment: </b>{" "}
                      <span style={{ color: "black" }}>{item.comment}</span>{" "}
                    </>
                  )}
                </div>
              }
            />

            {currentPage.includes("accountApproval") ? (
              loading ? (
                <Spin
                  size="large"
                  style={{ alignSelf: "self-end" }}
                  indicator={antIcon}
                />
              ) : (
                <Radio.Group
                  options={optionsWithDisabled}
                  onChange={() => onChange4(item)}
                  value={item.isAccountApprove ? "Active" : "Inactive"}
                  optionType="button"
                  buttonStyle="outline"
                />
              )
            ) : loading ? (
              <Spin
                size="large"
                style={{ alignSelf: "self-end" }}
                indicator={antIcon}
              />
            ) : (
              <Button
                type="primary"
                size="medium"
                onClick={() => showModal(item)}
                style={{ width: "7%" }}
              >
                Pay
              </Button>
            )}
          </List.Item>
        )}
      />
      <Modal
        title="Confirmation"
        open={confirmationPopup}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Typography>
          {" "}
          Are you sure you want to change user's permission?{" "}
        </Typography>
      </Modal>
      <Modal
        title="Payment Details"
        open={isModalOpen}
        onOk={handleOkPay}
        okButtonProps={{
          disabled:
            modalData.date === "" ||
            modalData.other === "" ||
            modalData.tid === "",
        }}
        onCancel={handleCancelPay}
        footer={[
          <Button
            type="primary"
            danger
            onClick={handleReject}
            style={{ float: "left" }}
          >
            Reject Request
          </Button>,
          <Button type="primary" onClick={handleCancelPay}>
            Cancel
          </Button>,
          <Button type="primary" onClick={handleOkPay}>
            Ok
          </Button>,
        ]}
      >
        <Space direction="vertical">
          <Typography.Text>
            User requested for <span style={{ color: "red" }}>{amount}rs</span>
          </Typography.Text>
          <Input
            size="large"
            placeholder="Transaction Id"
            prefix={<CheckCircleTwoTone />}
            onChange={(e) => {
              let data = { tid: e.target.value };
              setModalData((list) => ({
                ...list,
                ...data,
              }));
            }}
          />
          <Input
            type="number"
            size="large"
            placeholder="TDS"
            value={modalData.deductible_amount}
            prefix={<DollarTwoTone />}
            onChange={(e) => {
              let data = { deductible_amount: e.target.value };
              setModalData((list) => ({
                ...list,
                ...data,
              }));
            }}
          />
          <DatePicker size="large" showTime onChange={onChange} onOk={onOk} />
          <Input
            size="large"
            placeholder="Other Details"
            prefix={<MessageTwoTone />}
            onChange={(e) => {
              let data = { other: e.target.value };
              setModalData((list) => ({
                ...list,
                ...data,
              }));
            }}
          />
        </Space>
      </Modal>
    </>
  );
}

export default AccountApproval;
