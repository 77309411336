import { useState } from "react";
import {
  Typography,
  Row,
  Col,
  Button,
  Space,
  Modal,
  Card,
  Alert,
  Spin,
  Image,
} from "antd";
import { UserDetails } from "../People/SearchedUser";
import { useDispatch, useSelector } from "react-redux";
import { approveKYC } from "../../../../store/actions/users";

function UserKyc() {
  const [confirmationPopup, setConfirmationPopup] = useState(false);
  const dispatch = useDispatch();
  const usersSearchList = useSelector((state) => state.admin.searchUser);
  const loading = useSelector((state) => state.admin.loading);
  // const profileImg = usersSearchList.user.photo;
  const handleOk = () => {
    dispatch(approveKYC());
    setConfirmationPopup(false);
  };
  const handleCancel = () => {
    setConfirmationPopup(false);
  };

  return (
    <>
      {loading ? (
        <Row>
          <Col span={24}>
            <div style={{ display: "grid", height: "30vh" }}>
              {" "}
              <Spin size="large" style={{ alignSelf: "self-end" }} />{" "}
            </div>
          </Col>
        </Row>
      ) : (
        <Row
          style={{ paddingLeft: "1%", paddingTop: "1%", paddingRight: "1%" }}
        >
          <Col span={24}>
            <Typography.Title level={4}>KYC Info :</Typography.Title>
          </Col>
          <Col
            span={24}
            style={{
              border: "1px solid rgba(0,0,0,0.15)",
              borderWidth: "thick",
              marginBottom: "10px",
              borderRadius: "20px",
            }}
          >
            {/* <Card className="CardFullLength" style={{ marginBottom: "10px" }}> */}
            <Card
              style={{
                backgroundColor: "rgba(237, 237, 237, 0.8)",
                marginTop: "5px",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            >
              <div style={{ display: "flex" }}>
                <Col span={4}>
                  <div
                    style={{
                      border: "1px solid rgba(0,0,0,0.15)",
                      borderWidth: "thick",
                      display: "inline-block",
                      justifyContent: "space-between",
                    }}
                  >
                    {usersSearchList.user.photo === "" ? (
                      <img
                        src={`https://xsgames.co/randomusers/avatar.php?g=pixel&key=1`}
                        width="100px"
                      />
                    ) : (
                      <Image width={100} src={usersSearchList.user.photo} />
                    )}
                  </div>
                </Col>
                <Col span={12}>
                  <UserDetails />
                </Col>
                <Col span={4}>
                  <div style={{ float: "right" }}>
                    <Space direction="vertical" style={{ width: "100%" }}>
                      <Button
                        type="primary"
                        size="large"
                        danger
                        style={{
                          width: "200%",
                          height: "7vh",
                          marginTop: "4vh",
                        }}
                        disabled={usersSearchList.user.kyc_status === 'ACCEPTED'}
                        onClick={() => setConfirmationPopup(true)}
                      >
                        Approve
                      </Button>
                    </Space>
                  </div>
                </Col>
              </div>
            </Card>
          </Col>
          <Col span={24}>
            {usersSearchList.user.aadhar === "" ||
            usersSearchList.user.pan === "" ||
            usersSearchList.user.pan === null ||
            usersSearchList.user.aadhar === null ? (
              <Alert
                showIcon
                message={
                  usersSearchList.user.bank_details === ""
                    ? "NO KYC DETAILS TO DISPLAY"
                    : usersSearchList.bank_details
                }
                type="warning"
              />
            ) : (
              <>
                <Alert
                  showIcon
                  message={`Aadhar Number => ${usersSearchList.user.aadhar}`}
                  type="success"
                  style={{ marginTop: "20px" }}
                />
                <Alert
                  showIcon
                  message={`Pan Card => ${usersSearchList.user.pan}`}
                  type="success"
                  style={{ marginTop: "20px" }}
                />
              </>
            )}
          </Col>
          <Modal
            title="Confirmation"
            open={confirmationPopup}
            onOk={handleOk}
            onCancel={handleCancel}
          >
            <Typography>
              {" "}
              Are you sure you want to approve user's KYC?{" "}
            </Typography>
          </Modal>
        </Row>
      )}
    </>
  );
}

export default UserKyc;
