import { Typography, Row, Col, Button } from "antd";
import JoditEditor from "jodit-react";
import { useState, useRef, useMemo, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setPrivacyPolicy,
  submitPrivacyPolicy,
  setPrivacyDataa,
  setLoading,
} from "../../../../store/actions/users";

function About() {
  const editor = useRef(null);
  const placeholder = "Enter Privacy policy section here ";
  const dispatch = useDispatch();
  const config = {
    readonly: false,
    placeholder: placeholder || "Start typings...",
    height: "80vh",
    width: "100%",
    buttons: [
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "|",
      "ul",
      "ol",
      "|",
      "center",
      "left",
      "right",
      "justify",
      "|",
      "font",
      "fontsize",
    ],
    uploader: { insertImageAsBase64URI: true },
    removeButtons: ["brush", "file"],
    showXPathInStatusbar: false,
    showCharsCounter: false,
    showWordsCounter: false,
    toolbarAdaptive: false,
  };
  const privacyData = useSelector((state) => state.admin.privactData);
  const bearer = useSelector((state) => state.admin.token);
  const [content, setContent] = useState(privacyData);
  const loading = useSelector((state) => state.admin.loading);

  useEffect(() => {
    function handleResponse(resp) {
      let data = JSON.parse(resp);
      setContent(data.settings[0].option_value);
      dispatch(setPrivacyDataa(data.settings[0].option_value));
      dispatch(setLoading(false));
    }
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + bearer);
    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };
    fetch("https://araffiliates.com/api/setting/privacy_policy", requestOptions)
      .then((response) => response.text())
      .then((result) => handleResponse(result));
  });

  return useMemo(
    () => (
      <Row style={{ paddingLeft: "1%", paddingTop: "1%", paddingRight: "1%" }}>
        <Col span="24">
          <div style={{ display: "flex" }}>
            <Col span={12}>
              <Typography.Title level={3}>Privacy Policy</Typography.Title>
            </Col>
            <Col span={12}>
              <Button
                type="primary"
                size="large"
                onClick={() => dispatch(submitPrivacyPolicy(content))}
                style={{ float: "right", width: "150px" }}
              >
                Submit
              </Button>
            </Col>
          </div>
        </Col>
        <Col span={24} style={{ marginRight: "20px" }}>
          <div style={{ marginBottom: "10px" }}>
            <JoditEditor
              ref={editor}
              value={content}
              config={config}
              onChange={(content) => {
                setContent(content);
                dispatch(setPrivacyPolicy(content));
              }}
            />
          </div>
        </Col>
      </Row>
    ),
    []
  );
}

export default About;
