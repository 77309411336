export const GET_USERS_REQUESTED = "GET_USERS_REQUESTED";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";
export const PRE_REQUIRED_DATA = "PRE_REQUIRED_DATA";
export const PRE_REQUIRED_DATA_Response = "PRE_REQUIRED_DATA_Response";
export const RESET_TO_ORIGINAL = "RESET_TO_ORIGINAL";
export const UPDATE_USERDATA = "UPDATE_USERDATA";
export const SEARCH_USER_Response = "SEARCH_USER_Response";
export const SEARCH_USER = "SEARCH_USER";
export const SET_MINIMUM_BALANCE = "SET_MINIMUM_BALANCE";
export const SET_MINIMUM_BALANCE_DATA = "SET_MINIMUM_BALANCE_DATA";
export const SET_DESCRIPTION_ENGLISH = "SET_DESCRIPTION_ENGLISH";
export const SET_DESCRIPTION_HINDI = "SET_DESCRIPTION_HINDI";
export const SET_LINK_SHARE_DATA = "SET_LINK_SHARE_DATA";
export const SET_ABOUT_POLICY = "SET_ABOUT_POLICY";
export const SET_PRIVACY_POLICY = "SET_PRIVACY_POLICY";
export const SUBMIT_ABOUT = "SUBMIT_ABOUT";
export const SUBMIT_PRIVACY_POLICY = "SUBMIT_PRIVACY_POLICY";
export const SUBMIT_VIDEO = "SUBMIT_VIDEO";
export const DELETE_VIDEO = "DELETE_VIDEO";
export const RESET_DATA = "RESET_DATA";
export const CHANGE_USER_PERMISSION = "CHANGE_USER_PERMISSION";
export const ALL_USER_DATA = "ALL_USER_DATA";
export const SEARCHED_USER_RESPONSE = "SEARCHED_USER_RESPONSE";
export const LINK_DATA = "LINK_DATA";
export const UPDATE_LINK = "UPDATE_LINK";
export const COMPLETE_INCOMPMETE_LIST = "COMPLETE_INCOMPMETE_LIST";
export const APPROVE_INCOMPLETE_LIST = "APPROVE_INCOMPLETE_LIST";
export const SET_PAYMENT_REQUEST = "SET_PAYMENT_REQUEST";
export const UPDATE_DEACTIVE_LINK = "UPDATE_DEACTIVE_LINK";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";
export const CHANGE_MARQUEE = "CHANGE_MARQUEE";
export const GET_USERNAME_PASSWORD = "GET_USERNAME_PASSWORD";
export const GET_USER_TICKET = "GET_USER_TICKET";
export const USER_TICKET_DATA = "USER_TICKET_DATA";
export const ONLY_TICKETS = "ONLY_TICKETS";
export const SET_REPLY_DATA = "SET_REPLY_DATA";
export const SAGA_ERROR = "SAGA_ERROR";
export const TRAINING_LINK = "TRAINING_LINK";
export const LOGIN_STATUS = "LOGIN_STATUS";
export const FORGOT_SUBMIT_OTP = "FORGOT_SUBMIT_OTP";
export const LOGOUT = "LOGOUT";
export const SEND_FORGOT_EMAIL = "SEND_FORGOT_EMAIL";
export const SET_CHANGE_PASS = "SET_CHANGE_PASS";
export const RELOAD_REDUX = "RELOAD_REDUX";
export const FORGOT_PASS_EMAIL = "FORGOT_PASS_EMAIL";
export const SEND_NOTIFICATION = "SEND_NOTIFICATION";
export const SAGA_ERRO_To_TRUE = "SAGA_ERRO_To_TRUE";
export const NOTIFICATION_CALL = "NOTIFICATION_CALL";

// NEW API CHANGES
export const CHECK_LOGIN = "CHECK_LOGIN";
export const SET_TOKEN = "SET_TOKEN";
export const TARGET_LINK = "TARGET_LINK";
export const SELECTED_LINK = "SELECTED_LINK";
export const GET_ALL_USER_DATA = "GET_ALL_USER_DATA";
export const INCOMPLETE_LIST = "INCOMPLETE_LIST";
export const COMPLETE_LIST = "COMPLETE_LIST";
export const SEARCH_BOX = "SEARCH_BOX";
export const SET_SEARCH_BOX = "SET_SEARCH_BOX";
export const UPDATE_LINK_LIST = "UPDATE_LINK_LIST";
export const APROVE_KYC = "APROVE_KYC";
export const DATA_SAVED = "DATA_SAVED";
export const RESER_DESC_E = "RESER_DESC_E";
export const RESER_DESC_H = "RESER_DESC_H";
export const PAYMENT_DATA = "PAYMENT_DATA";
export const GET_TRAINING_DATA = "GET_TRAINING_DATA";
export const OPEN_CHAT = "OPEN_CHAT";
export const GET_DETAIL_TICKET = "GET_DETAIL_TICKET";
export const GET_TESTIMONIALS = "GET_TESTIMONIALS";
export const SET_TESTIMONIALS_DATA = "SET_TESTIMONIALS_DATA";
export const DELETE_PERFORMER = "DELETE_PERFORMER";
export const SUBMIT_PERFORMER = "SUBMIT_PERFORMER";
export const GET_PENDING_PAYMENT = "GET_PENDING_PAYMENT";
export const SET_PENDING_PAYMENT = "SET_PENDING_PAYMENT";
export const SET_CHAT_DETAILS = "SET_CHAT_DETAILS";
export const GET_ALL_CHAT_DETAILS = "GET_ALL_CHAT_DETAILS";
export const GET_PAYMENT_COMPLETE_LIST = "GET_PAYMENT_COMPLETE_LIST";
export const SET_COMPLETE_PAYMENT = "SET_COMPLETE_PAYMENT";
export const SUBMIT_SLIDER = "SUBMIT_SLIDER";
export const GET_SLIDER_DATA = "GET_SLIDER_DATA";
export const GET_SLIDER_DATA2 = "GET_SLIDER_DATA2";
export const DELETE_SLIDER = "DELETE_SLIDER";
export const REJECTED_LIST = "REJECTED_LIST";
export const SET_REJECTED_LIST = "SET_REJECTED_LIST";
export const INSUFICIENT_BALANCE = "INSUFICIENT_BALANCE";
export const INSUFICIENT_BALANCES = "INSUFICIENT_BALANCES";
export const SET_LOADING = "SET_LOADING";
export const SET_ABOUT_US = "SET_ABOUT_US";
export const SET_ABOUTDATA = "SET_ABOUTDATA";
export const SET_PRIVACTDATAA = "SET_PRIVACTDATAA";
export const SET_PRIVACTDATA = "SET_PRIVACTDATA";
export const CHANGE_PASS = "CHANGE_PASS";
export const PASSWORD_ERROR = "PASSWORD_ERROR";
export const PASSWORD_EXP = "PASSWORD_EXP";
