import { Typography } from "antd";
import { Space, Input, Row, Col, Button, Card, Modal } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeMarquee,
  setMinimumBalance,
  changePassword,
} from "../../../../store/actions/users";

function CreateAdmin() {
  const minBal = useSelector((state) => state.admin.minBal);
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false);
  const [openModelLink, setOpenModelLink] = useState(false);
  const [openModelMarquee, setOpenModelMarquee] = useState(false);
  const currentMinBalance = minBal;
  const [minBalance, setMinBalance] = useState(0);
  const [marquee, setMarquee] = useState("");
  const [changePass, setChangePass] = useState({
    oldPass: "",
    newPass: "",
  });
  const [changePasswordData, setChangePasswordData] = useState({
    oldPassword: "",
    confirmPassword: "",
    newPassword: "",
    error: "",
  });

  useEffect(() => {
    setChangePasswordData({
      oldPassword: "",
      confirmPassword: "",
      newPassword: "",
      error: "",
    });
  }, []);

  useEffect(() => {
    let data = { error: "" };
    setChangePasswordData((list) => ({
      ...list,
      ...data,
    }));
  }, [changePasswordData.confirmPassword, changePasswordData.newPassword]);

  function handleOkMarquee() {
    dispatch(changeMarquee(marquee));
    setMarquee("");
    setOpenModelMarquee(false);
  }

  function handleCancelMarquee() {
    setMarquee("");
    setOpenModelMarquee(false);
  }

  function handleOk() {
    dispatch(setMinimumBalance(minBalance));
    setMinBalance(0);
    setOpenModel(false);
  }

  function handleCancel() {
    setMinBalance(0);
    setOpenModel(false);
  }
  function handleModelPopup() {
    setOpenModel(true);
  }
  function handleModelPopupMarquee() {
    setOpenModelMarquee(true);
  }

  function handlePasswordUpdate() {
    setOpenModelMarquee(true);
  }

  function handleOkLink() {
    // if (changePasswordData.newPassword === changePasswordData.confirmPassword) {
    //   dispatch(changePassword(changePasswordData.confirmPassword));
    //   setChangePasswordData({
    //     oldPassword: "",
    //     confirmPassword: "",
    //     newPassword: "",
    //     error: "",
    //   });
    // } else {
    //   let data = {
    //     error: "New Password and Confirm Password does not matched",
    //   };
    //   setChangePasswordData((list) => ({
    //     ...list,
    //     ...data,
    //   }));
    // }

    setChangePass({
      oldPass: "",
      newPass: "",
    });
    dispatch(changePassword(changePass));

    setOpenModelLink(false);
  }

  function handleCancelLink() {
    setChangePasswordData({
      oldPassword: "",
      confirmPassword: "",
      newPassword: "",
      error: "",
    });
    setOpenModelLink(false);
  }
  function handleModelPopupLink() {
    setOpenModelLink(true);
  }
  // video share

  return (
    <div>
      <Row style={{ paddingLeft: "1%", paddingTop: "1%", paddingRight: "1%" }}>
        <Col span="24">
          <Typography.Title level={4}>Admin Options</Typography.Title>
        </Col>

        <Col span="11">
          <div>
            <Card style={{ backgroundColor: "rgba(237, 237, 237, 0.8)" }}>
              <Space
                direction="vertical"
                style={{ width: "-webkit-fill-available" }}
              >
                <b>
                  <Typography.Text>Change Minimum balance </Typography.Text>
                </b>

                <Typography.Text>
                  Current minimum withdrawal balance : {currentMinBalance}
                </Typography.Text>
                <Space>
                  <Typography.Text>
                    Change minimum withdrawal balance :
                  </Typography.Text>
                  <Input
                    type="number"
                    value={minBalance}
                    style={{ width: "100px" }}
                    onChange={(e) => setMinBalance(e.target.value)}
                  ></Input>
                </Space>
                <Button
                  onClick={handleModelPopup}
                  type="default"
                  danger
                  disabled={!minBalance}
                  style={{ width: "-webkit-fill-available", marginTop: "15px" }}
                >
                  Submit
                </Button>
              </Space>
            </Card>
            {/* <Card
              style={{
                backgroundColor: "rgba(237, 237, 237, 0.8)",
                marginTop: "20px",
              }}
            >
              <Space
                direction="vertical"
                style={{ width: "-webkit-fill-available" }} */}
            {/* > */}

            {/* //   <b>
              //     <Typography.Text>Change marquee text </Typography.Text>
              //   </b>
              //   <div style={{ display: "flex" }}>
              //     <Typography.Text>Change marquee :</Typography.Text>
              //     <Input
              //       value={marquee}
              //       size="small"
              //       onChange={(e) => setMarquee(e.target.value)}
              //       //style={{width: "100%"}}
              //     ></Input>
              //   </div>
              //   <Button
              //     onClick={handleModelPopupMarquee}
              //     type="default"
              //     danger
              //     disabled={marquee === ""}
              //     style={{ width: "-webkit-fill-available", marginTop: "15px" }}
              //   >
              //     Submit
              //   </Button> */}
            {/* </Space> */}
            {/* </Card> */}
          </div>
        </Col>
        <Col span={1}></Col>
        <Col span="12">
          <Card style={{ backgroundColor: "rgba(237, 237, 237, 0.8)" }}>
            <Space
              direction="vertical"
              style={{ width: "-webkit-fill-available" }}
            >
              <b>
                <Typography.Text>Change marquee text </Typography.Text>
              </b>
              <div style={{ display: "flex" }}>
                <Typography.Text>Change marquee :</Typography.Text>
                <Input
                  value={marquee}
                  size="small"
                  onChange={(e) => setMarquee(e.target.value)}
                  //style={{width: "100%"}}
                ></Input>
              </div>
              <Button
                onClick={handleModelPopupMarquee}
                type="default"
                danger
                disabled={marquee === ""}
                style={{ width: "-webkit-fill-available", marginTop: "15px" }}
              >
                Submit
              </Button>
            </Space>
          </Card>
        </Col>
        <Col span={11}>
          <Card
            style={{
              backgroundColor: "rgba(237, 237, 237, 0.8)",
              marginTop: "20px",
            }}
          >
            <Space
              direction="vertical"
              style={{ width: "-webkit-fill-available" }}
            >
              <b>
                <Typography.Text>Update Password </Typography.Text>
              </b>
              <div style={{ display: "flex" }}>
                <Typography.Text>Old Password :</Typography.Text>
                <Input
                  value={changePass.oldPass}
                  size="small"
                  onChange={(e) => {
                    let data = { oldPass: e.target.value };
                    setChangePass((list) => ({
                      ...list,
                      ...data,
                    }));
                  }}
                  //style={{width: "100%"}}
                ></Input>
              </div>
              <div style={{ display: "flex" }}>
                <Typography.Text>New Password :</Typography.Text>
                <Input
                  value={changePass.newPass}
                  size="small"
                  onChange={(e) => {
                    let data = { newPass: e.target.value };
                    setChangePass((list) => ({
                      ...list,
                      ...data,
                    }));
                  }}
                  //style={{width: "100%"}}
                ></Input>
              </div>
              <Button
                onClick={handleModelPopupLink}
                type="default"
                danger
                disabled={
                  changePass.oldPass === "" || changePass.newPass === ""
                }
                style={{ width: "-webkit-fill-available", marginTop: "15px" }}
              >
                Submit
              </Button>
            </Space>
          </Card>
        </Col>
      </Row>
      <Modal
        title="Confirmation"
        open={openModelLink}
        onOk={handleOkLink}
        onCancel={handleCancelLink}
      >
        <Typography.Text>
          Are you sure you want to Update Password ?{" "}
        </Typography.Text>
      </Modal>
      <Modal
        title="Confirmation"
        open={openModel}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Typography.Text>
          Are you sure you want to change min balance ?{" "}
        </Typography.Text>
      </Modal>
      <Modal
        title="Confirmation"
        open={openModelMarquee}
        onOk={handleOkMarquee}
        onCancel={handleCancelMarquee}
      >
        <Typography.Text>
          Are you sure you want to change marquee text ?{" "}
        </Typography.Text>
      </Modal>
    </div>
  );
}
export default CreateAdmin;
