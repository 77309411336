import "../../../css/dashboard.css";
import { Typography } from "antd";

function Footer() {
  return (
    <div className="Footer">
      <Typography.Link href="" target={"_blank"}>
        Powerd By: Pro Creations
      </Typography.Link>
      <Typography.Link>Privacy Policy</Typography.Link>
      <Typography.Link>About Us</Typography.Link>
    </div>
  );
}

export default Footer;
